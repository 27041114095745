import { AxiosResponse } from 'axios';

import { ListParams, DateRangeInterface } from '../Common/types';
import { AgreementDetails, BatchUpdateInterface, TeamAgreementDetails } from './types';
import API from '../Common/axios';

export type FindAgreementsParams = {
  query?: string;
  start_date?: string;
  end_date?: string;
  is_active?: boolean;
  sort_by?: string;
  sort_order?: 'asc' | 'desc';
  page?: number;
  processing?: boolean;
};

export const getAgreementList = (params: FindAgreementsParams = {}): Promise<AxiosResponse> => {
  return API.get('v1/agreements', { params });
};

export const createAgreement = (agreements: any[]): Promise<AxiosResponse> => {
  return API.post('v1/agreements/batch_create', { agreements });
};

export const updateAgreement = (id: string | undefined, agreement: AgreementDetails): Promise<AxiosResponse> => {
  return API.patch(`v1/agreements/${id}`, { agreement });
};

export const batchUpdateAgreements = (ids: number[], agreement: BatchUpdateInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/agreements/batch_update`, { agreement_ids: ids, agreement: agreement }, { timeout: 30000 });
};

export const getAgreement = (id: string) => {
  return API.get<{ data: AgreementDetails }>(`v1/agreements/${id}`);
};

export const exportAgreementOverview = (params: DateRangeInterface): Promise<AxiosResponse> => {
  return API.get(`v1/agreements/book_closing_data_dump`, { params, timeout: 30000 });
};

export const deleteAgreement = (id: string | undefined): Promise<AxiosResponse> => {
  return API.delete(`v1/agreements/${id}`);
};

export const batchDeleteAgreements = (agreement_ids: number[]): Promise<AxiosResponse> => {
  return API.delete(`v1/agreements/batch_destroy`, { params: { agreement_ids } });
};

export const getDeletableAgreements = (agreement_ids: number[]): Promise<AxiosResponse> => {
  return API.get(`v1/agreements/deletable`, { params: { agreement_ids } });
};

export const getTeamAgreement = (id: number) => {
  return API.get<{ data: TeamAgreementDetails }>(`v1/team_agreements/${id}`);
};
