import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Location, NavigateOptions, useSearchParams } from 'react-router-dom';
import { AuthProfile } from './types';
import Cookies from 'universal-cookie';

/**
 * Formats date according to locale standards
 *
 * @param date
 * @param locale
 */
export const formatDate = (date: string | undefined, locale = 'sv-SE'): string => {
  if (!date) return '-';
  const parsedDate = new Date(date);
  return parsedDate.toLocaleDateString(locale);
};

/**
 * Gets a specific field from a form state object.
 *
 * @param from
 * @param selector
 */
export const getValue = (from: Record<string, any>, selector: string): any =>
  selector
    .replace(/\[([^\[\]]*)]/g, '.$1.') // eslint-disable-line
    .split('.')
    .filter((t) => t.toString() !== '')
    .reduce((prev: any, cur: any) => prev && prev[cur], from);

/**
 * Returns true if the a value is an empty object,
 * collection, has no enumerable properties or is
 * any type that is not considered a collection.
 *
 * @param val
 */
export const isEmpty = (val: any): boolean => val == null || !(Object.keys(val) || val).length;
export const isNotEmpty = (val: any): boolean => !isEmpty(val);

export const monthTranslator = (month: string): string => monthDictionary[month];
export const fullMonthTranslator = (month: string): string => fullMonthDictionary[month];

interface MonthDictionary {
  [index: string]: string;
}
const monthDictionary: MonthDictionary = {
  '01': 'januari',
  '02': 'februari',
  '03': 'mars',
  '04': 'april',
  '05': 'maj',
  '06': 'juni',
  '07': 'juli',
  '08': 'augusti',
  '09': 'september',
  '10': 'oktober',
  '11': 'november',
  '12': 'december',
};

const fullMonthDictionary: MonthDictionary = {
  January: 'Januari',
  February: 'Februari',
  March: 'Mars',
  April: 'April',
  May: 'Maj',
  June: 'Juni',
  July: 'Juli',
  August: 'Augusti',
  September: 'September',
  October: 'Oktober',
  November: 'November',
  December: 'December',
};

/**
 * Converts a float value to a a time string
 *
 * @param number
 */
export const convertNumToTime = (number: number | string | undefined): string => {
  if (typeof number == 'string') {
    number = parseFloat(number);
  }
  if (number) {
    // Check sign of given number
    const sign = number >= 0 ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    const hour = Math.floor(number);
    let decpart = number - hour;
    const min = 1 / 60;

    // Round to nearest minute
    decpart = min * Math.round(decpart / min);
    let minute = Math.floor(decpart * 60) + '';

    // Add padding if need
    if (minute.length < 2) {
      minute = '0' + minute;
    }

    // Add Sign in final result
    const signChar = sign == 1 ? '' : '-';

    // Concatenate hours and minutes
    const time = signChar + hour + ':' + minute;
    return time;
  } else {
    return '';
  }
};

export const isFutureDate = (date: string): boolean => {
  return dayjs().isAfter(date, 'day');
};

/**
 * Converts a givern object into a query string
 * @param searchParams Properties should be either `string` or `undefined`
 * @param leadingQueryMark Whether or not the returned string should be preprended with `?`
 */
export function stringifySearchParams(
  searchParams: Record<string, string | undefined>,
  leadingQueryMark = false
): string {
  const result: string[] = [];
  for (const [key, property] of Object.entries(searchParams)) {
    if (property) result.push(`${key}=${property}`);
  }
  return (leadingQueryMark && result.length ? '?' : '') + result.join('&');
}

type UseQueryConverter<T> = { [K in keyof T]: (raw: string | undefined) => T[K] };
type UseQueryConfig<T> = {
  /**
   * Used to convert a query value back into a string
   */
  backConverter?: Partial<{ [K in keyof T]: (val: T[K]) => string | undefined }>;
  /**
   * Should `setQuery` calls overwrite all currently set query params
   * @default false
   */
  overwrite?: boolean;
};

/** Gets and sets search query parameters */
export function useQuery<T extends { [key: string]: any | undefined }>(
  /** functions to convert  */
  converter: UseQueryConverter<T>,
  { backConverter = {}, overwrite = false }: UseQueryConfig<T> = {}
): [T, (values: { [K in keyof T]?: any }, options?: NavigateOptions) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(generateQuery());

  useEffect(() => {
    setQuery(generateQuery());
  }, [searchParams]);

  function generateQuery() {
    const query: Partial<T> = {};
    for (const key in converter) {
      query[key] = converter[key](searchParams.get(key) ?? undefined);
    }
    return query as T;
  }

  function setSearchQuery(values: { [K in keyof T]?: any }, options?: NavigateOptions) {
    const newQuery: Record<string, any> = {};
    if (!overwrite) {
      for (const key in query) {
        if (query[key] !== undefined) newQuery[key] = query[key];
      }
    }
    for (const key in values) {
      newQuery[key] = values[key];
    }
    for (const key in newQuery) {
      if (newQuery[key] === undefined) {
        delete newQuery[key];
      } else if (backConverter[key] && typeof newQuery[key] !== 'string') {
        const backVal = backConverter[key]?.(newQuery[key]);
        if (backVal !== undefined) newQuery[key] = backVal;
      } else {
        newQuery[key] = String(newQuery[key]);
      }
    }
    setSearchParams(newQuery, options);
  }

  return [query, setSearchQuery];
}

/**
 * Regexp that matches work-shifts and schedule paths
 * to know where to redirect the user after creating or
 * updating a work shift
 */
export const workShiftsReturnRegex = /^\/(work-shifts$|schedule($|\/)|dashboard)/;

type UseDebounceProps<V, T> = {
  /** Action to run before starting wait for action */
  beforeDebounce?: (value: V) => void;
  /** Time in ms to wait before action */
  debounceTime?: number;
  /** Should run when created */
  runInitially?: boolean;
  /** value to use with promise generator on initial run */
  runInitiallyWith?: V;
  /**
   * Value to use with promise generator on initial run
   *
   * Defaults to `onResolved`
   */
  onInitialResolved?: (resolution: T) => void;
};

/**
 * @param promiseGenerator Used to generate new promise
 * @param onResolved Called when promise resolves with value retuned by promise
 */
export function useDebounce<T, V>(
  promiseGenerator: (value: V) => Promise<T> | void,
  onResolved: (resolution: T) => void,
  {
    beforeDebounce,
    debounceTime = 500,
    runInitially = false,
    runInitiallyWith,
    onInitialResolved = onResolved,
  }: UseDebounceProps<V, T> = {}
): { debounce: (value: V) => void; loading: boolean } {
  const [loading, setLoading] = useState(runInitially && runInitiallyWith !== undefined);
  const debounceRef = useRef(0);

  useEffect(() => {
    if (debounceRef.current === 0 && runInitially && runInitiallyWith !== undefined) {
      debounceRef.current += 1;
      const debounceId = debounceRef.current;
      promiseGenerator(runInitiallyWith)
        ?.then((res) => {
          if (debounceRef.current !== debounceId) return;
          onInitialResolved(res);
        })
        .catch((err) => {
          if (debounceRef.current === debounceId) throw err;
        })
        .finally(() => {
          if (debounceRef.current !== debounceId) return;
          setLoading(false);
        });
    }
  }, []);

  return {
    debounce: (value) => {
      debounceRef.current += 1;
      const debounceId = debounceRef.current;
      setLoading(true);

      beforeDebounce?.(value);

      setTimeout(() => {
        if (debounceRef.current !== debounceId) return;
        promiseGenerator(value)
          ?.then((res) => {
            if (debounceRef.current !== debounceId) return;
            onResolved(res);
          })
          .catch((err) => {
            if (debounceRef.current === debounceId) throw err;
          })
          .finally(() => {
            if (debounceRef.current !== debounceId) return;
            setLoading(false);
          });
      }, debounceTime);
    },
    loading,
  };
}

/**
 * @param func Code to run after first mount
 * @param dependencies Dependency array
 */
export const useDidMountEffect = (func: () => void, dependencies: Array<any>): void => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, dependencies);
};

export function formatNumberSpaced(number: number): string {
  const str = String(number);
  const cantSplitEqual = str.length % 3;
  const [preceding, rest] = [str.slice(0, cantSplitEqual), str.slice(cantSplitEqual)];

  return [preceding, ...(rest.match(/.{1,3}/g) ?? [])].filter(Boolean).join(' ');
}

export type LastLocation = {
  pathname: string;
  search: string;
  hash: string;
};

export function lastLocationState(location: Location, replace: Partial<LastLocation> = {}): LastLocation {
  return {
    pathname: replace.pathname ?? location.pathname,
    search: replace.search ?? location.search,
    hash: replace.hash ?? location.hash,
  };
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function downloadFile(file: Blob, filename: string) {
  const url = URL.createObjectURL(file);

  const link = document.createElement('a');
  link.style.visibility = 'hidden';
  link.setAttribute('href', url);
  link.setAttribute('download', filename);

  // Append to download link to page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Remove from page
  document.body.removeChild(link);
}

export function arrayify<T>(values: T[] | T | null | undefined): T[] {
  if (Array.isArray(values)) return values;
  return values ? [values] : [];
}

export function getDefaultsWithActualValues<T extends Record<string, any>>(
  defaults: T,
  actual: Record<string, any>
): T {
  const result: Record<string, any> = {};
  for (const [key, value] of Object.entries(defaults)) {
    result[key] = actual[key] !== undefined ? actual[key] : value;
  }
  return result as T;
}

export function stringToPositiveInt(val?: string): number | undefined {
  const num = Number(val);
  if (!isFinite(num) || num < 1 || num !== Math.floor(num)) return undefined;

  return num;
}

export function getUserRole(user: AuthProfile): string {
  switch (user.role.short_name) {
    case 'D':
      return 'deltagare';
    case 'TL':
      return 'teamledare';
    case 'RC':
      return 'regionchef';
    case 'VA':
      return 'verksamhetsansvarig';
    case 'VL':
      return 'verksamhetsledare';
    case 'A':
      return 'admin';
  }
  return user.role.name;
}

export function usePrevious(value: (string | number)[] | undefined): (string | number)[] | undefined {
  const ref = useRef<(string | number)[] | undefined>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function omitKeys<Data extends object, Keys extends keyof Data>(data: Data, keys: Keys[]): Omit<Data, Keys> {
  const result = { ...data };

  for (const key of keys) {
    delete result[key];
  }

  return result as Omit<Data, Keys>;
}

export const cookieManager = new Cookies(null, { path: '/' });

export const baseURL = process.env.REACT_APP_BASE_URL ? new URL(process.env.REACT_APP_BASE_URL) : undefined;
export const residencePageURL = process.env.REACT_APP_RESIDENCE_URL
  ? new URL(process.env.REACT_APP_RESIDENCE_URL)
  : undefined;
