import React, { useState, ReactNode } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { paginationDefaults as commonPaginationDefaults } from '../commonSchema';

import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Chip,
  FormControl,
  TextField,
} from '@mui/material';

import { createFilterOptions } from '@mui/material/Autocomplete';

import { ListParams, OptionsInterface } from '../../Common/types';

type FilterAutocompleteProps<OptionsInterface> = {
  getOptionLabel?: (option: OptionsInterface) => string;
  setGetListParams: (p: ListParams) => void;
  listParams: ListParams;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
  fieldName: string;
  label: string;
  options: OptionsInterface[];
  multiple?: boolean;
  limitTags?: number;
  componentProps?: object;
  values?: OptionsInterface[] | OptionsInterface | null;
  groupBy?: ((option: OptionsInterface) => string) | undefined;
  width?: number;
  disableClearable?: boolean;
  renderTags?:
    | ((values: OptionsInterface[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode)
    | 'simple'
    | 'chips';
};

export default function FilterAutocomplete<T>({
  getOptionLabel = (option: any) => {
    if (typeof option === 'object' && typeof option?.name === 'string') return option.name;
    if (typeof option === 'object' && typeof option?.label === 'string') return option.label;
    return String(option);
  },
  setGetListParams,
  listParams,
  setPagination,
  paginationDefaults,
  fieldName,
  label,
  options,
  multiple,
  limitTags,
  componentProps,
  values,
  groupBy,
  width,
  disableClearable,
  renderTags,
}: FilterAutocompleteProps<OptionsInterface>) {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(1450));
  const chipConfig = isMatch ? { maxWidth: 110 } : { maxWidth: 170 };

  // functions
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: OptionsInterface | OptionsInterface[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptionsInterface> | undefined
  ): void => {
    if (setPagination) {
      setPagination({
        page: 1,
        page_size: paginationDefaults ? paginationDefaults.page_size : commonPaginationDefaults.page_size,
      });
    }
    if (!value) {
      setGetListParams({ ...listParams, [fieldName]: '' });
    } else {
      // if it's a multiple choice autocomplete, set the field value as an array of ids
      if (Array.isArray(value)) {
        setGetListParams({
          ...listParams,
          [fieldName]: value.map((v: OptionsInterface) => v?.id),
        });
        // if it's a single choise autocomplete, set the field value as an id
      } else {
        setGetListParams({
          ...listParams,
          [fieldName]: value?.id,
        });
      }
    }
  };

  // render
  return (
    <FormControl sx={{ width: width ? width : '200px', marginRight: '5px' }}>
      <Autocomplete
        componentsProps={componentProps}
        disableCloseOnSelect={multiple ? true : false}
        disableClearable={disableClearable}
        multiple={multiple}
        limitTags={limitTags}
        options={options}
        filterOptions={createFilterOptions({ ignoreAccents: false })}
        groupBy={groupBy}
        getOptionLabel={getOptionLabel}
        renderTags={
          renderTags === 'simple'
            ? (values: OptionsInterface[]) => (
                <p className="ml-2">
                  {values.length} {values.length === 1 ? 'vald' : 'valda'}
                </p>
              )
            : (values: OptionsInterface[], getTagProps: AutocompleteRenderGetTagProps) =>
                // eslint-disable-next-line react/jsx-key
                values.map((value, index) => <Chip label={getOptionLabel(value)} {...getTagProps({ index })} />)
        }
        value={values}
        isOptionEqualToValue={(option: OptionsInterface, value: number | string | OptionsInterface): boolean => {
          // when no value is chosen, it is an empty string or undefined
          if (value === undefined) {
            return true;
          }
          if (typeof value === 'string') {
            if (value === '') {
              return true;
            }
            // when the form value is prefilled, it is a number
          } else if (typeof value === 'number') {
            if (value === option.id) {
              return true;
            }
            // otherwise it is a chosen option from the autocomplete with format {id: ..., name: ...}
          } else if (option.id === value.id) {
            return true;
          }
          return false;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name ? option.name : ''}
          </li>
        )}
        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              placeholder: Array.isArray(values) && values.length ? '' : label,
            }}
          />
        )}
        onChange={handleChange}
      />
    </FormControl>
  );
}
