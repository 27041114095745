import React, { useState, useEffect, useRef } from 'react';
import { sort_order as sort_order_from_types } from '../Common/types';
import { encode, decode } from 'base-64';
import { useLocation, useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import NumberFormat from 'react-number-format';
import { Table, TableBody, TableCell, Dialog, Checkbox, Grid, Tooltip, MenuItem } from '@mui/material';
import ReserveIcon from '@mui/icons-material/QueryBuilderSharp';
import CallMadeIcon from '@mui/icons-material/RadioButtonChecked';
import ClearIcon from '@mui/icons-material/Clear';
import { TableHead, TableRow, MenuTableCell, Pagination } from '../Common/Table';
import {
  paginationDefaults,
  filterSortDefaultsParticipant,
  filterSortDefaultsTeamLeader,
} from './jobApplicationSchema';
import { ListParams, ListPagination, OptionsInterface } from '../Common/types';
import { EmailPopover } from '../Common/Popovers';
import { formatDate, isEmpty, isNotEmpty, useDebounce } from '../Common/utilities';
import { StyleSheet, commonStyles, css } from '../Common/styling';
import FilterPopover from './components/FilterPopover';
import LanguageFilter from './components/LanguageFilter';
import FilterAutocomplete from '../Common/FilterPopover/FilterAutocomplete';
import { useNotify } from '../Common/snackbarHooks';
import EmptyList from '../Common/Table/EmptyList';
import { SearchBar } from '../Common/SearchBar';
import { getJobApplicationList, getLanguagesMinimalList, batchUpdateJobApplicationStatus } from './jobApplicationApi';
import { JobApplicationInterface, LanguageInterface } from './types';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import UpdateJobApplicationStatusModal from './components/UpdateJobApplicationStatusModal';
import { CreateFab, ResetFab, TaBortFab, CallInterviewFab, ReserveFab, EmployFab } from '../Common/ButtonLinks';
import EmployJobApplicationModal from './components/EmployJobApplicationModal';
import paramsFromUrl from '../Common/Hooks/paramsFromUrl';
import useGlobalPopstateListener from '../Common/Hooks/useGlobalPopstateListener';

const styles = StyleSheet.create({
  tableCell: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingRight: '2px',
    paddingLeft: '10px',
  },
  paddingCheckbox: {
    padding: '2px',
  },
  travelTimes: {
    display: 'block',
    width: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mobile: {
    paddingRight: '5px',
    paddingLeft: '0px',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: '#5699ff',
    fontWeight: 'bold',
    justifyContent: 'flex-end',
    textDecoration: 'none',
  },
  emailIcon: {
    color: 'black',
    position: 'relative',
    bottom: '1px',
    verticalAlign: 'middle',
    justifyContent: 'flex-start',
  },
  reserveIcon: {
    position: 'relative',
    bottom: '1px',
    paddingRight: '5px',
    verticalAlign: 'middle',
  },
  callMadeIcon: {
    position: 'relative',
    bottom: '1px',
    paddingRight: '5px',
    verticalAlign: 'middle',
    color: '#fee721',
  },
  employedIcon: {
    position: 'relative',
    bottom: '1px',
    paddingRight: '5px',
    verticalAlign: 'middle',
    color: '#00844b',
  },
  deletedIcon: {
    position: 'relative',
    bottom: '1px',
    paddingRight: '5px',
    paddingBottom: '1px',
    verticalAlign: 'middle',
    color: '#d0021b',
  },
});

type Props = {
  type: 'participant' | 'team_leader';
};

const JobApplications: React.FC<Props> = ({ type }) => {
  // hooks
  const pageTopRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyInfo, notifyError, notifySuccess } = useNotify();
  const [languageList, setLanguageList] = useState<Array<LanguageInterface>>([]);
  const [residenceList, setResidenceList] = useState<Array<OptionsInterface>>([]);
  const [jobApplicationList, setJobApplicationList] = useState<Array<JobApplicationInterface>>([]);
  const [selectedJobApplications, setSelectedJobApplications] = useState([] as JobApplicationInterface[]);
  const [quickActionJobApplication, setQuickActionJobApplication] = useState<Array<JobApplicationInterface>>([]);
  const [jobApplicationsCount, setJobApplicationsCount] = useState<number>(0);
  const [loadingResidences, setLoadingResidences] = useState(true);
  const [loadingLanguages, setLoadingLanguages] = useState(true);

  const [reserveModalState, setReserveModalState] = useState(false);
  const [employModalState, setEmployModalState] = useState(false);
  const [taBortModalState, setTaBortModalState] = useState(false);

  // get cached filters and pagination from url if any
  // otherwise load default values
  const query = paramsFromUrl('query');
  const page = paramsFromUrl('page');
  const [pagination, setPagination] = useState(
    page === '' ? (paginationDefaults as ListPagination) : (page as ListPagination)
  );

  function handleHistoryChange(event: PopStateEvent) {
    // Handle popstate event
    setPagination(paramsFromUrl('page'));
  }
  useGlobalPopstateListener(handleHistoryChange);

  const getFilters = (): ListParams => {
    if (query === '' && type === 'team_leader') {
      return filterSortDefaultsTeamLeader as ListParams;
    } else if (query === '' && type === 'participant') {
      return filterSortDefaultsParticipant as ListParams;
    } else {
      return query as ListParams;
    }
  };
  const [listParams, setGetListParams] = useState(getFilters());
  const { loading: loadingJA, debounce: fetchJobApplications } = useDebounce(
    (params: ListParams) => getJobApplicationList({ ...params, ...pagination }),
    ({ data }) => {
      setJobApplicationList(data.data);
      setJobApplicationsCount(data.meta.count);
    },
    { runInitially: false }
  );

  useEffect(() => {
    setPagination(pagination);
    fetchResidences();
    fetchLanguages();
    pageTopRef?.current?.scrollIntoView();
  }, []); // eslint-disable-line

  useEffect(() => {
    const query = encode(JSON.stringify(listParams));
    const page = encode(JSON.stringify(pagination));
    navigate(`/job_applications/${type}?query=${query}&page=${page}${location.hash}`, {
      replace: true,
    });

    fetchJobApplications({ ...listParams, ...pagination });
  }, [listParams, pagination]); // eslint-disable-line

  // Special use effect. React Router Dom v6 does not seem to be re-rendering the component when changing
  // between team_leader and participant types
  useEffect(() => {
    setGetListParams(getFilters);
  }, [type]); // eslint-disable-line

  const fetchLanguages = (): void => {
    setLoadingLanguages(true);
    getLanguagesMinimalList()
      .then(({ data }) => {
        setLanguageList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över språk'))
      .finally(() => setLoadingLanguages(false));
  };

  const fetchResidences = (): void => {
    setLoadingResidences(true);
    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => {
        setResidenceList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över boende'))
      .finally(() => setLoadingResidences(false));
  };

  const rowIsSelected = (id: number | undefined): boolean => {
    return selectedJobApplications.some(
      (value: JobApplicationInterface, index: number, array: JobApplicationInterface[]) => value.id === id
    );
  };

  const selectRow = (event: any, row: JobApplicationInterface): void => {
    // Avoid to navigate to job application detail view
    event.stopPropagation();

    // Push or pop element from list
    const newSelectedList = event.target.checked
      ? [...selectedJobApplications, row]
      : selectedJobApplications.filter((a: any) => a.id !== row.id);

    setSelectedJobApplications(newSelectedList);
  };

  const handleStatusUpdate = (jobApplicationIds: (number | undefined)[], statusValue: string): void => {
    if (jobApplicationIds) {
      batchUpdateJobApplicationStatus(jobApplicationIds, statusValue)
        .then(() => {
          if (statusValue == 'deleted' && jobApplicationIds.length == 1) {
            notifySuccess('Ansökan har arkiverats');
          } else if (statusValue == 'deleted' && jobApplicationIds.length > 1) {
            notifySuccess('Ansökningarna har arkiverats');
          } else {
            notifySuccess('Jobbansökningarna har uppdaterats');
          }
        })
        .then(() => {
          fetchJobApplications({ ...listParams, ...pagination });
          setSelectedJobApplications([]);
          setQuickActionJobApplication([]);
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera jobbansökningarna: ${error}`));
    }
  };

  const mobileNumberFormat = (mobile: string): string => {
    switch (mobile.split(' ').join('').length) {
      case 8:
        return '### - ### ##';
      case 9:
        return '### - ## ## ##';
      case 10:
        return '### - ### ## ##';
      default:
        return '### - ### ## ## ## ## ## ## ##';
    }
  };

  const formattedMobileNumber = (mobile: string): JSX.Element | string => {
    if (mobile.substring(0, 3) === '+46') {
      const formattedMobile = ('0' + mobile.substring(3, mobile.length)).split(' ').join('');
      return <NumberFormat value={formattedMobile} displayType={'text'} format={mobileNumberFormat(formattedMobile)} />;
    } else {
      return mobile;
    }
  };

  const deviceType = (): string => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return 'desktop';
  };

  const copyMobile = (mobile: string): void => {
    navigator.clipboard.writeText(mobile.split(' ').join(''));
    notifyInfo('Mobilnummer har kopierats', {
      variant: 'default',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
  };

  const mobileLink = (mobile: string): JSX.Element => {
    if (deviceType() === 'mobile') {
      return (
        <a
          href={'tel:' + mobile.split(' ').join('')}
          className={css(styles.mobile)}
          onClick={(e: { stopPropagation: () => void }): void => {
            e.stopPropagation();
            copyMobile(mobile);
          }}
        >
          {formattedMobileNumber(mobile)}
        </a>
      );
    } else {
      return (
        <a
          className={css(styles.mobile)}
          onClick={(e: { stopPropagation: () => void }): void => {
            e.stopPropagation();
            copyMobile(mobile);
          }}
        >
          {formattedMobileNumber(mobile)}
        </a>
      );
    }
  };

  const getTravelTimesString = (row: JobApplicationInterface): string => {
    if (row.recalculate_travels) {
      return '';
    } else {
      if (Array.isArray(row.job_application_travels) && row.job_application_travels.length > 0) {
        let time = '';
        let residence = '';
        const travels = row.job_application_travels.sort((a, b) => (a.travel_time_min > b.travel_time_min ? 1 : -1));
        if (listParams.filter_residence !== null) {
          const foundIdx = travels.findIndex((el) => el.residence_id == listParams.filter_residence);
          if (foundIdx !== -1) {
            const foundItem = travels[foundIdx];
            travels.splice(foundIdx, 1);
            travels.unshift(foundItem);
          }
        }
        return travels
          .map((t) => {
            time = t.travel_time_min ? String(t.travel_time_min) + 'min ' : '';
            residence = residenceList.find((r: { id: string | number }) => r.id == t.residence_id)?.name || 'NaN';
            if (t.manually_tagged == true) {
              return time + residence + '*';
            } else {
              return time + residence;
            }
          })
          .join(', ');
      } else {
        return 'Inga boenden inom 90min';
      }
    }
  };

  const getStatusInfo = (row: JobApplicationInterface): string | undefined => {
    let str = '';
    str += row.status_updated_at !== null ? row.status_updated_at : '';
    str += row.initiator_user_name !== null ? ' av ' + row.initiator_user_name : '';

    if (str === '') {
      return 'Status information är inte tillgänglig';
    } else if (row.status === 'D_reserve' || row.status === 'TL_reserve') {
      return 'Reservmarkerad ' + str;
    } else if (row.status === 'called_for_interview') {
      return 'Kallad ' + str;
    } else if (row.status === 'deleted') {
      return 'Borttagen ' + str;
    }
  };

  const SelectionFabs: React.FC = () => {
    return (
      <Grid container className={css(commonStyles.selectionFabContainerStyle2)}>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <ResetFab
            onClick={(): void => {
              handleStatusUpdate(
                selectedJobApplications.map(
                  (value: JobApplicationInterface, index: number, array: JobApplicationInterface[]) => value.id
                ),
                'default'
              );
            }}
            text="Återställ"
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <CallInterviewFab
            onClick={(): void =>
              handleStatusUpdate(
                selectedJobApplications.map(
                  (value: JobApplicationInterface, index: number, array: JobApplicationInterface[]) => value.id
                ),
                'called_for_interview'
              )
            }
            text="Kalla"
          />
        </Grid>
        {selectedJobApplications.length == 1 ? (
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <EmployFab onClick={(): void => setEmployModalState(true)} text="Anställ" />
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <ReserveFab onClick={(): void => setReserveModalState(true)} text="Reserv" />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <TaBortFab onClick={(): void => setTaBortModalState(true)} text="Arkivera" />
        </Grid>
      </Grid>
    );
  };

  const status: Record<string, { value: string; title: string; body: string; note: string }> = {
    reserve: {
      value: type === 'participant' ? 'D_reserve' : 'TL_reserve',
      title: 'Uppdatera status till "reserv"',
      body: 'Är du säker på att du vill ändra status till "reserv" för följande ansökning(ar):',
      note:
        type === 'participant'
          ? 'Endast deltagarkandidater som fått minst 29 poäng får markeras som reserver.'
          : 'Endast teamledarkandidater som fått minst 46 poäng får markeras som reserver.',
    },
    employed: {
      value: 'employed',
      title: 'Anställ ansökande',
      body: 'Är du säker på att du vill "anställa" följande ansökande:',
      note: 'Det här anställer den ansökande och tar bort respektive ansökning.',
    },
    deleted: {
      value: 'deleted',
      title: 'Arkivera ansökning',
      body: 'Är du säker på att du vill "arkivera" följande ansökning(ar):',
      note: 'Det här arkiverar jobbansökningen. För att se arkiverade ansökningar kan du aktivera "arkiverade" under "Filtrera".',
    },
  };

  const swedish_status: Record<string, string> = {
    called_for_interview: 'Kallad',
    D_reserve: type === 'participant' ? 'Reserv (D)' : '',
    TL_reserve: type === 'team_leader' ? 'Reserv (TL)' : '',
    default: '',
    employed: 'Anställd',
    deleted: 'Arkiverad',
  };

  // render
  const rows = [
    { id: 'select', label: 'Välj', notSortable: true },
    { id: 'first_name', label: 'Namn' },
    { id: 'mobile', label: 'Kontakt' },
    { id: 'status', label: 'Status' },
    { id: 'residences', label: 'Restid' },
    { id: 'birth_date', label: 'Födelseår' },
    { id: 'city', label: 'Ort' },
    { id: 'created_at', label: 'Skapad' },
    { id: 'quick_action', label: '', notSortable: true },
  ];

  const rowsTL = [
    { id: 'select', label: 'Välj' },
    { id: 'first_name', label: 'Namn' },
    { id: 'mobile', label: 'Kontakt' },
    { id: 'type', label: 'Typ' },
    { id: 'status', label: 'Status' },
    { id: 'residences', label: 'Restid' },
    { id: 'birth_date', label: 'Födelseår' },
    { id: 'city', label: 'Ort' },
    { id: 'created_at', label: 'Skapad' },
    { id: 'quick_action', label: '', notSortable: true },
  ];

  const renderStatusIcon = (status: string): React.ReactNode => {
    switch (status) {
      case 'D_reserve':
        if (type == 'participant') {
          return <ReserveIcon className={css(styles.reserveIcon)} />;
        }
        break;
      case 'TL_reserve':
        if (type == 'team_leader') {
          return <ReserveIcon className={css(styles.reserveIcon)} />;
        }
        break;
      case 'called_for_interview':
        return <CallMadeIcon className={css(styles.callMadeIcon)} />;
      case 'employed':
        return <CallMadeIcon className={css(styles.employedIcon)} />;
      case 'deleted':
        return <ClearIcon className={css(styles.deletedIcon)} />;
    }
  };

  return (
    <React.Fragment>
      <div ref={pageTopRef} className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>
              {type === 'participant' ? 'Rekrytering deltagare' : 'Rekrytering teamledare'}
            </h1>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <p style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
              {jobApplicationsCount} Ansökningar
            </p>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={7} sm={7} md={7} lg={7}>
            <SearchBar
              setGetListParams={setGetListParams}
              listParams={listParams}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={3} sm={3} md={3} lg={3}>
            {!loadingResidences && (
              <FilterAutocomplete
                getOptionLabel={(residence) => residence.name}
                setGetListParams={setGetListParams}
                listParams={listParams}
                fieldName="filter_residence"
                label="Boende"
                options={residenceList}
                values={residenceList.find((r) => listParams.filter_residence === r.id)}
                setPagination={setPagination}
                paginationDefaults={paginationDefaults}
              />
            )}
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={1} sm={1} md={1} lg={1}>
            <FilterPopover
              setGetListParams={setGetListParams}
              setSelectedRowItems={setSelectedJobApplications}
              setQuickActionItem={setQuickActionJobApplication}
              listParams={listParams}
              type={type}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={1} sm={1} md={1} lg={1}>
            <LanguageFilter
              options={languageList}
              listParams={listParams}
              selected={listParams.filter_languages}
              fieldName="filter_languages"
              setGetListParams={setGetListParams}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead
            rows={type === 'team_leader' ? rowsTL : rows}
            listParams={listParams}
            setGetListParams={setGetListParams}
          />

          <TableBody>
            {isEmpty(jobApplicationList) ? (
              <EmptyList />
            ) : (
              jobApplicationList.map((row: JobApplicationInterface, idx: number) => (
                <TableRow
                  customRoot={rowIsSelected(row.id) ? css(commonStyles.greenRow) : ''}
                  row={row}
                  idx={idx}
                  id={row.id?.toString()}
                  key={row.id}
                  onClick={(): void => {
                    const query = encode(JSON.stringify(listParams));
                    const page = encode(JSON.stringify(pagination));
                    navigate(`/job_applications/${type}?query=${query}&page=${page}#${row.id}`, {
                      replace: true,
                    });
                    navigate(`/job_applications/${type}/${row.id}`);
                  }}
                >
                  <TableCell className={css(styles.paddingCheckbox)}>
                    <Checkbox onClick={(e): void => selectRow(e, row)} checked={rowIsSelected(row.id)} />
                  </TableCell>
                  <TableCell
                    scope="row"
                    width="150"
                    style={{ paddingTop: '2px', paddingBottom: '2px', marginRight: '10px', paddingLeft: '0px' }}
                  >
                    <b>{row.first_name + ' ' + row.surname}</b>
                  </TableCell>
                  <TableCell align="left" className={css(styles.mobile)} onClick={(e): void => e.stopPropagation()}>
                    <Grid container justifyContent="center" style={{ minWidth: '130px', maxWidth: '200px' }}>
                      <Grid item xs={8} sm={8} md={8} lg={8} style={{ minWidth: '80px' }}>
                        {row.mobile && mobileLink(row.mobile)}
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} style={{ minWidth: '15px' }}>
                        {row.email && (
                          <div>
                            <EmailPopover email={row.email} icon={true}></EmailPopover>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  {type === 'team_leader' && (
                    <TableCell
                      align="left"
                      style={{ paddingTop: '2px', paddingBottom: '2px', paddingLeft: '10px', paddingRight: '10px' }}
                      scope="row"
                    >
                      <p>{row.display_type}</p>
                    </TableCell>
                  )}
                  {row.status == 'deleted' ||
                  row.status == 'called_for_interview' ||
                  row.status == 'D_reserve' ||
                  row.status == 'TL_reserve' ? (
                    <TableCell align="left" className={css(styles.tableCell)} style={{ width: '95px' }}>
                      <Tooltip
                        title={<b style={{ fontSize: '12px', fontWeight: '400' }}>{getStatusInfo(row)}</b>}
                        placement="top-start"
                      >
                        <div>
                          {renderStatusIcon(row.status)}
                          {swedish_status[row.status]}
                        </div>
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell align="left" className={css(styles.tableCell)} style={{ width: '95px' }}>
                      {row.status ? renderStatusIcon(row.status) : null}
                      {row.status ? swedish_status[row.status] : null}
                    </TableCell>
                  )}

                  <TableCell align="left" className={css(styles.tableCell)}>
                    <Tooltip
                      title={<p style={{ fontSize: '1.25em' }}>{getTravelTimesString(row)}</p>}
                      placement="top-start"
                    >
                      <div className={css(styles.travelTimes)}>{getTravelTimesString(row)}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={css(styles.tableCell)} align="left">
                    {row.birth_date ? '- ' + row.birth_date.slice(2, 4) : ''}
                  </TableCell>
                  <TableCell align="left" className={css(styles.tableCell)}>
                    {row.city}
                  </TableCell>
                  <TableCell align="left" className={css(styles.tableCell)}>
                    {formatDate(row.created_at)}
                  </TableCell>
                  <MenuTableCell>
                    <MenuItem>
                      <ResetFab
                        onClick={(): void => {
                          handleStatusUpdate([row.id], 'default');
                        }}
                        text="Återställ"
                      />
                    </MenuItem>
                    <MenuItem>
                      <CallInterviewFab
                        onClick={(): void => handleStatusUpdate([row.id], 'called_for_interview')}
                        text="Kalla"
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={(): void => {
                        setQuickActionJobApplication([row]);
                        setSelectedJobApplications([]);
                      }}
                    >
                      <EmployFab onClick={(): void => setEmployModalState(true)} text="Anställ" />
                    </MenuItem>
                    <MenuItem
                      onClick={(): void => {
                        setQuickActionJobApplication([row]);
                        setSelectedJobApplications([]);
                      }}
                    >
                      <ReserveFab onClick={(): void => setReserveModalState(true)} text="Reserv" />
                    </MenuItem>
                    <MenuItem
                      onClick={(): void => {
                        setQuickActionJobApplication([row]);
                        setSelectedJobApplications([]);
                      }}
                    >
                      <TaBortFab onClick={(): void => setTaBortModalState(true)} text="Arkivera" />
                    </MenuItem>
                  </MenuTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <Dialog
          open={reserveModalState}
          onClose={(): void => {
            setReserveModalState(false);
            setQuickActionJobApplication([]);
          }}
          fullWidth={true}
          maxWidth="md"
        >
          <UpdateJobApplicationStatusModal
            jobApplications={quickActionJobApplication[0] ? quickActionJobApplication : selectedJobApplications}
            status={status['reserve']}
            setModalState={setReserveModalState}
            fetchJobApplicationsList={fetchJobApplications}
            listParams={listParams}
            setSelectedJobApplications={setSelectedJobApplications}
            setQuickActionJobApplication={setQuickActionJobApplication}
          />
        </Dialog>
        <Dialog
          open={employModalState}
          onClose={(): void => {
            setEmployModalState(false);
            setQuickActionJobApplication([]);
          }}
          fullWidth={true}
          maxWidth="md"
        >
          <EmployJobApplicationModal
            // only one application should be selected for employment
            jobApplication={quickActionJobApplication[0] ?? selectedJobApplications[0]}
            setModalState={setEmployModalState}
            fetchJobApplicationsList={fetchJobApplications}
            listParams={listParams}
            type={type}
            setSelectedJobApplications={setSelectedJobApplications}
          />
        </Dialog>
        <Dialog
          open={taBortModalState}
          onClose={(): void => {
            setTaBortModalState(false);
            setQuickActionJobApplication([]);
          }}
          fullWidth={true}
          maxWidth="md"
        >
          <UpdateJobApplicationStatusModal
            jobApplications={quickActionJobApplication[0] ? quickActionJobApplication : selectedJobApplications}
            status={status['deleted']}
            setModalState={setTaBortModalState}
            fetchJobApplicationsList={fetchJobApplications}
            listParams={listParams}
            setSelectedJobApplications={setSelectedJobApplications}
            setQuickActionJobApplication={setQuickActionJobApplication}
          />
        </Dialog>

        <Pagination
          pageTopRef={pageTopRef}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={jobApplicationsCount}
        />

        {!isNotEmpty(selectedJobApplications) && <CreateFab link={`/job_applications/${type}/create`} />}
        {(loadingJA || loadingResidences || loadingLanguages) && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loadingJA || loadingResidences || loadingLanguages} />
          </div>
        )}

        {isNotEmpty(selectedJobApplications) && (
          <Grid
            container
            spacing={2}
            style={{ justifyContent: 'flex-start', position: 'sticky', bottom: '20px', zIndex: '2' }}
          >
            <Grid item xs={1} sm={1} md={1} lg={2}></Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <SelectionFabs />
            </Grid>
            <Grid item xs={3} sm={3} md={2} lg={2}></Grid>
          </Grid>
        )}
      </div>
    </React.Fragment>
  );
};

export default JobApplications;
