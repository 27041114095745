import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useNotify } from '../Common/snackbarHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { JobApplicationInterface } from './types';
import { getDefaultsWithActualValues } from '../Common/utilities';
import { Button } from '@mui/material';
import { getJobApplicationByUpdateToken, updateJobApplicationByUpdateToken } from './jobApplicationApi';
import logo from '../Common/logo.png';
import { initialValues, updatePhoneValidationSchema } from './jobApplicationSchema';
import FormFreeTextField from '../Common/FormFreeItems/FormFreeTextField';

export default function EditExtraHour() {
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();
  const [jobApplication, setJobApplication] = useState<JobApplicationInterface>();
  const params = useParams();
  const jobApplicationUpdateToken = useMemo(() => {
    const updateToken = params.id;
    return updateToken;
  }, [params.id]);

  useEffect(() => {
    if (!jobApplicationUpdateToken) return;
    getJobApplicationByUpdateToken(jobApplicationUpdateToken)
      .then(({ data }) => setJobApplication(data.data))
      .catch((error) => notifyError(`Det gick inte att hitta ansökan`));
  }, [jobApplicationUpdateToken]);

  async function handleSubmit({ mobile }: Pick<JobApplicationInterface, 'mobile'>) {
    if (!jobApplication) return;

    await updateJobApplicationByUpdateToken(jobApplicationUpdateToken, { mobile: mobile })
      .then(() => notifySuccess('Din ansökan har uppdaterats.'))
      .then(() => window.location.replace('https://ungomsorg.se'))
      .catch((error) => notifyError(`Det gick inte att redigera ansökan: ${HandleError(error.response.data)}`));
  }

  return (
    <div className="pl-[15%] pr-[15%] mx-8 mb-5">
      {/* Title */}
      <div className="flex flex-col pt-8 space-y-8">
        <div className="self-center">
          <img className="w-[300px]" src={logo} alt="Logo" />
        </div>
        <div className="self-center">
          <span className="text-2xl font-bold text-center">Komplettera dina uppgifter</span>
        </div>
      </div>
      {/* Content */}
      <div className="flex mt-8">
        <span>
          Hej {jobApplication?.first_name} {jobApplication?.surname}! Du har blivit inlagd i vårt system som intresserad
          av att jobba extra hos oss. Om du inte kompletterar dina uppgifter kommer vi tyvärr inte att kunna kontakta
          dig när det blir lediga jobb i din närhet.
        </span>
      </div>
      <div className="flex mt-8">
        <span>
          Fyll i ditt telefonnummer så att vi kan hantera din ansökan. Uppgifterna sprids inte utanför Ung Omsorg. När
          det blir platser lediga på ett äldreboende i din närhet kommer vi att kalla till intervjuer utifrån längst
          kötid.
        </span>
      </div>

      {/* Form */}
      {jobApplication && (
        <Formik
          enableReinitialize
          validateOnMount={true}
          initialValues={getDefaultsWithActualValues(initialValues, jobApplication)}
          validationSchema={updatePhoneValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, handleChange, handleSubmit, dirty, isSubmitting, isValid }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col mt-8 gap-6 xl:gap-8">
                <div className="self-center !w-48">
                  <FormFreeTextField
                    size={null}
                    name={`mobile`}
                    label="Mobiltelefon"
                    placeholder="Mobiltelefon"
                    values={values}
                    errors={errors}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                  />
                </div>
                <div className="self-center !w-48">
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={!dirty || !isValid || isSubmitting}
                    fullWidth
                    type="submit"
                  >
                    Spara
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}
