import React, { useState, useEffect, ReactElement } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import { RotateLoader } from 'react-spinners';
import { workShiftsReturnRegex } from '../Common/utilities';

import { validationSchema, initialValues } from './workShiftSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import WorkShiftForm from './components/WorkShiftForm';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { OptionsInterface } from '../Common/types';

import { getDefaultParticipants, getTeam, getTempTeamLeaders } from '../Team/teamApi';
import { TeamInterface } from '../Team/types';
import { getUsersMinimalList } from '../User/usersApi';
import { ScheduledParticipantInterface, WorkShiftInterface } from './types';
import { MinimalWorkShiftUserInterface } from '../User/types';
import { createWorkShift, getLatestWorkShift } from './workShiftApi';
import { getSalaryClassesMinimalList } from '../SalaryClass/salaryClassApi';

const CreateWorkShift: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const { teamId } = useParams() as { teamId: string };
  const location = useLocation();

  const [initialFormValues, setInitialFormValues] = useState(
    initialValues as {
      date?: string | null;
      start_time?: number;
      end_time?: number;
      scheduled_participants: ScheduledParticipantInterface[];
      editable: boolean;
      deletable: boolean;
    }
  );

  const [team, setTeam] = useState({} as TeamInterface);
  const [latestWorkShift, setLatestWorkShift] = useState({} as WorkShiftInterface);
  const [salaryClassesMinimalList, setSalaryClassesMinimalList] = useState([] as OptionsInterface[]);
  const [defaultParticipantsMinimalList, setDefaultParticipantsMinimalList] = useState<MinimalWorkShiftUserInterface[]>(
    []
  );
  const [participantsMinimalList, setParticipantsMinimalList] = useState<MinimalWorkShiftUserInterface[]>([]);

  const [loadingTeam, setLoadingTeam] = useState(false);
  const [loadingLatestWorkShift, setLoadingLatestWorkShift] = useState(false);
  const [loadingDefaultParticipants, setLoadingDefaultParticipants] = useState(false);
  const [loadingSalaryClasses, setLoadingSalaryClasses] = useState(false);
  const [loadingParticipants, setLoadingParticipants] = useState(false);

  // functions
  useEffect(() => {
    fetchTeam();
    fetchLatestWorkShift();
    fetchParticipants();
    // fetchTemporaryTeamLeaders();
    fetchSalaryClasses();
  }, []);

  useEffect(() => {
    if (latestWorkShift && Object.keys(latestWorkShift).length > 0) {
      const latestWorkShiftDate = dayjs(latestWorkShift.date);

      if (dayjs().isAfter(latestWorkShiftDate.add(7, 'day'), 'day')) {
        setInitialFormValues({
          date: null,
          start_time: 13.0,
          end_time: 15.0,
          scheduled_participants: [],
          editable: true,
          deletable: false,
        });
      } else {
        setInitialFormValues({
          date: latestWorkShiftDate.add(7, 'day').format('YYYY-MM-DD'),
          start_time: latestWorkShift.start_time,
          end_time: latestWorkShift.end_time,
          scheduled_participants: [],
          editable: true,
          deletable: false,
        });
      }
    } else {
      setInitialFormValues({
        date: null,
        start_time: 13.0,
        end_time: 15.0,
        scheduled_participants: [],
        editable: true,
        deletable: false,
      });
    }
  }, [latestWorkShift]);

  const fetchTeam = (): void => {
    setLoadingTeam(true);
    getTeam(teamId)
      .then(({ data }) => setTeam(data.data))
      .catch(() => notifyError('Det gick inte att hämta teamet.'))
      .finally(() => setLoadingTeam(false));
  };

  const fetchLatestWorkShift = (): void => {
    setLoadingLatestWorkShift(true);
    getLatestWorkShift({ team_id: parseInt(teamId) })
      .then(({ data }) => {
        setLatestWorkShift(data.data);

        if (Object.keys(data.data).length > 0) {
          const latestWorkShiftDate = dayjs(data.data.date);

          if (dayjs().isAfter(latestWorkShiftDate.add(7, 'day'), 'day')) {
            fetchDefaultParticipants();
          } else {
            fetchDefaultParticipants(latestWorkShiftDate.add(7, 'day').format('YYYY-MM-DD'));
          }
        } else {
          fetchDefaultParticipants();
        }
      })
      .catch(() => notifyError('Det gick inte att hämta det senaste passet.'))
      .finally(() => setLoadingLatestWorkShift(false));
  };

  const fetchDefaultParticipants = (date?: string | null): void => {
    setLoadingDefaultParticipants(true);
    getDefaultParticipants(parseInt(teamId), { date: date })
      .then(({ data }) => setDefaultParticipantsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta deltagare.'))
      .finally(() => setLoadingDefaultParticipants(false));
  };

  const fetchParticipants = (): void => {
    setLoadingParticipants(true);
    getUsersMinimalList({ hide_banned: true, filter_teams: [parseInt(teamId)], with_role: true, name_with_role: true })
      .then(({ data }) => setParticipantsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta deltagare.'))
      .finally(() => setLoadingParticipants(false));
  };

  const fetchSalaryClasses = (): void => {
    setLoadingSalaryClasses(true);
    getSalaryClassesMinimalList({ custom: false })
      .then(({ data }) => setSalaryClassesMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta lönearter.'))
      .finally(() => setLoadingSalaryClasses(false));
  };

  // functions
  const handleFormSubmit = (values: WorkShiftInterface, setSubmitting: (isSubmitting: boolean) => void): void => {
    values.team_id = parseInt(teamId);
    values.scheduled_participants_attributes = values.scheduled_participants;

    setSubmitting(true);

    createWorkShift(values)
      .then(() => notifySuccess('Passet har skapats.'))
      .then(() => {
        if (location.key !== 'default' && workShiftsReturnRegex.test(location?.state?.pathname)) {
          const { state } = location;
          navigate(state.pathname + state.search + state.hash);
        } else {
          navigate(`/work-shifts`);
        }
      })
      .catch((error: any) => {
        notifyError(`Det gick inte att skapa ansökan: \n${HandleError(error.response.data)}`);
      })
      .finally(() => setSubmitting(false));
  };

  // render
  return (
    <React.Fragment>
      {loadingTeam ||
      loadingLatestWorkShift ||
      loadingDefaultParticipants ||
      loadingParticipants ||
      loadingSalaryClasses ? (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader
            loading={
              loadingTeam ||
              loadingLatestWorkShift ||
              loadingDefaultParticipants ||
              loadingParticipants ||
              loadingSalaryClasses
            }
          />
        </div>
      ) : (
        <React.Fragment>
          <div className={css(commonStyles.createViewHeader)}>
            <h1 className={css(commonStyles.headerTextStyle)}>Lägg till schemalagt pass för {team.name}</h1>
            <CancelLink
              link={
                location.key === 'default' || !workShiftsReturnRegex.test(location.state?.pathname)
                  ? `/work-shifts`
                  : location.state.pathname + location.state.search + location.state.hash
              }
              goBack={workShiftsReturnRegex.test(location.state?.pathname)}
            />
          </div>

          <div className={css(commonStyles.formContainer)}>
            <Formik
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }): void => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                setSubmitting,
                isValid,
                dirty,
                validateForm,
              }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <WorkShiftForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    isCreate={true}
                    setFieldValue={setFieldValue}
                    setSubmitting={setSubmitting}
                    validateForm={validateForm}
                    submitDisabled={isSubmitting || !isValid || !dirty}
                    team={team}
                    salaryClassesMinimalList={salaryClassesMinimalList}
                    defaultParticipantsMinimalList={defaultParticipantsMinimalList}
                    participantsMinimalList={participantsMinimalList}
                    handleFormSubmit={handleFormSubmit}
                  />
                </form>
              )}
            </Formik>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CreateWorkShift;
