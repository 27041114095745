import React, { ReactElement } from 'react';

import { commonStyles, css, StyleSheet } from '../../Common/styling';
import { SubdirectoryArrowRight } from '@mui/icons-material';
import { Button, TableCell, TableRow } from '@mui/material';

const styles = StyleSheet.create({
  subrowWrapper: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  hour: {
    textAlign: 'left',
  },
});

type Props = {
  row: any;
  index: number;
  invoiceAgreement: any;
  openCorrection: (invoiceAgreement: any, team: any) => void;
};

export const SubRow: React.FC<Props> = (props) => {
  const AdditionalHours = (): ReactElement => {
    return (
      <React.Fragment>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>
          <SubdirectoryArrowRight />
        </TableCell>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>
          {props.row.month} {props.row.year}
        </TableCell>
        <TableCell colSpan={4} className={css(commonStyles.tableCellSmaller)}>
          Team: {props.row.team.name}
        </TableCell>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>Kvarstående:</TableCell>
        <TableCell className={css(commonStyles.tableCellSmaller, styles.hour)}>{props.row.hours}</TableCell>
        <TableCell colSpan={3} className={css(commonStyles.tableCellSmaller)}></TableCell>
      </React.Fragment>
    );
  };

  const Overwork = (): ReactElement => {
    return (
      <React.Fragment>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>
          <SubdirectoryArrowRight />
        </TableCell>

        <TableCell className={css(commonStyles.textRed, commonStyles.tableCellSmaller)}>
          <b>Överarbete avtal</b>
        </TableCell>

        <TableCell colSpan={4} className={css(commonStyles.tableCellSmaller)}>
          Team: {props.row.team.name}
        </TableCell>

        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>Varav överarbete:</TableCell>

        <TableCell className={css(commonStyles.textRed, commonStyles.tableCellSmaller)} align="right">
          <b>{props.row.hours}</b>
        </TableCell>

        <TableCell colSpan={1} className={css(commonStyles.tableCellSmaller)}>
          <Button
            size="small"
            variant="outlined"
            onClick={(): void => props.openCorrection(props.invoiceAgreement, props.row.team)}
          >
            HANTERA
          </Button>
        </TableCell>
        <TableCell colSpan={2} className={css(commonStyles.tableCellSmaller)}></TableCell>
      </React.Fragment>
    );
  };

  const UnconfirmedHours = (): ReactElement => {
    return (
      <React.Fragment>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>
          <SubdirectoryArrowRight />
        </TableCell>
        <TableCell colSpan={2} className={css(commonStyles.textRed, commonStyles.tableCellSmaller)}>
          <b>{props.row.title}</b>
        </TableCell>
        <TableCell colSpan={9} className={css(commonStyles.textRed, commonStyles.tableCellSmaller)}>
          Det finns pass som inte är konfirmerade i systemet, åtgärda detta i Schemavyn
        </TableCell>
      </React.Fragment>
    );
  };

  const RegularHours = (): ReactElement => {
    return (
      <React.Fragment>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>
          <SubdirectoryArrowRight />
        </TableCell>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>
          {props.row.month} {props.row.year}
        </TableCell>
        <TableCell colSpan={4}>Team: {props.row.team.name}</TableCell>
        <TableCell className={css(commonStyles.tableCellSmaller)}>Timmar:</TableCell>
        <TableCell className={css(commonStyles.tableCellSmaller, styles.hour)}>{props.row.hours}</TableCell>
        <TableCell colSpan={3} className={css(commonStyles.tableCellSmaller)}></TableCell>
      </React.Fragment>
    );
  };

  const OutstandingHours = (): ReactElement => {
    return (
      <React.Fragment>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>
          <SubdirectoryArrowRight />
        </TableCell>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>
          {props.row.month} {props.row.year}
        </TableCell>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>{props.row.title}</TableCell>
        <TableCell colSpan={3} className={css(commonStyles.tableCellSmaller)}>
          Team: {props.row.team.name}
        </TableCell>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>Timmar:</TableCell>
        <TableCell className={css(commonStyles.tableCellSmaller, styles.hour)}>{props.row.hours}</TableCell>
        <TableCell colSpan={3} className={css(commonStyles.tableCellSmaller)}></TableCell>
      </React.Fragment>
    );
  };

  const WorkHoursCorrections = (): ReactElement => {
    return (
      <React.Fragment>
        <TableCell className={css(styles.subrowWrapper)}>
          <SubdirectoryArrowRight />
        </TableCell>
        <TableCell className={css(styles.subrowWrapper)}>
          {props.row.month} {props.row.year}
        </TableCell>
        <TableCell colSpan={4}>Team: {props.row.team.name}</TableCell>
        <TableCell className={css(styles.subrowWrapper, commonStyles.tableCellSmaller)}>Korrigerade:</TableCell>
        <TableCell className={css(commonStyles.tableCellSmaller, styles.hour)}>{props.row.hours}</TableCell>
        <TableCell colSpan={3} className={css(commonStyles.tableCellSmaller)}></TableCell>
      </React.Fragment>
    );
  };

  return (
    <TableRow>
      {props.row.type === 'additional_hours' && <AdditionalHours />}
      {props.row.type === 'unconfirmed_hours' && <UnconfirmedHours />}
      {props.row.type === 'overwork' && <Overwork />}
      {props.row.type === 'regular_hours' && <RegularHours />}
      {props.row.type === 'outstanding_hours' && <OutstandingHours />}
      {props.row.type === 'work_hours_corrections' && <WorkHoursCorrections />}
    </TableRow>
  );
};
