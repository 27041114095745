import React, { ReactElement, useState } from 'react';

import { FormTextField } from '../../Common/FormItems';
import { commonStyles, css, theme } from '../../Common/styling';
import { Button, Divider, Grid } from '@mui/material';

import { FieldArray, FieldArrayRenderProps, FormikErrors, FormikHandlers } from 'formik';
import { PersonInterface } from '../../Common/types';
import { ResidenceInterface } from '../../Residence/types';
import ConfirmDeletePersonModal from './ConfirmDeletePersonModal';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { TeamInterface } from '../types';

type Props = {
  model: ResidenceInterface | TeamInterface;
  errors: FormikErrors<ResidenceInterface>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  showDividers?: boolean;
};

export default function AddPeople({ model, errors, handleChange, setFieldValue, showDividers }: Props) {
  const [selectedPerson, setSelectedPerson] = useState<PersonInterface>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [showDeletePersonModal, setShowDeletePersonModal] = useState<boolean>(false);

  const emptyAmbassador = {
    name: '',
    email: '',
    mobile: '',
    person_type: 'ambassador',
  };

  const emptyResidenceManager = {
    name: '',
    email: '',
    mobile: '',
    person_type: 'residence_manager',
  };

  return (
    <>
      <FieldArray
        name="people_attributes"
        render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
          <React.Fragment>
            {model.people_attributes &&
            model.people_attributes.filter(
              (person: PersonInterface) => person.person_type === 'ambassador' && person._destroy !== 1
            ).length > 0 ? (
              model.people_attributes
                .map((person: PersonInterface, index: number) => ({ person, index }))
                .filter(({ person }) => person.person_type === 'ambassador' && person._destroy !== 1)
                .map(({ person, index }) => (
                  <React.Fragment key={index}>
                    <Grid container spacing={2} alignItems="center" alignContent="space-between">
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.name`}
                          label="Ambassadör namn"
                          placeholder="Ambassadör namn"
                          values={model}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.email`}
                          label="Ambassadör e-post"
                          placeholder="Ambassadör e-post"
                          values={model}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.mobile`}
                          label="Ambassadör telefon"
                          placeholder="Ambassadör telefon"
                          values={model}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item style={{ display: 'none' }}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.person_type`}
                          label=""
                          placeholder=""
                          values={model}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button
                          variant="text"
                          color="error"
                          style={{ marginBottom: '15px' }}
                          onClick={(): void => {
                            setSelectedPerson(person);
                            setSelectedIndex(index);
                            setShowDeletePersonModal(true);
                          }}
                        >
                          <ClearIcon className={css(commonStyles.deleteIcon)} />
                        </Button>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Button
                          variant="outlined"
                          style={{ color: theme.themeGreen, borderColor: theme.themeGreen, marginBottom: '15px' }}
                          onClick={(): void => arrayHelpers.push(emptyAmbassador)}
                        >
                          <AddIcon style={{ color: theme.themeGreen, marginTop: '1px', marginBottom: '1px' }} />
                          LÄGG TILL
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button
                      variant="outlined"
                      className="!my-5"
                      onClick={(): void => arrayHelpers.push(emptyAmbassador)}
                    >
                      <AddIcon style={{ color: theme.themeGreen, marginRight: '10px' }} />
                      LÄGG TILL UO-AMBASSADÖR
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      />
      {model.people_attributes &&
        model.people_attributes.filter(
          (person: PersonInterface) => person.person_type === 'ambassador' && person._destroy !== 1
        ).length > 0 &&
        showDividers && <Divider style={{ marginBottom: '20px' }} />}
      <FieldArray
        name="people_attributes"
        render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
          <React.Fragment>
            {model.people_attributes &&
            model.people_attributes.filter(
              (person: PersonInterface) => person.person_type === 'residence_manager' && person._destroy !== 1
            ).length > 0 ? (
              model.people_attributes
                .map((person: PersonInterface, index: number) => ({ person, index }))
                .filter(({ person }) => person.person_type === 'residence_manager' && person._destroy !== 1)
                .map(({ person, index }) => (
                  <React.Fragment key={index}>
                    <Grid container spacing={2} alignItems="center" alignContent="space-between">
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.name`}
                          label="Verksamhetschef namn"
                          placeholder="Verksamhetschef namn"
                          values={model}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.email`}
                          label="Verksamhetschef e-post"
                          placeholder="Verksamhetschef e-post"
                          values={model}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.mobile`}
                          label="Verksamhetschef telefon"
                          placeholder="Verksamhetschef telefon"
                          values={model}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item style={{ display: 'none' }}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.person_type`}
                          label=""
                          placeholder=""
                          values={model}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button
                          variant="text"
                          color="error"
                          style={{ marginBottom: '15px' }}
                          onClick={(): void => {
                            setSelectedPerson(person);
                            setSelectedIndex(index);
                            setShowDeletePersonModal(true);
                          }}
                        >
                          <ClearIcon className={css(commonStyles.deleteIcon)} />
                        </Button>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Button
                          variant="outlined"
                          style={{ color: theme.themeGreen, borderColor: theme.themeGreen, marginBottom: '15px' }}
                          onClick={(): void => arrayHelpers.push(emptyResidenceManager)}
                        >
                          <AddIcon style={{ color: theme.themeGreen, marginTop: '1px', marginBottom: '1px' }} />
                          LÄGG TILL
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button
                      variant="outlined"
                      className="!my-5"
                      onClick={(): void => arrayHelpers.push(emptyResidenceManager)}
                    >
                      <AddIcon style={{ color: theme.themeGreen, marginRight: '10px' }} />
                      LÄGG TILL VERKSAMHETSCHEF
                    </Button>
                  </Grid>
                </Grid>
                {showDividers && <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      />
      {model.people_attributes &&
        model.people_attributes.filter(
          (person: PersonInterface) => person.person_type === 'residence_manager' && person._destroy !== 1
        ).length > 0 &&
        showDividers && <Divider style={{ marginBottom: '20px' }} />}
      {showDeletePersonModal && (
        <ConfirmDeletePersonModal
          onConfirm={(): void => {
            if (selectedPerson) {
              // If it's an existing person (has an id), we mark it for destruction
              if (selectedPerson.id) {
                setFieldValue(`people_attributes[${selectedIndex}]._destroy`, 1);
              } else {
                // Otherwise, we remove it from the array
                if (model.people_attributes) {
                  const newPeople = [...model.people_attributes];
                  newPeople.splice(selectedIndex, 1);
                  setFieldValue('people_attributes', newPeople);
                }
              }
            }
            setShowDeletePersonModal(false);
          }}
          onClose={(): void => {
            setShowDeletePersonModal(false);
          }}
          isVisible={showDeletePersonModal}
          person={selectedPerson}
        />
      )}
    </>
  );
}
