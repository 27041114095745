import BusinessIcon from '@mui/icons-material/Business';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import GroupIcon from '@mui/icons-material/Group';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTipOutlined';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { encode } from 'base-64';
import dayjs from 'dayjs';
import React, { MouseEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';

import AuthContext from '../Common/AuthContext';
import BouncingDots from '../Common/BouncingDots';
import { CreateFab, ExportFab, QuickAction } from '../Common/ButtonLinks';
import QuickLinkAction from '../Common/ButtonLinks/QuickLinkAction';
import { FilterAutocompleteBox } from '../Common/FilterPopover';
import { MenuTableCell } from '../Common/Table';
import { useNotify } from '../Common/snackbarHooks';
import { StyleSheet, commonStyles, css } from '../Common/styling';
import { AuthContextValue, OptionsInterface } from '../Common/types';
import { stringToPositiveInt, useQuery } from '../Common/utilities';
import { getRegionsMinimalList } from '../Region/regionApi';
import { getUsersMinimalList } from '../User/usersApi';
import { filterSortDefaults as userFilterDefaults } from '../User/usersSchema';

import { getTeamList } from './teamApi';
import { TeamListInterface } from './types';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';
import ExportModal from './components/ExportModal';
import TableBox, { TableBoxColumn } from '../Common/Table/TableBox';
import TeamsSearchBar from './components/SearchBar';
import TeamsFilterPopover from './components/FilterPopover';

export default function Teams() {
  // hooks
  const pageTopRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyError } = useNotify();
  const [infoModalState, setInfoModalState] = useState(false);
  const [teamList, setTeamList] = useState<Array<TeamListInterface>>([]);
  const [teamsCount, setTeamsCount] = useState<number>();
  const [showNextPeriod, setShowNextPeriod] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState<Array<OptionsInterface>>([]);
  const [loadingRegions, setLoadingRegions] = useState(true);
  const [users, setUsers] = useState<Array<OptionsInterface>>([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [residenceGroups, setResidenceGroups] = useState<Array<OptionsInterface>>([]);
  const [loadingResidenceGroups, setLoadingResidenceGroups] = useState(true);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const { hasPermissions, profile } = useContext(AuthContext) as AuthContextValue;

  const [query, setQuery] = useQuery({
    search: (val) => val,
    residenceGroup: (val) => stringToPositiveInt(val),
    operationalManager: (val) => val,
    region: (val) => stringToPositiveInt(val),
    showInactive: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
    sortBy: (val) => val,
    sortOrder: (val) => (val === 'asc' ? ('asc' as const) : val === 'desc' ? ('desc' as const) : undefined),
    page: (val) => stringToPositiveInt(val),
  });

  const tableColumns = useMemo<TableBoxColumn[]>(
    () => [
      { id: 'region_name', label: 'Region', width: 'sm', lineStyle: 'compress' },
      { id: 'name', label: 'Team', width: 'md', lineStyle: 'compress' },
      {
        id: 'no_employed',
        label: 'Anställda',
        sortable: false,
        width: 'xs',
        tooltip:
          'Så många inklusive teamledaren är anställda i teamet. Blir rött om antalet anställda i teamet är färre än standardnärvaron +2. Är teamets standardnärvaro 3 eller färre är gränsen +1.',
      },
      {
        id: 'days_last_work_shift',
        label: 'Senaste pass',
        width: 'sm',
        tooltip:
          'Antal dagar sedan senaste inrapporterade passet, blir rött om antalet överstiger 14 dagar. Närvaro senaste pass / Teamets standardnärvaro, blir rött om två eller fler var frånvarande på senaste passet jämfört med teamets standardnärvaro.',
      },
      {
        id: 'hours_current_period',
        label: 'Kv.Schemalägga',
        width: 'sm',
        tooltip:
          'Så här många timmar har teamet Kvar att schemalägga i nuvarande schemaperiod för att ligga i fas. Blir rött om det är större än +/- 2,5 standardpass.',
      },
      {
        id: 'hours_next_period',
        label: 'Kommande',
        hide: !showNextPeriod,
        width: 'sm',
        tooltip:
          'Så här många timmar har teamet Kvar att schemalägga i kommande schemaperiod för att ligga i fas. Blir rött om det inte finns minst 1 standardpass extra lagt som buffert. (används bara i jan, maj och sep när det är dags att schemalägga).',
      },
      {
        id: 'catastrophe_work_shifts',
        label: 'Katastrof',
        width: 'xs',
        tooltip:
          'Antalet pass de senaste två månaderna där teamet haft katastrofdålig närvaro. Detta innebär 2 eller fler frånvarande om teamet har standardnärvaro 3-5, samt 3 eller fler frånvarande om standardnärvaron är 6 eller högre.',
      },
      { id: 'info', label: 'Info', width: 'xs' },
    ],
    [showNextPeriod]
  );

  const styles = StyleSheet.create({
    groupIcon: {
      position: 'relative',
      bottom: '1px',
      paddingLeft: '7px',
      verticalAlign: 'middle',
    },
    tooltipText: {
      width: '182px',
      height: '16px',
      margin: '5px 10px 15px 10px', // top right bottom left
      fontSize: '13px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.85',
      letterSpacing: '0.09px',
      textAlign: 'center',
      color: 'rgba(255, 255, 255, 0.87)',
    },
  });

  // This useEffect takes place on first page load
  useEffect(() => {
    fetchRegions();
    fetchUsers();
    fetchResidenceGroups();
  }, []);

  useEffect(() => {
    if (firstLoad && !query.operationalManager && hasPermissions(['operational_manager'])) {
      setFirstLoad(false);
      setQuery({ operationalManager: profile.id });
    } else {
      fetchTeamsList();
    }
  }, [
    query.search,
    query.residenceGroup,
    query.operationalManager,
    query.region,
    query.showInactive,
    query.sortBy,
    query.sortOrder,
    query.page,
  ]);

  // functions
  const fetchTeamsList = (): void => {
    setLoading(true);
    getTeamList({
      query: query.search,
      filter_residence_group: query.residenceGroup,
      filter_operational_manager: query.operationalManager,
      filter_region: query.region,
      is_active: !query.showInactive,
      sort_by: query.sortBy ?? 'region_name',
      sort_order: query.sortOrder ?? 'asc',
      page: query.page ?? 1,
    })
      .then(({ data }) => {
        setTeamList(data.data);
        setTeamsCount(data.meta.count);
        setShowNextPeriod(data.meta.show_next_period);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över teams'))
      .finally(() => {
        const element = document.getElementById(location.hash.substring(1)); // need to drop '#' from the beginning of the id
        if (element) {
          element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
        }
        setLoading(false);
      });
  };

  const fetchRegions = (): void => {
    getRegionsMinimalList({ is_active: true })
      .then(({ data }) => setRegions(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner.'))
      .finally(() => setLoadingRegions(false));
  };

  const fetchUsers = (): void => {
    getUsersMinimalList({ hide_banned: true, filter_by_role_name: 'operational_manager' })
      .then(({ data }) => setUsers(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över VA.'))
      .finally(() => setLoadingUsers(false));
  };

  const fetchResidenceGroups = (): void => {
    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroups(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalspart.'))
      .finally(() => setLoadingResidenceGroups(false));
  };

  const dateFormat = (timestamp: string): string => {
    const date = dayjs(timestamp);
    return date.format('YYYY-MM-DD');
  };

  const toolTipText = (row: TeamListInterface): React.ReactNode => {
    return (
      <>
        <p className={css(styles.tooltipText)}>
          {row.team_agreement
            ? `${dateFormat(row.team_agreement?.start_date)} - ${dateFormat(row.team_agreement?.end_date)}`
            : 'Inget avtal finns'}
        </p>
        {row.team_agreement && (
          <p className={css(styles.tooltipText)}>
            {`Inrapporterat: ${row.team_agreement?.reported_hours} / ${row.team_agreement?.budgeted_hours}h`}
          </p>
        )}
        <p className={css(styles.tooltipText)}>{`VA: ${row.operational_manager?.name ?? '-'}`}</p>
        <p className={css(styles.tooltipText)}>{`RC: ${row.regional_manager?.name ?? '-'}`}</p>
      </>
    );
  };

  function handleSortChange(col: string) {
    return setQuery({ sortBy: col, sortOrder: query.sortBy !== col || query.sortOrder === 'desc' ? 'asc' : 'desc' });
  }

  return (
    <div ref={pageTopRef}>
      <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <h1 className={css(commonStyles.headerTextStyle)}>Översikt</h1>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <p className="text-center text-sm text-black/60">
            {teamsCount !== undefined ? teamsCount : <BouncingDots />} team
          </p>
        </Grid>
        {/* <Grid item xs={3} sm={3} md={3} lg={3}>
            {hasPermissions(['admin', 'operational_leader', 'operational_manager', 'regional_manager']) && (
              <Button
                // TODO: MUI V5 add
                // color="secondary"
                // TODO: MUI V5 remove
                className={css(materialV5Styles.colorSecondary, materialV5Styles.borderColorSecondary)}
                sx={{
                  width: '180px',
                  display: 'none',
                }}
                variant="outlined"
                onClick={(): void => {
                  setInfoModalState(true);
                }}
              >
                HÄMTA INFO
              </Button>
            )}
          </Grid> */}
      </Grid>

      <div className="bg-white flex h-13">
        <TeamsSearchBar value={query.search} onChange={(value) => setQuery({ search: value || undefined })} />
        <div className="flex">
          <FilterAutocompleteBox
            options={residenceGroups ?? []}
            value={residenceGroups?.find(({ id }) => query.residenceGroup === id) ?? null}
            onChange={(option) => setQuery({ residenceGroup: option?.id, page: undefined })}
            placeholder="Avtalspart"
            getOptionLabel={(rg) => rg.name}
            loading={loadingResidenceGroups}
            clearable
            className="w-44"
          />
          <FilterAutocompleteBox
            options={users ?? []}
            value={users?.find(({ id }) => query.operationalManager === id) ?? null}
            onChange={(option) => setQuery({ operationalManager: option?.id, page: undefined })}
            placeholder="VA"
            getOptionLabel={(u) => u.name}
            loading={loadingUsers}
            clearable
            className="w-44"
          />
          <FilterAutocompleteBox
            options={regions ?? []}
            value={regions?.find(({ id }) => query.region === id) ?? null}
            onChange={(option) => setQuery({ region: option?.id, page: undefined })}
            placeholder="Region"
            getOptionLabel={(r) => r.name}
            loading={loadingRegions}
            clearable
            className="w-44"
          />
          <TeamsFilterPopover
            showInactive={query.showInactive}
            setShowInactive={(showInactive) => setQuery({ showInactive, page: undefined })}
          />
        </div>
      </div>

      <TableBox
        columns={tableColumns}
        content={teamList}
        moreActionsCol
        fullWidth
        rowDefinition={(row) => {
          function beforeNavigate(event: MouseEvent) {
            event.preventDefault();
            event.stopPropagation();
            navigate(`/teams${location.search}#${row.id}`, { replace: true });
          }
          function fromParam() {
            return 'from=' + encode(`/teams${location.search}`);
          }
          const openRegion = (event: MouseEvent) => {
            if (hasPermissions(['admin', 'operational_leader', 'operational_manager'])) {
              beforeNavigate(event);
              navigate(`/regions/${row.region?.id}`);
            }
          };
          const openTeam = (event: MouseEvent) => {
            if (
              hasPermissions(['admin', 'operational_leader', 'operational_manager']) ||
              profile.id === row.regional_manager?.id
            ) {
              beforeNavigate(event);
              navigate(`/teams/${row.id}`);
            }
          };
          const openSchedule = (event: MouseEvent) => {
            beforeNavigate(event);
            navigate(`/schedule/${row.id}?${fromParam()}`);
          };
          const usersQuery = () => encode(JSON.stringify({ ...userFilterDefaults, filter_teams: [row.id] }));
          const openUsers = (event: MouseEvent) => {
            beforeNavigate(event);
            navigate(`/users?query=${usersQuery()}&${fromParam()}`);
          };
          const openWorkShift = (event: MouseEvent) => {
            beforeNavigate(event);
            navigate(`/work-shifts/${row.latest_catastrophe_work_shift}?${fromParam()}`);
          };
          return {
            key: row.id!,
            cols: [
              {
                content: row.region?.name ?? '-',
                className: 'font-extrabold',
                link:
                  hasPermissions(['admin', 'operational_leader', 'operational_manager']) && row.region
                    ? `/regions/${row.region.id}`
                    : undefined,
                onClick: openRegion,
              },
              {
                content: row.team_name,
                link: `/teams/${row.id}`,
                onClick: openTeam,
              },
              {
                content: (
                  <p>
                    <span
                      className={
                        row.no_employed < row.standard_attendance + (row.standard_attendance <= 3 ? 1 : 2)
                          ? '!text-red-500'
                          : undefined
                      }
                    >
                      {row.no_employed}
                    </span>
                  </p>
                ),
                link: `/users?query=${usersQuery()}`,
                onClick: openUsers,
              },
              {
                content: (
                  <p>
                    <span className={(row.days_last_work_shift ?? 0) > 14 ? '!text-red-500' : undefined}>
                      {row.days_last_work_shift !== null ? `${row.days_last_work_shift}d` : 'N/A'}
                    </span>
                    {' - '}
                    <span
                      className={
                        row.attendance_last_work_shift && row.attendance_last_work_shift + 2 <= row.standard_attendance
                          ? '!text-red-500'
                          : undefined
                      }
                    >
                      {row.attendance_last_work_shift ?? 0}
                    </span>{' '}
                    / {row.standard_attendance}
                    <span className={css(styles.groupIcon)}>
                      <GroupIcon sx={{ fontSize: '1.1rem' }} />
                    </span>
                  </p>
                ),
                link: `/schedule/${row.id}`,
                onClick: openSchedule,
              },
              {
                content: `${row.hours_current_period}h`,
                className:
                  row.hours_current_period > 1.5 * row.standard_attendance * row.standard_workday ||
                  row.hours_current_period < -2.5 * row.standard_attendance * row.standard_workday
                    ? '!text-red-500'
                    : undefined,
                link: `/schedule/${row.id}`,
                onClick: openSchedule,
              },
              {
                content: `${row.hours_next_period}h`,
                className:
                  row.hours_next_period <= -1 * row.standard_attendance * row.standard_workday &&
                  row.hours_next_period >= -2.5 * row.standard_attendance * row.standard_workday
                    ? undefined
                    : '!text-red-500',
                link: `/schedule/${row.id}`,
                onClick: openSchedule,
              },
              {
                content: `${row.catastrophe_work_shifts} st.`,
                className: row.catastrophe_work_shifts > 1 ? '!text-red-500' : undefined,
                link: row.latest_catastrophe_work_shift
                  ? `/work-shifts/${row.latest_catastrophe_work_shift}`
                  : undefined,
                onClick: row.latest_catastrophe_work_shift ? openWorkShift : undefined,
              },
              {
                content: (
                  <Tooltip title={toolTipText(row)} placement={'top-start'}>
                    <InfoIcon style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '20px' }} />
                  </Tooltip>
                ),
                className: '!px-4',
                padding: 'none',
              },
              {
                content: (
                  <MenuTableCell key="extraActions">
                    {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && row.region && (
                      <MenuItem className="!p-0">
                        <QuickLinkAction
                          to={`/regions/${row.region.id}`}
                          onClick={openRegion}
                          text="Visa region"
                          label="show_region"
                          icon={<SouthAmericaIcon style={{ marginRight: '10px' }} />}
                          styles={commonStyles.selectionFabStyle3NoPadding}
                        />
                      </MenuItem>
                    )}
                    {hasPermissions(['admin', 'operational_leader']) && (
                      <MenuItem className="!p-0">
                        <QuickLinkAction
                          to={`/residences/${row.residence.id}`}
                          text="Visa boende"
                          icon={<BusinessIcon style={{ marginRight: '10px' }} />}
                          label="show_residence"
                          styles={commonStyles.selectionFabStyle3NoPadding}
                        />
                      </MenuItem>
                    )}
                    <MenuItem className="!p-0">
                      <QuickLinkAction
                        to={`/teams/${row.id}`}
                        onClick={openTeam}
                        text="Visa teamsida"
                        icon={<PrivacyTipIcon style={{ marginRight: '10px' }} />}
                        label="show_team"
                        styles={commonStyles.selectionFabStyle3NoPadding}
                      />
                    </MenuItem>
                    <MenuItem className="!p-0">
                      <QuickLinkAction
                        to={`/users?query=${usersQuery()}`}
                        onClick={openUsers}
                        text="Visa anställda"
                        icon={<GroupIcon style={{ marginRight: '10px' }} />}
                        label="show_employees"
                        styles={commonStyles.selectionFabStyle3NoPadding}
                      />
                    </MenuItem>
                    <MenuItem className="!p-0">
                      <QuickLinkAction
                        to={`/schedule/${row.id}`}
                        onClick={openSchedule}
                        text="Visa schema"
                        icon={<CalendarViewWeekIcon style={{ marginRight: '10px' }} />}
                        label="show_schedule"
                        styles={commonStyles.selectionFabStyle3NoPadding}
                      />
                    </MenuItem>
                    {hasPermissions(['admin']) && row.team_agreement && (
                      <MenuItem className="!p-0">
                        <QuickLinkAction
                          to={`/agreements/${row.team_agreement.agreement.id}`}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            navigate(`/agreements/${row.team_agreement.agreement.id}`);
                          }}
                          text="Visa avtal"
                          icon={<HowToVoteIcon style={{ marginRight: '10px' }} />}
                          label="show_agreement"
                          styles={commonStyles.selectionFabStyle3NoPadding}
                        />
                      </MenuItem>
                    )}
                    {row.team_leaders_emails.length > 0 && (
                      <MenuItem>
                        <QuickAction
                          onClick={(e): void => e.stopPropagation()}
                          text={
                            <a
                              style={{ color: 'var(--black-60-87)', textDecoration: 'none' }}
                              onClick={(e): void => e.stopPropagation()}
                              href={
                                row.regional_manager?.email
                                  ? `mailto:${row.team_leaders_emails.join(',')}?cc=${row.regional_manager?.email}`
                                  : `mailto:${row.team_leaders_emails.join(',')}`
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {row.regional_manager?.email ? `E-post TL + CC till RC` : `E-post TL`}
                            </a>
                          }
                          icon={<EmailIcon style={{ marginRight: '10px' }} />}
                          label="email_tl_rc"
                          styles={commonStyles.selectionFabStyle3}
                        />
                      </MenuItem>
                    )}
                  </MenuTableCell>
                ),
                customCell: true,
              },
            ],
          };
        }}
        pagination={{
          page: query.page ?? 1,
          onChange: (_, page) => setQuery({ page }),
          pageSize: 50,
          totalEntries: teamsCount || 0,
          pageTopRef,
        }}
        sorting={{ sortBy: query.sortBy, sortOrder: query.sortOrder, handleSortChange }}
      />

      {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && (
        <>
          <ExportModal
            isOpen={exportModalOpen}
            setIsOpen={setExportModalOpen}
            filterResidenceGroup={query.residenceGroup}
            filterOperationalManager={query.operationalManager}
            filterRegion={query.region}
          />
          <ExportFab onClick={() => setExportModalOpen(true)} />
        </>
      )}
      {hasPermissions(['admin', 'operational_leader']) && <CreateFab link={'/teams/create'} />}
      {(loading || loadingResidenceGroups || loadingUsers || loadingRegions) && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}
    </div>
  );
}
