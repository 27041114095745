import { Search as SearchIcon } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

type AgreementsSearchBarProps = {
  value?: string;
  onChange?: (value: string) => void;
};

export default function AgreementsSearchBar({ value, onChange }: AgreementsSearchBarProps) {
  return (
    <div className="relative w-full">
      <SearchIcon className="absolute top-3.5 bottom-3.5 left-2.5" />
      <input
        className="pl-11 py-3.5 outline-none w-full"
        placeholder="Sök avtal"
        value={value}
        onChange={({ target }) => onChange?.(target.value)}
      />
    </div>
  );
}
