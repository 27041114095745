import React from 'react';

import { FormControl, FormHelperText, Grid, InputProps, InputLabelProps, TextField } from '@mui/material';
import ConditionalParent from '../ConditionalParent';
import { getValue } from '../utilities';

type TextFieldProps<T> = {
  size?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
  name: string;
  className?: string;
  InputProps?: InputProps;
  label: string;
  placeholder: string;
  values: Record<string, any>;
  errors?: Record<string, any>;
  required?: boolean;
  disabled?: boolean;
  InputLabelProps?: InputLabelProps;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export default function FormFreeTextField<T>({
  size,
  name,
  className,
  InputProps,
  label,
  placeholder,
  values,
  errors,
  required,
  disabled,
  InputLabelProps,
  onChange,
}: TextFieldProps<T>) {
  return (
    <ConditionalParent
      on={size !== null}
      parent={(children) => (
        <Grid item xs={size!}>
          <FormControl variant="standard" margin="normal" required={required} fullWidth>
            {children}
          </FormControl>
        </Grid>
      )}
    >
      <>
        <TextField
          name={name}
          className={className}
          InputProps={InputProps}
          label={label}
          placeholder={placeholder}
          value={values ? getValue(values, name) : null}
          error={errors ? !!getValue(errors, name) : false}
          required={required}
          disabled={disabled}
          InputLabelProps={InputLabelProps}
          onChange={onChange}
        />
        {errors && getValue(errors, name) && <FormHelperText error>{getValue(errors, name)}</FormHelperText>}
      </>
    </ConditionalParent>
  );
}
