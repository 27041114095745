import * as Yup from 'yup';

import { OptionsInterface, sort_order } from '../Common/types';

export const initialValues = {
  id: 0,
  residence_clusters: [] as OptionsInterface[],
  residence_group: undefined as OptionsInterface | undefined,
  region: undefined as OptionsInterface | undefined,
  name: '',
  visiting_address: '',
  zip_code: '',
  city: '',
  gets_invoice_details_from_residence_group: true,
  gets_only_for_locals_from_residence_group: true,
  residence_password: undefined as string | undefined,
  is_active: true,
  notes: '',
  only_for_locals: false,
  teams_notes: `Utförare:
Arbetssätt:

ANDRA JOURNR:

ÖVERGRIPANDE INFORMATION
* Hänger av sig...
* Struktur för passen (uppdelning, våningar)...
* Uppföljningsrutin...
* Några risker i arbetsmiljö som bör noteras?
* Övriga rutiner på boendet som bör noteras?
------------------------------------------------------------------------

MÖTE 20XX ÅRSTID (DD/M-YY)
Närvarande: TL Förnamn Efternamn, RC Förnamn Efternamn, UOA....

* Feedback från boendet:
* Aktiviteter i fokus:
* Inköpsmöjlighet/ rutin:
* Ev närståendemöte: (Om nej, behöver ej vara med)
* Övrigt:
------------------------------------------------------------------------
MÖTE 20XX ÅRSTID (DD/M-YY)
Närvarande: TL Förnamn Efternamn, RC Förnamn Efternamn, UOA....
`,
  people_attributes: [],
  teams: [] as { name: string; standard_workday: number; standard_attendance: string; is_new?: boolean }[],
  invoice_detail: {
    recipient: '',
    address_line_1: '',
    address_line_2: '',
    zip_code: '',
    city: '',
    reference: '',
    // gln_number: '',
  },
  editable: true,
  deletable: true,
};

export const validationSchema = Yup.object().shape({
  id: Yup.number(),
  get_invoice_details_from_residence_group: Yup.boolean(),
  residence_group: Yup.object().typeError('* Välj en avtalspart').required('* Välj en avtalspart'),
  region: Yup.object().typeError('* Välj en region').required('* Välj en region'),
  name: Yup.string().required('* Namn är ett obligatoriskt fält'),
  visiting_address: Yup.string().required('* Adress är ett obligatoriskt fält'),
  zip_code: Yup.string()
    .matches(/^[1-9]\d{2}[ ]?\d{2}$/, '* Postnumret skrivs på formatet XXXXX och enbart siffror')
    .required('* Postnummer är ett obligatoriskt fält'),
  city: Yup.string().required('* Ort är ett obligatoriskt fält'),
  teams_notes: Yup.string().when(['teams', 'residence_password'], {
    is: (teams: any, residence_password: string) => teams.length > 0 && residence_password === '',
    then: Yup.string().required('* Skriv en anteckning till team'),
  }),
  residence_password: Yup.string().when('id', {
    is: (id) => id !== 0,
    then: Yup.string().required('* Får inte vara tomt').min(7, '* Måste vara minst 7 tecken'),
  }),
  invoice_detail: Yup.object({
    recipient: Yup.string().test('recipient', '* Fakturamottagare är ett obligatoriskt fält', function (value) {
      // @ts-ignore
      if (this.options.context && this.options.context['check_invoice']) return true;
      else if (value === '') return false;
      else return true;
    }),
    zip_code: Yup.string().test(
      'zip_code',
      '* Postnumret skrivs på formatet XXXXX och enbart siffror',
      function (value) {
        // @ts-ignore
        if (this.options.context && this.options.context['check_invoice']) return true;
        if (!value) return true;
        return /^(^$|[1-9]\d{2}[ ]?\d{2})$/.test(value);
      }
    ),
    org_number: Yup.string().test(
      'org_number',
      '* Orginisationsnummer skrivs på formatet XXXXXX-XXXX och enbart siffror',
      function (value) {
        // @ts-ignore
        if (this.options.context && this.options.context['check_invoice']) return true;
        if (!value) return true;
        return /^(^$|[1-9]\d{5}[-]\d{4})$/.test(value);
      }
    ),
    // gln_number: Yup.string().test('gln_number', '* GLN-numret måste vara 13 siffror', function (value) {
    //   // @ts-ignore
    //   if (this.options.context && this.options.context['check_invoice']) return true;
    //   if (!value) return true;
    //   return /^(^$|\d{13})+$/.test(value);
    // }),
  }),
  people_attributes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().when('_destroy', {
        is: (value) => value !== 1,
        then: Yup.string().required('* Obligatoriskt fält'),
      }),
    })
  ),
  teams: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required('* Namn är ett obligatoriskt fält')
        .max(28, '* Namnet får inte vara längre än 28 tecken'),
      standard_attendance: Yup.number()
        .integer('* Måste vara ett heltal')
        .min(0, '* Kan inte vara negativt')
        .typeError('* Måste vara ett heltal'),
      standard_workday: Yup.number().min(0, '* Får inte vara negativ'),
    })
  ),
});

export const filterSortDefaults = {
  query: '',
  sort_by: 'name',
  sort_order: sort_order.asc,
  is_active: true,
};
