import React from 'react';
import { BaseFilterPopover } from '../../Common/FilterPopover';
import { FormControlLabel, Switch } from '@mui/material';

type Props = {
  showInactive: boolean | undefined;
  setShowInactive: (val: boolean) => void;
};

export default function ScheduleFilterPopover({ showInactive, setShowInactive }: Props) {
  return (
    <BaseFilterPopover>
      <div className="flex items-center justify-between">
        Visa inaktiva team i menyn
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={showInactive ?? false} onChange={(_, checked) => setShowInactive(checked)} />}
        />
      </div>
    </BaseFilterPopover>
  );
}
