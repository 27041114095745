import { Help as HelpIcon, LocationOn as LocationOnIcon } from '@mui/icons-material';
import {
  AutocompleteChangeReason,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
} from '@mui/material';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import AuthContext from '../../Common/AuthContext';
import { FormHourMinutePicker, FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import InfoDialog from '../../Common/InfoDialog';
import RichTextEditorNews from '../../Common/TextEditor/RichTextEditorNews';
import { StyleSheet, css, theme } from '../../Common/styling';
import { AuthContextValue, OptionsInterface, RoleOptionsInterface } from '../../Common/types';
import { getResidencesMinimalList } from '../../Residence/residenceApi';
import { initialJobOpeningValues } from '../jobOpeningSchema';
import { JobOpeningInterface } from '../types';

const styles = StyleSheet.create({
  text1: {
    fontSize: '15.8px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: 'rgb(0, 0, 0, 0.87)',
  },
  text2: {
    marginTop: '8px',
    fontSize: '15.8px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
  },
  text3: {
    fontSize: '15.8px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    alignContent: 'center',
  },
  text4: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    alignContent: 'center',
  },
  map: {
    fontSize: '15.8px',
    lineHeight: '15px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    textAlign: 'right',
  },
});

type Props = {
  values: typeof initialJobOpeningValues;
  jobOpening?: JobOpeningInterface;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: Record<string, any>;
  submitDisabled: boolean;
  isCreate: boolean;
  roleMinimalList: RoleOptionsInterface[];
  residenceMinimalList: OptionsInterface[];
  residenceClustersMinimalList: OptionsInterface[];
  residenceGroupsMinimalList: OptionsInterface[];
  regionsMinimalList: OptionsInterface[];
  setResidenceClusters?: (residenceClusters: OptionsInterface[]) => void;
  setResidenceGroups?: (residenceGroups: OptionsInterface[]) => void;
  setRegions?: (residenceGroups: OptionsInterface[]) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  residenceClusters: OptionsInterface[];
  residenceGroups: OptionsInterface[];
  regions: OptionsInterface[];
  removal?: () => void;
};

const jobOpeningForm: React.FC<Props> = (props) => {
  const { profile } = useContext(AuthContext) as AuthContextValue;
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const openResidenceMap = (): void => {
    window.open(`${process.env.REACT_APP_BASE_URL}/karta?complete=true`, '_blank');
  };

  const [selectedResidenceClusters, setSelectedResidenceClusters] = useState<OptionsInterface[]>([]);
  const [selectedResidenceGroups, setSelectedResidenceGroups] = useState<OptionsInterface[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<OptionsInterface[]>([]);

  const buttonState = useMemo(() => {
    return props.values.roles.some((role) => role.short_name === 'RC') ? 'RC' : 'TL';
  }, [props.values.roles]);

  // functions
  useEffect(() => {
    if (!props.isCreate && props.jobOpening) {
      props.jobOpening.roles?.forEach((role: OptionsInterface) => {
        if (role.name === 'Teamledare') {
          clickRoleButton('Teamledare');
        } else if (role.name === 'Regionchef') {
          clickRoleButton('Regionchef');
        }
      });
    } else {
      clickRoleButton('Teamledare');
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (props.values.residences.length) return;

    setSelectedResidenceClusters([]);
    setSelectedResidenceGroups([]);
    setSelectedRegions([]);
  }, [props.values.residences]);

  const current_user_mobile = profile?.mobile ? profile?.mobile : '[FYLL I MOBILTELEFON]';
  const current_user_mail = profile?.email ? profile?.email : '[FYLL I MEJLADRESS]';

  const bodyTL =
    `Teamledarrollen är en rolig möjlighet att utvecklas genom att du får ta ansvar och testa på ledarskap. Utöver att planera och hålla i passen får du även lära dig om rekrytering, hålla kontakten med boendet m.m. Dessa arbetsuppgifter är självklart betalda. I din nya roll har du alltid din regionchef som nära stöd, och att ha varit teamledare är en bra merit på ditt CV.

    <br><br>Teamet har pass på [FYLL I PASSDAG OCH TID]. Sista ansökningsdag är [XX] och vi kommer att hålla intervjuer löpande. Om du har frågor om tjänsten är du varmt välkommen att kontakta mig på <b>` +
    current_user_mobile +
    `</b> eller <b>` +
    current_user_mail +
    `</b>.
  
    <br><br>Läs mer om rollen som teamledare <a href="https://drive.google.com/file/d/1Cn5OWV-aRZAk20Mb9NYhEkVK90K2EomQ/view?usp=sharing">här</a>.

    <br><br>Bästa hälsningar ${profile?.first_name + ' ' + profile?.surname}`;

  const bodyRC =
    `Regionchefstjänsten är en chans att utvecklas vidare med ett mer indirekt ledarskap där du stöttar teamledarna i deras arbete. Du besöker och stöttar dina team, ansvarar för rekrytering och får delta på utbildningsträffar med din verksamhetsansvarige och andra regionchefer. Rollen är lika rolig som den är utvecklande och en oslagbar merit på ditt CV. Du har alltid din verksamhetsansvarig som nära stöd i din nya roll.

    <br><br>Sista ansökningsdag är [XX] och vi håller intervjuer löpande. Du kan söka oavsett hur länge du varit teamledare, men vid många ansökningar kommer vi prioritera de som arbetat längst. Om du har frågor om tjänsten är du varmt välkommen att kontakta mig på <b>` +
    current_user_mobile +
    `</b> eller <b>` +
    current_user_mail +
    `</b>.

    <br><br>Läs mer om tjänsten som regionchef <a href="https://drive.google.com/file/d/1Cs2E9qNkI7T__Ib9bIwMh61wBUB6lEas/view">här</a>.`;

  // 1. Convert the HTML
  const contentHTMLTL = convertFromHTML(bodyTL);
  const contentHTMLRC = convertFromHTML(bodyRC);

  // 2. Create the ContentState object
  const stateTL = ContentState.createFromBlockArray(contentHTMLTL.contentBlocks, contentHTMLTL.entityMap);
  const stateRC = ContentState.createFromBlockArray(contentHTMLRC.contentBlocks, contentHTMLRC.entityMap);

  // 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
  const contentTL = JSON.stringify(convertToRaw(stateTL));
  const contentRC = JSON.stringify(convertToRaw(stateRC));

  const clickRoleButton = (roleName: 'Teamledare' | 'Regionchef'): void => {
    // Do nothing if button is already active
    if (roleName === 'Teamledare' && buttonState === 'TL') return;
    if (roleName === 'Regionchef' && buttonState === 'RC') return;

    setSelectedResidenceClusters([]);
    setSelectedResidenceGroups([]);
    setSelectedRegions([]);

    let residences = props.jobOpening?.residences ?? [];
    if (roleName === 'Teamledare' && residences.length > 1) residences = [residences[0]];
    props.setFieldValue('residences', residences);

    props.setFieldValue(
      'title',
      props.jobOpening?.title ?? (roleName === 'Teamledare' ? 'Ledig teamledartjänst' : '[FYLL I REGIONENS PLATS]')
    );
    props.setFieldValue(
      'roles',
      props.roleMinimalList.filter(({ short_name }) => short_name === (roleName === 'Teamledare' ? 'TL' : 'RC'))
    );
  };

  async function setMultipleResidences(
    type: 'cluster' | 'group' | 'region',
    act: AutocompleteChangeReason,
    option?: OptionsInterface
  ) {
    if (act === 'blur' || act === 'createOption') return;

    let options: OptionsInterface[] = option ? [option] : [];
    if (!option) {
      options =
        type === 'cluster' ? selectedResidenceClusters : type === 'group' ? selectedResidenceGroups : selectedRegions;
    }

    const ids = options.map(({ id }) => id);
    const residences = await getResidencesMinimalList({
      is_active: true,
      residence_cluster_id: type === 'cluster' ? ids : undefined,
      residence_group_id: type === 'group' ? ids : undefined,
      region_id: type === 'region' ? ids : undefined,
    }).then(({ data }) => data.data);
    let newResidences: OptionsInterface[];
    if (act === 'selectOption') {
      newResidences = [...props.values.residences];
      residences.forEach((residence) => {
        if (!newResidences.some(({ id }) => residence.id === id)) {
          newResidences.push(residence);
        }
      });
    } else {
      newResidences = props.values.residences.filter(({ id }) => !residences.some((residence) => id === residence.id));
    }

    props.setFieldValue('residences', newResidences);
    switch (type) {
      case 'cluster':
        return setSelectedResidenceClusters((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
      case 'group':
        return setSelectedResidenceGroups((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
      case 'region':
        return setSelectedRegions((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
    }
  }

  return (
    <div style={{ minWidth: '900px', maxWidth: '100%' }}>
      <Grid container spacing={2} alignItems="center" alignContent="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>Annons för rekrytering av:</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }}>
          <Button
            variant={buttonState === 'TL' ? 'contained' : 'outlined'}
            color={buttonState === 'TL' ? 'primary' : 'secondary'}
            style={{ marginRight: '10px' }}
            disabled={!(props.isCreate || props.values.editable)}
            onClick={() => clickRoleButton('Teamledare')}
          >
            TL
          </Button>
          <Button
            variant={buttonState === 'RC' ? 'contained' : 'outlined'}
            color={buttonState === 'RC' ? 'primary' : 'secondary'}
            style={{ marginRight: '10px' }}
            disabled={!(props.isCreate || props.values.editable)}
            onClick={() => clickRoleButton('Regionchef')}
          >
            RC
          </Button>
          <FormHelperText error>{props.errors.roles}</FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Divider />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        alignContent="space-between"
        justifyContent="flex-end"
        className="mb-4"
      >
        <Grid item xs={5} sm={5} md={5} lg={5}>
          {buttonState === 'TL' ? (
            <p className={css(styles.text2)}>För vilket boende söker du teamledare?</p>
          ) : (
            <p className={css(styles.text2)}>
              För vilka boenden ska annonsen visas?
              <IconButton size="large" style={{ marginTop: '-3px' }} onClick={(): void => setInfoDialogOpen(true)}>
                <HelpIcon />
              </IconButton>
            </p>
          )}
        </Grid>
        {buttonState === 'TL' ? (
          <Grid item xs={5} sm={5} md={5} lg={5}></Grid>
        ) : (
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <p className={css(styles.text3)}>
              {props.values.residences.length +
                ' Boende' +
                (props.values.residences.length === 1 ? ' valt' : 'n valda')}
            </p>
          </Grid>
        )}

        <Grid item xs={2} sm={2} md={2} lg={2} style={{ alignSelf: 'center', display: 'flex' }}>
          <Button
            style={{ marginTop: '2px', color: theme.greenLight }}
            color="secondary"
            variant="outlined"
            startIcon={<LocationOnIcon />}
            onClick={() => {
              openResidenceMap();
            }}
          >
            Karta
          </Button>
        </Grid>
      </Grid>
      {buttonState === 'TL' ? (
        <Grid container spacing={2} className="mb-3">
          <Grid item xs={5} sm={5} md={5} lg={5} style={{ paddingTop: '0px' }}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="single"
                  id="residence_ids"
                  name="residence_ids"
                  label="Boende för annonsen"
                  options={props.residenceMinimalList}
                  value={props.values.residences[0]}
                  onChange={(_, value) => props.setFieldValue('residences', value ? [value] : [])}
                  getOptionLabel={(opt) => opt.name}
                  optionEquality={(option, value) => option.id === value.id}
                  error={props.errors.residences}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingTop: '0px' }}>
            <FormHourMinutePicker
              fieldName="max_travel_time"
              label="Max restid, välj längre restid om få boenden ligger nära (tim:minut)"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
              hours={2}
              skip={['00:00', '00:15', '01:15', '01:45']}
              disabled={!(props.isCreate || props.values.editable)}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <>{/* Absolutly no idea why, but if this is removed everything breaks */}</>
                <FormAutocompleteBox
                  type="multiple"
                  label="Grupp"
                  options={props.residenceClustersMinimalList}
                  values={selectedResidenceClusters}
                  getOptionLabel={(opt) => opt.name}
                  optionEquality={(option, value) => option.id === value.id}
                  onChange={(_e, _v, action, detail) => setMultipleResidences('cluster', action, detail?.option)}
                  renderTags={(values) => `${values.length} vald${values.length === 1 ? '' : 'a'}`}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="multiple"
                  label="Avtalspart"
                  options={props.residenceGroupsMinimalList}
                  values={selectedResidenceGroups}
                  getOptionLabel={(opt) => opt.name}
                  optionEquality={(option, value) => option.id === value.id}
                  onChange={(_e, _v, action, detail) => setMultipleResidences('group', action, detail?.option)}
                  renderTags={(values) => `${values.length} vald${values.length === 1 ? '' : 'a'}`}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="multiple"
                  label="Region"
                  options={props.regionsMinimalList}
                  values={selectedRegions}
                  getOptionLabel={(opt) => opt.name}
                  optionEquality={(option, value) => option.id === value.id}
                  onChange={(_e, _v, action, detail) => setMultipleResidences('region', action, detail?.option)}
                  renderTags={(values) => `${values.length} vald${values.length === 1 ? '' : 'a'}`}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="multiple"
                  id="residence_ids"
                  name="residence_ids"
                  label="Boenden för annonsen"
                  options={props.residenceMinimalList}
                  values={props.values.residences}
                  onChange={(_, values) => props.setFieldValue('residences', values)}
                  renderTags={(values) => `${values.length} vald${values.length === 1 ? '' : 'a'}`}
                  getOptionLabel={(opt) => opt.name}
                  optionEquality={(option, value) => option.id === value.id}
                  error={props.errors.residences}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        {buttonState === 'RC' && (
          <Grid item xs={12} style={{ marginBottom: '20px', marginTop: '20px' }}>
            <div className={css(styles.text4)} style={{ textAlign: 'center' }}>
              {props.values.residences
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((r) => r.name)
                .join(', ')}
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} className="!pt-6 !pb-5">
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormTextField
            required={true}
            size={buttonState === 'TL' ? 5 : 6}
            fieldName="title"
            label="Annonsens titel"
            placeholder="Titel"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
            disabled={!(props.isCreate || props.values.editable)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <RichTextEditorNews
            handleChange={props.handleChange}
            initialValue={!props.isCreate ? props.values.raw_content : buttonState === 'TL' ? contentTL : contentRC}
            type={buttonState}
            setFieldValue={props.setFieldValue}
            fieldName="raw_content"
            disabled={!(props.isCreate || props.values.editable)}
            subheader="Annonsens text"
            controls={[
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'link',
              'media',
              'numberList',
              'bulletList',
              'quote',
            ]}
            label={'Skriv din annonsen här...'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }} justifyContent="center">
        <Grid item></Grid>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <p className={css(styles.text2)} style={{ textAlign: 'center' }}>
            Kom ihåg: Arkivera annonsen när du bokat alla intervjuer!
          </p>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }} justifyContent="center">
        {(props.isCreate || props.values.editable) && (
          <>
            <Grid item></Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <FormControl
                variant="standard"
                margin="dense"
                required
                style={{ margin: '0 auto', display: 'block', textAlign: 'center' }}
              >
                <Button type="submit" variant="contained" color="primary" disabled={props.submitDisabled}>
                  {props.isCreate && 'Publicera'}
                  {!props.isCreate && 'Spara'}
                </Button>
              </FormControl>
            </Grid>
            <Grid item></Grid>
          </>
        )}
        {!props.isCreate && props.values.deletable && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button
                variant="outlined"
                onClick={(): void => {
                  if (props.removal) props.removal();
                }}
              >
                Arkivera
              </Button>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <InfoDialog
        open={infoDialogOpen}
        title="Logik för att snabbt välja bostäder"
        body={
          <div>
            <p>
              Välj <i>boendegrupp</i>, <i>avtalspart</i> och <i>region</i> för att snabbt välja en grupp av flera
              boenden som sedan markeras i <i>Välj boende</i>. Endast boenden markerade under <i>Välj boende</i> kommer
              få tillgång till annonsen.
            </p>
            <p>Du kan även välja individuella boenden under Välj boende.</p>
          </div>
        }
        closeDialog={(): void => setInfoDialogOpen(false)}
      />
    </div>
  );
};

export default jobOpeningForm;
