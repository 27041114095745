import { AxiosResponse } from 'axios';

import { ResidenceGroupInterface } from './types';
import { ListParams } from '../Common/types';
import API from '../Common/axios';

export const createResidenceGroup = (residence_group: ResidenceGroupInterface): Promise<AxiosResponse> => {
  return API.post('v1/residence_groups', { residence_group });
};

export const getResidenceGroup = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/residence_groups/${id}`);
};

export const updateResidenceGroup = (id: string, residence_group: ResidenceGroupInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/residence_groups/${id}`, { residence_group });
};

export const getResidenceGroupList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/residence_groups', { params });
};

export const getResidenceGroupsMinimalList = (params?: { is_active?: boolean }): Promise<AxiosResponse> => {
  return API.get('v1/residence_groups/minimal_list', { params });
};

export const deleteResidenceGroup = (id: number): Promise<AxiosResponse> => {
  return API.delete(`v1/residence_groups/${id}`);
};

export const synchWithFortnox = (id: string): Promise<AxiosResponse> => {
  return API.post(`v1/residence_groups/${id}/synch_with_fortnox`);
};
