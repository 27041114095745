import { AxiosResponse } from 'axios';

import { ListParams } from '../Common/types';
import API from '../Common/axios';

export const getJobApplicationList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/job_applications', { params });
};

export const createJobApplication = (
  job_application: {
    first_name: string;
    surname: string;
    person_nr: string;
    email: string;
    mobile: string;
    school?: string;
    graduate_year?: number;
    address: string;
    zip_code: string;
    city: string;
    status?: string;
    type: 'ParticipantJobApplication' | 'TeamLeaderJobApplication';
    birth_date?: string;
    team_id?: number;
    language_ids?: number[];
    manually_tagged_residence_ids?: number[];
  },
  external?: boolean
): Promise<AxiosResponse> => {
  return API.post('v1/job_applications', { job_application, external });
};

export const updateJobApplication = (
  id: number | undefined,
  job_application: {
    first_name: string;
    surname: string;
    person_nr?: string;
    email?: string;
    mobile?: string;
    school?: string;
    graduate_year?: number | null;
    address?: string;
    zip_code?: string;
    city?: string;
    status?: string;
    type: 'ParticipantJobApplication' | 'TeamLeaderJobApplication';
    birth_date?: string;
    team_id?: number;
    language_ids?: number[];
    manually_tagged_residence_ids?: number[];
  }
): Promise<AxiosResponse> => {
  return API.patch(`v1/job_applications/${id}`, { job_application });
};

export const batchUpdateJobApplicationStatus = (
  ids: (number | undefined)[],
  status: string
): Promise<AxiosResponse> => {
  return API.patch(`v1/job_applications/batch_update_status`, { job_application_ids: ids, status: status });
};

export const employ = (
  id: number,
  type: 'participant' | 'team_leader',
  job_application: { team_id: number; email: string; first_name: string; surname: string }
): Promise<AxiosResponse> => {
  return API.post(`/v1/job_applications/${id}/employ?type=${type}`, { job_application });
};

export const getJobApplication = (id: number): Promise<AxiosResponse> => {
  return API.get(`v1/job_applications/${id}`);
};

export const deleteJobApplication = (id: number | undefined): Promise<AxiosResponse> => {
  return API.delete(`v1/job_applications/${id}`);
};

export const getLanguagesMinimalList = (): Promise<AxiosResponse> => {
  return API.get('v1/languages');
};

export const getJobApplicationByUpdateToken = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/job_applications/update_token/${id}`);
};

export const updateJobApplicationByUpdateToken = (
  id: string | undefined,
  job_application: {
    mobile: string;
  }
): Promise<AxiosResponse> => {
  return API.patch(`v1/job_applications/update_token/${id}`, { job_application });
};
