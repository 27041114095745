import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';

import { Checkbox, Dialog } from '@mui/material';

import { AuthContextValue } from '../Common/types';
import BatchUpdateAgreementModal from './components/BatchUpdateAgreementModal';
import BatchRemoveAgreementModal from './components/BatchRemoveAgreementModal';

import { CreateFab, UpdateFab, ExportFab, RemoveFab } from '../Common/ButtonLinks';
import { isNotEmpty, stringToPositiveInt, useDebounce, useQuery } from '../Common/utilities';
import AgreementsFilterPopover from './components/FilterPopover';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import ExportModal from './components/ExportModal';
import AuthContext from '../Common/AuthContext';
import { FindAgreementsParams, getAgreementList } from './agreementApi';
import { AgreementInterface } from './types';
import dayjs, { Dayjs } from 'dayjs';
import TableBox, { TableBoxColumn } from '../Common/Table/TableBox';
import AgreementsSearchBar from './components/SearchBar';

const tableColumns: TableBoxColumn[] = [
  { id: 'choose', label: 'Välj', width: '3xs', sortable: false },
  { id: 'residence_name', label: 'Boende', width: 'md', lineStyle: 'compress' },
  { id: 'residence_group_name', label: 'Avtalspart', width: 'md', lineStyle: 'compress' },
  { id: 'type', label: 'Typ', width: '3xs' },
  { id: 'budgeted_hours', label: 'Timpott (tim)', width: 'xs' },
  { id: 'reported_hours', label: 'Inrapporterat', width: 'xs' },
  { id: 'closest_hourly_rate', label: 'Timpris (kr/tim)', width: 'xs', sortable: false },
  { id: 'start_date', label: 'Startdatum', width: 'xs' },
  { id: 'end_date', label: 'Slutdatum', width: 'xs' },
];

const PAGE_SIZE = 25;

const Agreements: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const [selectedAgreements, setSelectedAgreements] = useState([] as AgreementInterface[]);
  const [agreementsList, setAgreementsList] = useState<Array<AgreementInterface>>();
  const [agreementsCount, setAgreementsCount] = useState<number>();
  const [editModalState, setEditModalState] = useState(false);
  const [removeModalState, setRemoveModalState] = useState(false);
  const [exportModalState, setExportModalState] = useState(false);
  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;
  const [query, setQuery] = useQuery(
    {
      search: (val) => val,
      startDate: (val) => (dayjs(val, 'YYYY-MM-DD').isValid() ? dayjs(val, 'YYYY-MM-DD') : undefined),
      endDate: (val) => (dayjs(val, 'YYYY-MM-DD').isValid() ? dayjs(val, 'YYYY-MM-DD') : undefined),
      showInactive: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      sortBy: (val) => val,
      sortOrder: (val) => (val === 'asc' ? ('asc' as const) : val === 'desc' ? ('desc' as const) : undefined),
      page: (val) => stringToPositiveInt(val),
    },
    { backConverter: { startDate: (val) => val?.format('YYYY-MM-DD'), endDate: (val) => val?.format('YYYY-MM-DD') } }
  );
  const pageTopRef = useRef<HTMLDivElement>(null);
  const { loading, debounce: fetchAgreementsList } = useDebounce(
    (query: {
      search?: string;
      startDate?: Dayjs;
      endDate?: Dayjs;
      showInactive?: boolean;
      sortBy?: string;
      sortOrder?: 'asc' | 'desc';
      page?: number;
    }) =>
      getAgreementList({
        query: query.search,
        start_date: query.startDate?.format('YYYY-MM-DD'),
        end_date: query.endDate?.format('YYYY-MM-DD'),
        is_active: !query.showInactive,
        sort_by: query.sortBy ?? 'residence_name',
        sort_order: query.sortOrder ?? 'asc',
        page: query.page ?? 1,
        processing: false,
      }),
    ({ data }) => {
      setAgreementsList(data.data);
      setAgreementsCount(data.meta.count);
      pageTopRef.current?.scrollIntoView();
    },
    { runInitially: false }
  );

  useEffect(() => {
    fetchAgreementsList(query);
  }, [query.search, query.startDate, query.endDate, query.showInactive, query.sortBy, query.sortOrder, query.page]); // eslint-disable-line

  const updateSorting = (col: string) => {
    return setQuery({ sortBy: col, sortOrder: query.sortBy !== col || query.sortOrder === 'desc' ? 'asc' : 'desc' });
  };

  const selectRow = (event: any, row: AgreementInterface): void => {
    // Avoid to navigate to agreement detail view
    event.stopPropagation();

    // Push or pop element from list
    const newSelectedList = event.target.checked
      ? [...selectedAgreements, row]
      : selectedAgreements.filter((a: any) => a.id !== row.id);

    setSelectedAgreements(newSelectedList);
  };

  const SelectionFabs: React.FC = () => {
    return (
      <div className={css(commonStyles.selectionFabContainer)}>
        <UpdateFab onClick={(): void => setEditModalState(true)} text="ÄNDRA AVTAL" />
        <RemoveFab onClick={(): void => setRemoveModalState(true)} text="TA BORT AVTAL" />
      </div>
    );
  };

  const agreementType = (type: string): string => {
    if (type === 'ContinuousAgreement') {
      // Löpande
      return 'L';
    } else {
      if (type === 'FixedAgreement') {
        // Anpassad
        return 'A';
      } else {
        return '';
      }
    }
  };

  return (
    <div ref={pageTopRef} className="min-h-full">
      {/* Title */}
      <div className="h-16 p-4 flex items-center justify-between bg-gray-100">
        <h1 className="text-2xl font-semibold">Avtal</h1>
        <p className="text-center text-sm text-black/60">{agreementsCount} st avtal</p>
      </div>

      {/* Filters */}
      <div className="bg-white flex h-13">
        <AgreementsSearchBar value={query.search} onChange={(value) => setQuery({ search: value || undefined })} />
        <div className="flex">
          <AgreementsFilterPopover
            startDate={query.startDate}
            setStartDate={(date) => setQuery({ startDate: date?.format('YYYY-MM-DD') ?? undefined, page: undefined })}
            endDate={query.endDate}
            setEndDate={(date) => setQuery({ endDate: date?.format('YYYY-MM-DD') ?? undefined, page: undefined })}
            showInactive={query.showInactive}
            setShowInactive={(val) => setQuery({ showInactive: val, page: undefined })}
          />
        </div>
      </div>

      {/* Content */}
      <TableBox
        columns={tableColumns}
        content={agreementsList}
        selectedRows={selectedAgreements}
        rowDefinition={(agreement) => ({
          key: agreement.id,
          defaultLink: `/agreements/${agreement.id}`,
          cols: [
            {
              padding: 'none',
              content: (
                <Checkbox
                  onClick={(e): void => selectRow(e, agreement)}
                  checked={selectedAgreements.some((element) => element.id === agreement.id)}
                />
              ),
            },
            agreement.residence_name,
            agreement.residence_group_name,
            agreementType(agreement.type),
            agreement.budgeted_hours,
            agreement.reported_hours,
            agreement.closest_hourly_rate,
            dayjs(agreement.start_date).format('YYYY-MM-DD'),
            dayjs(agreement.end_date).format('YYYY-MM-DD'),
          ],
        })}
        pagination={{
          totalEntries: agreementsCount ?? 0,
          pageSize: PAGE_SIZE,
          page: query.page ?? 1,
          onChange: (_, page) => setQuery({ page }),
        }}
        sorting={{ sortBy: query.sortBy, sortOrder: query.sortOrder, handleSortChange: updateSorting }}
        fullWidth
      />

      {editModalState && (
        <Dialog open={editModalState} onClose={(): void => setEditModalState(false)} fullWidth={true} maxWidth="md">
          <BatchUpdateAgreementModal
            agreements={selectedAgreements}
            setModalState={setEditModalState}
            fetchAgreementsList={fetchAgreementsList}
            query={query}
          />
        </Dialog>
      )}
      {removeModalState && (
        <Dialog open={removeModalState} onClose={(): void => setRemoveModalState(false)} fullWidth={true} maxWidth="md">
          <BatchRemoveAgreementModal
            agreements={selectedAgreements}
            setModalState={setRemoveModalState}
            fetchAgreementsList={fetchAgreementsList}
            query={query}
          />
        </Dialog>
      )}
      {hasPermissions(['admin']) && exportModalState && (
        <Dialog open={exportModalState} onClose={(): void => setExportModalState(false)}>
          <ExportModal setModalState={setExportModalState} />
        </Dialog>
      )}
      {isNotEmpty(selectedAgreements) && <SelectionFabs />}

      <ExportFab onClick={(): void => setExportModalState(true)} />
      <CreateFab link={'/agreements/create'} />
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}
    </div>
  );
};

export default Agreements;
