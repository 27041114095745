import React, { useState, useEffect, useRef } from 'react';
import { encode } from 'base-64';
import { useLocation, useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import { Dialog, Table, TableBody, TableCell, Grid, MenuItem } from '@mui/material';
import { TableHead, TableRow, Pagination, MenuTableCell } from '../Common/Table';
import { paginationDefaults, filterSortDefaults } from './regionSchema';
import { ListParams, ListPagination, OptionsInterface } from '../Common/types';
import { isEmpty, useDebounce } from '../Common/utilities';
import { StyleSheet, commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import EmptyList from '../Common/Table/EmptyList';
import { SearchBar } from '../Common/SearchBar';
import { RegionInterface } from './types';
import { deleteRegion, getRegions } from './regionApi';
import { CreateFab, QuickAction } from '../Common/ButtonLinks';
import paramsFromUrl from '../Common/Hooks/paramsFromUrl';
import useGlobalPopstateListener from '../Common/Hooks/useGlobalPopstateListener';
import FilterAutocomplete from '../Common/FilterPopover/FilterAutocomplete';
import { getUsersMinimalList } from '../User/usersApi';
import InventoryIcon from '@mui/icons-material/Inventory';
import ConfirmationDialog from '../Common/Dialogs/ConfirmationDialog';

const styles = StyleSheet.create({
  tableCell: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingRight: '2px',
    paddingLeft: '10px',
  },
  paddingCheckbox: {
    padding: '2px',
  },
});

const Regions: React.FC = () => {
  // hooks
  const pageTopRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyInfo, notifyError } = useNotify();
  const [regions, setRegions] = useState<Array<RegionInterface>>([]);
  const [users, setUsers] = useState<Array<OptionsInterface>>([]);
  const [regionsCount, setRegionsCount] = useState<number>(0);
  const [quickActionRegion, setQuickActionRegion] = useState<RegionInterface | Record<string, never>>({});
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);

  // get cached filters and pagination from url if any
  // otherwise load default values
  const query = paramsFromUrl('query');
  const page = paramsFromUrl('page');
  const [pagination, setPagination] = useState(
    page === '' ? (paginationDefaults as ListPagination) : (page as ListPagination)
  );
  const [listParams, setGetListParams] = useState(
    query === '' ? (filterSortDefaults as ListParams) : (query as ListParams)
  );
  const { loading: loadingRegions, debounce: fetchRegions } = useDebounce(
    (params: ListParams) => getRegions({ ...params, ...pagination }),
    ({ data }) => {
      setRegions(data.data);
      setRegionsCount(data.meta.count);
    },
    { runInitially: false }
  );

  function handleHistoryChange(event: PopStateEvent) {
    // Handle popstate event
    setPagination(paramsFromUrl('page'));
  }
  useGlobalPopstateListener(handleHistoryChange);

  const rows = [
    { id: 'name', label: 'Region' },
    { id: 'regional_manager_name', label: 'Regionchef' },
    { id: 'operational_manager_name', label: 'Verksamhetsansvarig' },
    { id: 'active_teams_count', label: 'Antal team' },
    { id: 'quick_action', label: '', notSortable: true },
  ];

  useEffect(() => {
    setPagination(pagination);
    fetchUsers();
    pageTopRef?.current?.scrollIntoView();
  }, []); // eslint-disable-line

  useEffect(() => {
    const query = encode(JSON.stringify(listParams));
    const page = encode(JSON.stringify(pagination));
    navigate(`/regions?query=${query}&page=${page}${location.hash}`, {
      replace: true,
    });

    fetchRegions({ ...listParams, ...pagination });
  }, [listParams, pagination]); // eslint-disable-line

  const fetchUsers = (): void => {
    setLoadingUsers(true);
    getUsersMinimalList({ hide_banned: true, filter_by_role_name: 'operational_manager' })
      .then(({ data }) => {
        setUsers(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över VA användare'))
      .finally(() => setLoadingUsers(false));
  };

  const archiveRegion = (id: number): void => {
    setLoading(true);
    deleteRegion(id)
      .then(() => {
        notifyInfo('Nu kommer regionen att arkiveras. Du får ett mejl så snart det är klart.');
        fetchRegions({ ...listParams, ...pagination });
      })
      .catch((error: any) => notifyError(`Det gick inte att arkivera regionen: ${error}`))
      .finally(() => {
        setQuickActionRegion({});
        setLoading(false);
      });
  };

  const confirmDialogMessage = (region: RegionInterface | Record<string, never>): string => {
    if (region.active_teams_count !== undefined && region.name) {
      if (region.active_teams_count > 0) {
        return `Regionen ${region.name} har aktiva teams. Alla boenden, teams och användare som är kopplat till regionen kommer att inaktiveras. Är du säker på att du vill arkivera regionen ${region.name}?`;
      } else {
        return `Vill du arkivera regionen ${region.name} som du valt?`;
      }
    } else {
      return '';
    }
  };

  // render
  return (
    <React.Fragment>
      <div ref={pageTopRef} className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Regioner</h1>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <p style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
              {regionsCount} Regioner
            </p>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <SearchBar
              setGetListParams={setGetListParams}
              listParams={listParams}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={2} sm={2} md={2} lg={2}>
            {!loadingUsers && (
              <FilterAutocomplete
                getOptionLabel={(user) => user.name}
                listParams={listParams}
                setGetListParams={setGetListParams}
                fieldName="filter_operational_manager"
                label="Välj VA"
                values={users.find((u: OptionsInterface) => listParams.filter_operational_manager == u.id)}
                options={loading ? [] : users}
                limitTags={1}
                setPagination={setPagination}
                paginationDefaults={paginationDefaults}
              />
            )}
          </Grid>
        </Grid>
        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} listParams={listParams} setGetListParams={setGetListParams} />

          <TableBody>
            {isEmpty(regions) ? (
              <EmptyList />
            ) : (
              regions.map((row: RegionInterface, idx: number) => (
                <TableRow
                  row={row}
                  idx={idx}
                  id={row.id?.toString()}
                  key={row.id}
                  onClick={(): void => {
                    if (!loading) {
                      const query = encode(JSON.stringify(listParams));
                      const page = encode(JSON.stringify(pagination));
                      navigate(`/regions?query=${query}&page=${page}#${row.id}`, { replace: true });
                      navigate(`/regions/${row.id}`);
                    }
                  }}
                >
                  <TableCell scope="row" width="200">
                    <b>{row.name}</b>
                  </TableCell>
                  <TableCell align="left">{row.regional_manager ? row.regional_manager.name : ''}</TableCell>
                  <TableCell align="left">{row.operational_manager ? row.operational_manager.name : ''}</TableCell>
                  <TableCell align="left">{row.active_teams_count}</TableCell>
                  <MenuTableCell>
                    <MenuItem>
                      <QuickAction
                        onClick={(): void => {
                          setQuickActionRegion(row);
                          setConfirmationDialogVisible(true);
                        }}
                        text="Arkivera"
                        icon={<InventoryIcon style={{ marginRight: '10px' }} />}
                        label="archive_region"
                        styles={commonStyles.selectionFabStyle3}
                        disabled={!row.deletable || loading}
                      />
                    </MenuItem>
                  </MenuTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <ConfirmationDialog
          isVisible={confirmationDialogVisible}
          onClose={(): void => {
            setConfirmationDialogVisible(false);
            setQuickActionRegion({});
          }}
          onConfirm={(): void => {
            if (quickActionRegion.id) {
              setConfirmationDialogVisible(false);
              archiveRegion(quickActionRegion.id);
            }
          }}
          title="Arkivera region"
          message={confirmDialogMessage(quickActionRegion)}
        />

        <Pagination
          pageTopRef={pageTopRef}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={regionsCount}
        />

        {loading || loadingRegions || loadingUsers ? (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading || loadingRegions || loadingUsers} />
          </div>
        ) : (
          <CreateFab link={`/regions/create`} />
        )}
      </div>
    </React.Fragment>
  );
};

export default Regions;
