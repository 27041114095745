import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';

import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../Common/styling';
import { OptionsInterface, RoleOptionsInterface } from '../Common/types';

import NewsForm from './components/NewsForm';
import { NewsInterface } from './types';
import { validationSchema, initialNewsValues } from './newsSchema';

import { getRolesMinimalList } from '../Common/commonApi';
import { getNews, updateNews } from './newsApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import { getResidenceClustersMinimalList } from '../ResidenceCluster/residenceClusterApi';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';
import { getRegionsMinimalList } from '../Region/regionApi';

const EditNews: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { notifyError, notifySuccess } = useNotify();
  const [news, setNews] = useState<NewsInterface>(initialNewsValues);
  const [loading, setLoading] = useState(true);
  const [loadingResidenceMinimalList, setLoadingResidenceMinimalList] = useState(true);
  const [loadingRolesMinimalList, setLoadingRolesMinimalList] = useState(true);
  const [residenceClustersMinimalList, setResidenceClustersMinimalList] = useState([]);
  const [residenceGroupsMinimalList, setResidenceGroupsMinimalList] = useState([]);
  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);
  const [regionsMinimalList, setRegionsMinimalList] = useState([]);
  const [roleMinimalList, setRoleMinimalList] = useState<RoleOptionsInterface[]>([]);

  // functions
  useEffect(() => {
    setLoading(true);
    setLoadingResidenceMinimalList(true);
    setLoadingRolesMinimalList(true);
    getResidenceClustersMinimalList()
      .then(({ data }) => setResidenceClustersMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'));

    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroupsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalspart'));

    getRegionsMinimalList({ is_active: true })
      .then(({ data }) => setRegionsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner'));

    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => setResidenceMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'))
      .finally(() => setLoadingResidenceMinimalList(false));

    getRolesMinimalList({ hide_banned: true })
      .then(({ data }) => {
        setRoleMinimalList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över roller'))
      .finally(() => setLoadingRolesMinimalList(false));

    getNews(id)
      .then(({ data }) => {
        setNews(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta nyhet'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line

  const handleFormSubmit = (values: NewsInterface, actions: FormikHelpers<any>): void => {
    if (values.roles?.length == 0) {
      notifyError('Du måste välja minst en roll.');
    } else {
      handleUpdateNews(values, actions.setSubmitting);
    }
  };

  const handleUpdateNews = (
    { roles, residences, ...values }: NewsInterface,
    setSubmitting: (isSubmitting: boolean) => void
  ): void => {
    updateNews(news.id, {
      ...values,
      role_ids: roles!.map((r) => r.id as number),
      residence_ids: residences!.map((r) => r.id as number),
    })
      .then(() => notifySuccess('Nyhet är uppdaterad'))
      .then(() => navigate(`/news`))
      .catch((error: any) => {
        notifyError(`Det gick inte att uppdatera nyhet: \n${HandleError(error.response.data)}`);
        setSubmitting(false);
      });
  };

  // render
  return (
    <React.Fragment>
      {loading ||
        loadingResidenceMinimalList ||
        (loadingRolesMinimalList && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        ))}{' '}
      {!loading && !loadingResidenceMinimalList && !loadingRolesMinimalList && (
        <div>
          <div className={css(commonStyles.createViewHeader)}>
            <h1 className={css(commonStyles.headerTextStyle)}>Redigera nyhet</h1>
            <CancelLink link={`/news`} />
          </div>

          <div className={css(commonStyles.formContainer)}>
            <Formik
              enableReinitialize
              initialValues={news}
              validationSchema={validationSchema}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, isValid }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <NewsForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    roleMinimalList={roleMinimalList}
                    residenceMinimalList={residenceMinimalList}
                    residenceClustersMinimalList={residenceClustersMinimalList}
                    residenceGroupsMinimalList={residenceGroupsMinimalList}
                    regionsMinimalList={regionsMinimalList}
                    isCreate={false}
                    setFieldValue={setFieldValue}
                    submitDisabled={isSubmitting || !isValid}
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditNews;
