import React, { useEffect, useState } from 'react';

import { AutocompleteChangeReason, Button, Divider, FormControl, Grid } from '@mui/material';
import { FormDateField, FormSelect, FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import { commonStyles, css } from '../../Common/styling';
import { OptionsInterface } from '../../Common/types';
import { initialValues } from '../jobApplicationSchema';
import { JobApplicationInterface } from '../types';
import { getResidencesMinimalList } from '../../Residence/residenceApi';

type Props = {
  values: typeof initialValues;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: Record<string, any>;
  submitDisabled: boolean;
  residenceMinimalList: OptionsInterface[];
  residenceGroupsMinimalList: OptionsInterface[];
  languageMinimalList: OptionsInterface[];
  jobApplication?: JobApplicationInterface;
  type: string;
  isCreate: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  removal?: () => void;
};

const JobApplicationForm: React.FC<Props> = (props) => {
  const [selectedResidenceGroups, setSelectedResidenceGroups] = useState<OptionsInterface[]>([]);

  useEffect(() => {
    if (props.values.manually_tagged_residences?.length === props.residenceMinimalList.length) {
      setSelectedResidenceGroups(props.residenceGroupsMinimalList);
    } else if (!props.values.manually_tagged_residences?.length) {
      setSelectedResidenceGroups([]);
    }
  }, [props.values.manually_tagged_residences]);

  // functions
  async function setMultipleResidences(type: 'group', act: AutocompleteChangeReason, option?: OptionsInterface) {
    if (act === 'blur' || act === 'createOption') return;

    let options: OptionsInterface[] = option ? [option] : [];
    if (!option) {
      options = selectedResidenceGroups;
    }

    const ids = options.map(({ id }) => id);
    const residences = await getResidencesMinimalList({
      is_active: true,
      residence_group_id: type === 'group' ? ids : undefined,
    }).then(({ data }) => data.data);
    let newResidences: OptionsInterface[];
    if (act === 'selectOption') {
      newResidences = [...(props.values.manually_tagged_residences ?? [])];
      residences.forEach((residence) => {
        if (!newResidences.some(({ id }) => residence.id === id)) {
          newResidences.push(residence);
        }
      });
    } else {
      newResidences =
        props.values.manually_tagged_residences?.filter(
          ({ id }) => !residences.some((residence) => id === residence.id)
        ) ?? [];
    }

    props.setFieldValue('manually_tagged_residences', newResidences);
    switch (type) {
      case 'group':
        return setSelectedResidenceGroups((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
    }
  }

  return (
    <div className={css(commonStyles.internalFormContentContainer)}>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          required={true}
          fieldName="first_name"
          label={'Förnamn'}
          placeholder="Förnamn"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={6}
          required={true}
          fieldName="surname"
          label={'Efternamn'}
          placeholder="Efternamn"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          fieldName="mobile"
          label={'Telefonnummer'}
          placeholder="Mobiltelefon"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={6}
          fieldName="address"
          label={'Adress'}
          placeholder="Gatuadress"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          fieldName="city"
          label={'Ort'}
          placeholder="Ort"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={props.values.automatic_zip_code ? 4 : 6}
          fieldName="zip_code"
          label={'Postnummer'}
          placeholder="Postnummer"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        {props.values.automatic_zip_code && (
          <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'left', marginTop: '2px' }}>
            <p style={{ fontSize: '14px', color: 'rgb(0, 0, 0, 0.6)' }}>
              Postnummer kompletterades automatiskt av systemet
            </p>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormDateField
          size={6}
          fieldName="birth_date"
          label={'Födelsedatum (ÅÅÅÅ-MM-DD)'}
          placeholder="Födelsedatum - ÅÅÅÅMMDD"
          values={props.values}
          errors={props.errors}
          onChange={props.setFieldValue}
        />
        <FormTextField
          size={6}
          fieldName="email"
          label={'E-postadress'}
          placeholder="E-post"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>

      <Grid container spacing={2} justifyContent="flex-start" style={{ marginBottom: '20px' }}>
        {!props.isCreate && (
          <Grid item sm={6} lg={6} xs={6}>
            <FormTextField
              size={12}
              fieldName="person_nr"
              label={'Personnummer (ÅÅMMDDXXXX)'}
              placeholder="Personnummer (ÅÅMMDDXXXX)"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
          </Grid>
        )}
        {props.type === 'team_leader' && (
          <Grid item sm={6} lg={6} xs={6}>
            <FormTextField
              size={12}
              fieldName="graduate_year"
              label="Gymnasieexamensår"
              placeholder="Gymnasieexamensår"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: '20px', display: 'none' }}>
        <FormTextField
          size={2}
          fieldName="type"
          label="type"
          placeholder="Type"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          type="hidden"
        />
        <FormTextField
          size={2}
          fieldName="status"
          label="Status"
          placeholder="Status"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          type="hidden"
        />
      </Grid>

      <Divider className="!my-5" />

      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item sm={6} lg={6} xs={6} style={{ textAlign: 'left', marginBottom: '12px' }}>
          <Grid item xs={12}>
            <FormControl variant="standard" margin="normal" required fullWidth>
              <FormAutocompleteBox
                type="multiple"
                id="language_ids"
                name="language_ids"
                label="Språk"
                options={props.languageMinimalList}
                getOptionLabel={(opt) => opt.name}
                values={props.values.languages as OptionsInterface[]}
                onChange={(_, values) => props.setFieldValue('languages', values)}
                error={props.errors.languages}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} className="!text-left !mt-0.5 !flex !items-center">
          <p className="text-sm text-black/60 mt-4 mb-5">Språk som kandidaten kan konversera på</p>
        </Grid>
      </Grid>

      <Divider className="!my-5" />

      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item container sm={8} lg={8} xs={8} style={{ textAlign: 'left', marginBottom: '5px' }}>
          <Grid item xs={5}>
            <FormControl variant="standard" margin="normal" required fullWidth>
              <FormAutocompleteBox
                type="multiple"
                id="residence_group_ids"
                label="Avtalspart"
                options={props.residenceGroupsMinimalList}
                values={selectedResidenceGroups}
                onChange={(_e, _v, reason, details) => setMultipleResidences('group', reason, details?.option)}
                renderTags="simple"
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormControl variant="standard" margin="normal" required fullWidth>
              <FormAutocompleteBox
                type="multiple"
                id="manually_tagged_residence_ids"
                name="manually_tagged_residence_ids"
                label="Manuellt kopplade boenden"
                options={props.residenceMinimalList}
                values={props.values.manually_tagged_residences}
                onChange={(_, values) => props.setFieldValue('manually_tagged_residences', values)}
                renderTags="simple"
                error={props.errors.manually_tagged_residences}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} className="!text-left !mt-0.5 !flex !items-center">
          <p className="text-sm text-black/60 mt-4 mb-5">Manuellt kopplade boenden räknas som 0-30min restid.</p>
        </Grid>
        {!props.isCreate && (
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '20px' }}>
            <b className={css(commonStyles.text4)} style={{ textAlign: 'left', color: 'rgb(0, 0, 0, 0.6)' }}>
              Boenden med automatiskt uträknad restid
            </b>
            <div
              className={css(commonStyles.text4)}
              style={{ marginTop: '7px', textAlign: 'left', color: 'rgb(0, 0, 0, 0.6)' }}
            >
              {props.jobApplication?.residences_with_travel_time?.length ? (
                props.jobApplication.residences_with_travel_time
                  .sort((a: { travel_time_min: number }, b: { travel_time_min: number }) =>
                    a.travel_time_min > b.travel_time_min ? 1 : -1
                  )
                  .map((r: { travel_time_min: number; name: string }) => r.travel_time_min.toString() + 'min ' + r.name)
                  .join(', ')
              ) : (
                <p>Det finns inga boenden med automatiskt uträknad restid inom 90min</p>
              )}
            </div>
          </Grid>
        )}
      </Grid>
      <Divider className="!my-5" />

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={5} />
        {(props.isCreate || props.values.editable) && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button type="submit" variant="contained" disabled={props.submitDisabled}>
                {props.isCreate && 'Lägg till'}
                {!props.isCreate && 'Spara'}
              </Button>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={3} />
        {!props.isCreate && props.values.deletable && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button
                variant="outlined"
                color="secondary"
                onClick={(): void => {
                  if (props.removal) props.removal();
                }}
              >
                Arkivera
              </Button>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default JobApplicationForm;
