import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';

import { createJobApplication, getLanguagesMinimalList } from './jobApplicationApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import { validationSchema, initialValues } from './jobApplicationSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import JobApplicationForm from './components/JobApplicationForm';
import { LanguageInterface } from './types';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { OptionsInterface } from '../Common/types';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';

type Props = {
  type: string;
};

const CreateJobApplication: React.FC<Props> = (props) => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);
  const [residenceGroupsMinimalList, setResidenceGroupsMinimalList] = useState([]);
  const [languageMinimalList, setLanguageMinimalList] = useState<Array<LanguageInterface>>([]);

  useEffect(() => {
    fetchLanguagesMinimalList();
    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroupsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalspart'));
    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => setResidenceMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'));
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = (
    { graduate_year, birth_date, languages, team, manually_tagged_residences, ...values }: typeof initialValues,
    actions: FormikHelpers<any>
  ): void => {
    createJobApplication({
      ...values,
      type: props.type === 'participant' ? 'ParticipantJobApplication' : 'TeamLeaderJobApplication',
      birth_date: birth_date ?? undefined,
      graduate_year: graduate_year ? Number(graduate_year.trim()) : undefined,
      language_ids: languages.map((language) => language.id as number),
      team_id: team?.id as number | undefined,
      manually_tagged_residence_ids: manually_tagged_residences.map((residence) => residence.id as number),
    })
      .then(() => notifySuccess('Ansökan har skapats'))
      .then(() => navigate(`/job_applications/${props.type}`))
      .catch((error: any) => {
        notifyError(`Det gick inte att skapa ansökan: \n${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      });
  };

  const fetchLanguagesMinimalList = (): void => {
    getLanguagesMinimalList()
      .then(({ data }) => {
        setLanguageMinimalList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över språk'));
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>
          {props.type === 'participant' ? 'Lägg till deltagaransökan' : 'Lägg till teamledareansökan'}
        </h1>
        <CancelLink link={`/job_applications/${props.type}`} />
      </div>

      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
          }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <JobApplicationForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                residenceMinimalList={residenceMinimalList}
                residenceGroupsMinimalList={residenceGroupsMinimalList}
                languageMinimalList={languageMinimalList}
                type={props.type}
                isCreate={true}
                setFieldValue={setFieldValue}
                submitDisabled={isSubmitting || !isValid || !dirty}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CreateJobApplication;
