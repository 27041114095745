import React, { useState, useEffect, ReactElement } from 'react';
import { Formik, FormikHelpers, yupToFormErrors } from 'formik';
import { RotateLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

import { createResidence } from './residenceApi';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { validationSchema, initialValues } from './residenceSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import ResidenceForm from './components/ResidenceForm';
import { CancelLink } from '../Common/ButtonLinks';

import { getResidenceClustersMinimalList } from '../ResidenceCluster/residenceClusterApi';
import { getRegionsMinimalList } from '../Region/regionApi';

const CreateResidence: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const [residenceGroupsMinimalList, setResidenceGroupsMinimalList] = useState([]);
  const [residenceClustersMinimalList, setResidenceClustersMinimalList] = useState([]);
  const [regionsMinimalList, setRegionsMinimalList] = useState([]);
  const [loadingResidenceGroupsMinimalList, setLoadingResidenceGroupsMinimalList] = useState(true);
  const [loadingResidenceClustersMinimalList, setLoadingResidenceClustersMinimalList] = useState(true);
  const [loadingRegionsMinimalList, setLoadingRegionsMinimalList] = useState(true);

  useEffect(() => {
    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroupsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalsparten'))
      .finally(() => setLoadingResidenceGroupsMinimalList(false));

    getResidenceClustersMinimalList()
      .then(({ data }) => setResidenceClustersMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'))
      .finally(() => setLoadingResidenceClustersMinimalList(false));

    getRegionsMinimalList({ is_active: true })
      .then(({ data }) => setRegionsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner'))
      .finally(() => setLoadingRegionsMinimalList(false));
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = (
    {
      teams,
      teams_notes,
      people_attributes,
      residence_group,
      residence_clusters,
      region,
      ...values
    }: typeof initialValues,
    actions: FormikHelpers<any>
  ): void => {
    createResidence({
      ...values,
      residence_group_id: residence_group?.id as number,
      residence_cluster_ids: residence_clusters.map(({ id }) => id as number),
      region_id: region?.id as number,
      teams: teams.map((team) => ({ ...team, notes: teams_notes, people_attributes: people_attributes })),
    })
      .then(() => notifySuccess('Boendet har skapats'))
      .then(() => navigate('/residences'))
      .catch((error) => {
        notifyError(`Det gick inte att skapa boendet : ${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      });
  };

  // render
  return (
    <React.Fragment>
      {(loadingResidenceGroupsMinimalList || loadingResidenceClustersMinimalList || loadingRegionsMinimalList) && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader
            loading={
              loadingResidenceGroupsMinimalList || loadingResidenceClustersMinimalList || loadingRegionsMinimalList
            }
          />
        </div>
      )}{' '}
      {!loadingResidenceGroupsMinimalList && !loadingResidenceClustersMinimalList && !loadingRegionsMinimalList && (
        <div>
          <div className={css(commonStyles.createViewHeader)}>
            <h1 className={css(commonStyles.headerTextStyle)}>Lägg till boende</h1>
            <CancelLink link={'/residences'} />
          </div>

          <div className={css(commonStyles.formContainer)}>
            <Formik
              initialValues={initialValues}
              validate={(values): Promise<any> => {
                return validationSchema
                  .validate(values, {
                    abortEarly: false,
                    context: { check_invoice: values.gets_invoice_details_from_residence_group },
                  })
                  .then(() => Promise.resolve())
                  .catch((err) => Promise.resolve(yupToFormErrors(err))); // In Formik 2.x, rejection will be interpreted as an actual exception and it won't update the form error state, therefore we return a resolved promise of errors instead
              }}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                isValid,
                dirty,
              }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <ResidenceForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    residenceGroupsMinimalList={residenceGroupsMinimalList}
                    residenceClustersMinimalList={residenceClustersMinimalList}
                    regionsMinimalList={regionsMinimalList}
                    isCreate={true}
                    setFieldValue={setFieldValue}
                    submitDisabled={isSubmitting || !isValid || !dirty}
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CreateResidence;
