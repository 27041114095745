import React, { useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem } from '@mui/material';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { batchUpdateJobApplicationStatus } from './../jobApplicationApi';
import { JobApplicationInterface } from './../types';
import { ListParams } from '../../Common/types';

type Props = {
  jobApplications: JobApplicationInterface[];
  status: { value: string; title: string; body: string; note: string };
  setModalState: (modalState: boolean) => void;
  fetchJobApplicationsList: (value: ListParams) => void;
  listParams: ListParams;
  setSelectedJobApplications: (job_applications: JobApplicationInterface[]) => void;
  setQuickActionJobApplication: (job_applications: JobApplicationInterface[]) => void;
};

const UpdateJobApplicationStatusModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [jobApplicationIds, setJobApplicationIds] = useState<number[]>();
  const [loading, setLoading] = useState(true);

  /// Get the job application ids into a list
  useEffect(() => {
    setLoading(true);
    const ids: number[] = [];
    props.jobApplications.forEach((jobApplication) => {
      if (jobApplication.id) ids.push(jobApplication.id);
    });
    setJobApplicationIds(ids);
    setLoading(false);
  }, [props.jobApplications]); // eslint-disable-line

  const handleSubmit = (): void => {
    setLoading(true);
    if (jobApplicationIds) {
      batchUpdateJobApplicationStatus(jobApplicationIds, props.status.value)
        .then(() => {
          if (props.status.value == 'deleted' && jobApplicationIds.length == 1) {
            notifySuccess('Ansökan har arkiverats');
          } else if (props.status.value == 'deleted' && jobApplicationIds.length > 1) {
            notifySuccess('Ansökningarna har arkiverats');
          } else {
            notifySuccess('Jobbansökningarna har uppdaterats');
          }
        })
        .then(() => {
          setLoading(false);
          props.fetchJobApplicationsList(props.listParams);
          props.setSelectedJobApplications([]);
          props.setQuickActionJobApplication([]);
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera jobbansökningarna: ${error}`))
        .then(() => props.setModalState(false));
    }
  };

  return (
    <div>
      <DialogTitle>{props.status.title}</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText> {props.status.body} </DialogContentText>
            <DialogContentText>
              <List>
                {props.jobApplications.map((jobApplication: JobApplicationInterface) => (
                  <ListItem key={jobApplication.id}>
                    {jobApplication.first_name + ' ' + jobApplication.surname}
                  </ListItem>
                ))}
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              <hr></hr>
              {props.status.note}
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogActions>
              <Button
                fullWidth
                color="error"
                onClick={(): void => {
                  props.setModalState(false);
                  props.setQuickActionJobApplication([]);
                }}
              >
                NEJ
              </Button>
              <Button fullWidth onClick={(): void => handleSubmit()}>
                JA
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </div>
  );
};

export default UpdateJobApplicationStatusModal;
