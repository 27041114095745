import { DeleteOutline } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, MenuItem, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import { CreateFab, QuickAction } from '../Common/ButtonLinks';
import ConfirmationDialog from '../Common/Dialogs/ConfirmationDialog';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { FilterAutocompleteBox } from '../Common/FilterPopover';
import { MenuTableCell } from '../Common/Table';
import TableBox, { TableBoxColumn } from '../Common/Table/TableBox';
import { useNotify } from '../Common/snackbarHooks';
import { commonStyles } from '../Common/styling';
import { stringToPositiveInt, useDebounce, useQuery } from '../Common/utilities';
import { getSalaryClassesMinimalList } from '../SalaryClass/salaryClassApi';
import { MinimalSalaryClass } from '../SalaryClass/types';
import { UserListInterface } from '../User/types';
import { GetUsersParams, getUsers } from '../User/usersApi';
import ExtraHoursFilterPopover from './components/FilterPopover';
import ExtraHoursSearchBar from './components/SearchBar';
import { deleteExtraHour, findExtraHours } from './extraHoursApi';
import { ExtraHour } from './types';
import AuthContext from '../Common/AuthContext';
import { AuthContextValue } from '../Common/types';

const tableColumns: TableBoxColumn[] = [
  { id: 'activity', label: 'Beskrivning', width: 'xl', lineStyle: 'compress' },
  { id: 'date', label: 'Datum', width: 'xs' },
  { id: 'individual_hours', label: 'Timmar per person', width: 'md' },
  { id: 'users_count', label: 'Användare', width: 'sm' },
  { id: 'salary_class', label: 'Löneart', width: 'xs' },
  { id: 'locked', label: 'Exporterad', width: 'xs' },
  { id: 'created_by', label: 'Skapad av', width: 'lg', lineStyle: 'compress' },
  { id: 'created_at', label: 'Skapad', width: 'xs' },
];

const PAGE_SIZE = 25;

export default function ExtraHours() {
  const { notifySuccess, notifyError } = useNotify();
  const [extraHours, setExtraHours] = useState<ExtraHour[]>();
  const [totalExtraHours, setTotalExtraHours] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<UserListInterface[]>([]);
  const [salaryClasses, setSalaryClasses] = useState<MinimalSalaryClass[]>();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [query, setQuery] = useQuery(
    {
      search: (val) => val,
      fromDate: (val) => (dayjs(val, 'YYYY-MM-DD').isValid() ? dayjs(val, 'YYYY-MM-DD') : undefined),
      toDate: (val) => (dayjs(val, 'YYYY-MM-DD').isValid() ? dayjs(val, 'YYYY-MM-DD') : undefined),
      user: (val) => val,
      salaryClass: (val) => stringToPositiveInt(val),
      exported: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      showBannedUsers: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      sortBy: (val) => val,
      sortOrder: (val) => (val === 'asc' ? ('asc' as const) : val === 'desc' ? ('desc' as const) : undefined),
      page: (val) => stringToPositiveInt(val),
    },
    { backConverter: { fromDate: (val) => val?.format('YYYY-MM-DD'), toDate: (val) => val?.format('YYYY-MM-DD') } }
  );
  const { loading: loadingUsers, debounce: fetchUsers } = useDebounce(
    (params: GetUsersParams) => getUsers(params),
    ({ data }) => setUsers(data.data),
    { runInitially: false }
  );

  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;

  const pageTopRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getExtraHours();
  }, [query]);

  useEffect(() => {
    getSalaryClassesMinimalList({ custom: true, with_name: true }).then(({ data }) => setSalaryClasses(data.data));
    getUsers({ hide_banned: !query.showBannedUsers ?? true, page: 1 }).then(({ data }) => {
      const userData = data.data;

      if (query.user && !userData.find((user: UserListInterface) => user.id === query.user)) {
        getUsers({ user_id: query.user }).then(({ data }) => {
          const updatedUserData = [...userData, ...data.data];
          setUsers(updatedUserData);
        });
      } else {
        setUsers(userData);
      }
    });
  }, []);

  function getExtraHours() {
    setLoading(true);
    findExtraHours({
      page: query.page ?? 1,
      query: query.search,
      filter_user: query.user,
      filter_salary_class: query.salaryClass,
      filter_from_date: query.fromDate?.format('YYYY-MM-DD'),
      filter_to_date: query.toDate?.format('YYYY-MM-DD'),
      hide_exported: query.exported === false,
      sort_by: query.sortBy,
      sort_order: query.sortOrder,
    })
      .then(({ data }) => {
        setExtraHours(data.data);
        setTotalExtraHours(data.meta.count);
        pageTopRef.current?.scrollIntoView();
      })
      .finally(() => setLoading(false));
  }

  function updateSorting(col: string) {
    return setQuery({ sortBy: col, sortOrder: query.sortBy !== col || query.sortOrder === 'desc' ? 'asc' : 'desc' });
  }

  function archiveExtraHour() {
    if (deleteId === null) return;
    deleteExtraHour(deleteId)
      .then(() => {
        notifySuccess('Extra arbetstimmen har tagits bort.');
        getExtraHours();
        setDeleteId(null);
      })
      .catch((error) =>
        notifyError(`Det gick inte att ta bort extra arbetstimmen: ${HandleError(error.response.data)}`)
      );
  }

  return (
    <div ref={pageTopRef} className="min-h-full">
      {/* Title */}
      <div className="h-16 p-4 flex items-center justify-between bg-gray-100">
        <h1 className="text-2xl font-semibold">Extra arbetstimmar</h1>
        {hasPermissions(['admin']) && (
          <Link to="/salary-classes">
            <Button variant="text" color="primary" className="!px-2.">
              Hantera lönearter
            </Button>
          </Link>
        )}
      </div>
      {/* Filters */}
      <div className="bg-white flex h-13">
        <ExtraHoursSearchBar value={query.search} onChange={(value) => setQuery({ search: value || undefined })} />
        <div className="flex">
          <FilterAutocompleteBox
            options={users ?? []}
            value={users?.find(({ id }) => query.user === id) ?? null}
            onChange={(option) => setQuery({ user: option?.id, page: undefined })}
            placeholder="Närvarande"
            getOptionLabel={(u) => u.name}
            clearable
            loading={loadingUsers}
            onInputChange={(_, value: string, reason: string) => {
              if (reason === 'clear') {
                fetchUsers({ hide_banned: !query.showBannedUsers ?? true, page: 1 });
              }
              if (reason === 'input') {
                fetchUsers({ query: value, hide_banned: !query.showBannedUsers ?? true, page: 1 });
              }
            }}
          />
          <FilterAutocompleteBox
            loading={!salaryClasses}
            options={salaryClasses ?? []}
            getOptionLabel={(sc) => sc.name}
            value={salaryClasses?.find(({ id }) => id === query.salaryClass) ?? null}
            onChange={(opt) => setQuery({ salaryClass: opt?.id ?? undefined, page: undefined })}
            placeholder="Välj Löneart"
            clearable
          />
          <ExtraHoursFilterPopover
            fromDate={query.fromDate}
            setFromDate={(date) => setQuery({ fromDate: date?.format('YYYY-MM-DD') ?? undefined, page: undefined })}
            toDate={query.toDate}
            setToDate={(date) => setQuery({ toDate: date?.format('YYYY-MM-DD') ?? undefined, page: undefined })}
            showExported={query.exported}
            setShowExported={(val) => setQuery({ exported: val, page: undefined })}
            showBannedUsers={query.showBannedUsers}
            setShowBannedUsers={(val) => setQuery({ showBannedUsers: val })}
          />
        </div>
      </div>
      {/* Content */}
      <TableBox
        columns={tableColumns}
        content={extraHours}
        rowDefinition={(eh) => ({
          key: eh.id,
          defaultLink: `/extra-hours/${eh.id}`,
          cols: [
            eh.activity,
            eh.date,
            eh.individual_hours,
            {
              padding: 'none',
              content: (
                <Tooltip
                  title={
                    eh.users.length > 1 ? (
                      <div>
                        {eh.users.map((u) => (
                          <p key={u.id}>{u.name}</p>
                        ))}
                      </div>
                    ) : (
                      ''
                    )
                  }
                  placement="bottom-start"
                >
                  {eh.users.length === 1 ? (
                    <div className="h-1lh flex items-center p-4">
                      <div className="max-h-2lh line-clamp-2">{eh.users[0].name}</div>
                    </div>
                  ) : (
                    <div className="w-full inline-flex items-center p-4">
                      <span className="mr-2.5">{eh.users_count}</span>
                      <InfoOutlinedIcon fontSize="inherit" color="action" />
                    </div>
                  )}
                </Tooltip>
              ),
            },
            {
              padding: 'none',
              content: (
                <Tooltip title={<div>{eh.salary_class.name}</div>} placement="bottom-start">
                  <div className="w-full inline-flex items-center p-4">
                    <span className="mr-2.5">{eh.salary_class.short_name}</span>
                  </div>
                </Tooltip>
              ),
            },
            eh.locked ? 'JA' : 'NEJ',
            {
              content: eh.created_by.name,
              link: `/users/${eh.created_by.id}`,
            },
            dayjs(eh.created_at).format('YYYY-MM-DD'),
            ,
            {
              content: (
                <MenuTableCell>
                  <MenuItem className="!p-0">
                    <QuickAction
                      onClick={() => setDeleteId(eh.id)}
                      text="Ta bort"
                      icon={<DeleteOutline className="!mr-2.5" />}
                      label="delete_hours"
                      disabled={eh.locked}
                      styles={commonStyles.selectionFabStyle3}
                    />
                  </MenuItem>
                </MenuTableCell>
              ),
              customCell: true,
            },
          ],
        })}
        pagination={{
          totalEntries: totalExtraHours ?? 0,
          pageSize: PAGE_SIZE,
          page: query.page ?? 1,
          onChange: (_, page) => setQuery({ page }),
        }}
        sorting={{ sortBy: query.sortBy, sortOrder: query.sortOrder, handleSortChange: updateSorting }}
        moreActionsCol
        fullWidth
      />

      {loading && (
        <div className="fixed top-1/2 left-1/2">
          <RotateLoader />
        </div>
      )}
      <ConfirmationDialog
        isVisible={deleteId !== null}
        title="Ta bort extra arbetstimme"
        message="Är du säker på att du vill ta bort denna extra arbetstimme?"
        onClose={() => setDeleteId(null)}
        onConfirm={archiveExtraHour}
      />

      <CreateFab link="/extra-hours/create" />
    </div>
  );
}
