import * as Yup from 'yup';

export const initialValues = {
  start_date: '',
  end_date: '',
  budgeted_hours: 0,
  price_periods: [],
};

export const validationSchema = Yup.object().shape({
  start_date: Yup.date()
    .when('end_date', {
      is: (v) => v != null,
      then: (s: any) => s.max(Yup.ref('end_date'), '* Startdatumet måste vara innan slutdatumet'),
    })
    .required('* Startdatum är ett obligatoriskt fält')
    .typeError('* Ogiltigt datum'),
  end_date: Yup.date()
    .min(Yup.ref('start_date'), '* Slutdatumet måste vara efter startdatumet')
    .required('* Slutdatum är ett obligatoriskt fält')
    .typeError('* Ogiltigt datum'),
  price_periods: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.date()
        .when('end_date', {
          is: (v) => v != null,
          then: (s: any) => s.max(Yup.ref('end_date'), '* Startdatumet måste vara innan slutdatumet'),
        })
        .test('fill-agreement-start-date', '* Måste täcka hela avtalet och får inte överlappa', function (value) {
          if (!value) return false;
          // @ts-ignore
          const array = this.options.context['price_periods'];
          const index = parseInt(this.path.split('[')[1].split(']')[0], 10);
          if (index === 0) {
            // @ts-ignore
            const date = new Date(this.options.context['start_date']);
            const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
            if (dateOnly1.valueOf() !== dateOnly2.valueOf()) return false;
          } else {
            const date = new Date(array[index - 1].end_date);
            const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate() - 1);
            if (dateOnly1.valueOf() !== dateOnly2.valueOf()) return false;
          }
          return true;
        })
        .required('* Startdatum är ett obligatoriskt fält')
        .typeError('* Ogiltigt datum'),
      end_date: Yup.date()
        .min(Yup.ref('start_date'), '* Slutdatumet måste vara efter startdatumet')
        .test('fill-agreement-end-date', '* Måste täcka hela avtalet och får inte överlappa', function (value) {
          if (!value) return false;
          // @ts-ignore
          const array = this.options.context['price_periods'];
          const index = parseInt(this.path.split('[')[1].split(']')[0], 10);
          if (index === array.length - 1) {
            // @ts-ignore
            const date = new Date(this.options.context['end_date']);
            const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
            if (dateOnly1.valueOf() !== dateOnly2.valueOf()) return false;
          }
          return true;
        })
        .required('* Slutdatum är ett obligatoriskt fält')
        .typeError('* Ogiltigt datum'),
      hourly_rate: Yup.number().typeError('* Måste vara ett nummer').min(0, '* Får ej vara lägre än 0').required(),
    })
  ),
});
