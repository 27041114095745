import React, { useEffect, useState } from 'react';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { AutocompleteChangeReason, Button, Divider, FormControl, Grid } from '@mui/material';
import { FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import { StyleSheet, css, theme } from '../../Common/styling';
import { OptionsInterface } from '../../Common/types';
import { getResidencesMinimalList } from '../../Residence/residenceApi';
import { initialValues } from '../residenceClusterSchema';
import { Link } from 'react-router-dom';

const styles = StyleSheet.create({
  text1: {
    fontSize: '15.8px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: 'rgb(0, 0, 0, 0.87)',
  },
  text2: {
    marginTop: '12px',
    fontSize: '15.8px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
  },
  text3: {
    fontSize: '15.8px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    alignContent: 'center',
  },
  text4: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    alignContent: 'center',
  },
  map: {
    fontSize: '15.8px',
    lineHeight: '15px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    textAlign: 'right',
  },
});

type Props = {
  values: typeof initialValues;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: Record<string, any>;
  submitDisabled: boolean;
  isCreate: boolean;
  removal?: () => void;
  residenceMinimalList: OptionsInterface[];
  residenceClustersMinimalList: OptionsInterface[];
  residenceGroupsMinimalList: OptionsInterface[];
  regionsMinimalList: OptionsInterface[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const ResidenceClusterForm: React.FC<Props> = (props) => {
  const [selectedResidenceClusters, setSelectedResidenceClusters] = useState<OptionsInterface[]>([]);
  const [selectedResidenceGroups, setSelectedResidenceGroups] = useState<OptionsInterface[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<OptionsInterface[]>([]);
  const openResidenceMap = (): void => {
    window.open('https://ungintra.ungomsorg.se/gmapIframe.php', '_blank');
  };

  useEffect(() => {
    if (props.values.residences?.length === props.residenceMinimalList.length) {
      setSelectedResidenceClusters(props.residenceClustersMinimalList);
      setSelectedResidenceGroups(props.residenceGroupsMinimalList);
      setSelectedRegions(props.regionsMinimalList);
    } else if (!props.values.residences?.length) {
      setSelectedResidenceClusters([]);
      setSelectedResidenceGroups([]);
      setSelectedRegions([]);
    }
  }, [props.values.residences]);

  async function setMultipleResidences(
    type: 'cluster' | 'group' | 'region',
    act: AutocompleteChangeReason,
    option?: OptionsInterface
  ) {
    if (act === 'blur' || act === 'createOption') return;

    let options: OptionsInterface[] = option ? [option] : [];
    if (!option) {
      options =
        type === 'cluster' ? selectedResidenceClusters : type === 'group' ? selectedResidenceGroups : selectedRegions;
    }

    const ids = options.map(({ id }) => id);
    const residences = await getResidencesMinimalList({
      is_active: true,
      residence_cluster_id: type === 'cluster' ? ids : undefined,
      residence_group_id: type === 'group' ? ids : undefined,
      region_id: type === 'region' ? ids : undefined,
    }).then(({ data }) => data.data);
    let newResidences: OptionsInterface[];
    if (act === 'selectOption') {
      newResidences = [...(props.values.residences ?? [])];
      residences.forEach((residence) => {
        if (!newResidences.some(({ id }) => residence.id === id)) {
          newResidences.push(residence);
        }
      });
    } else {
      newResidences =
        props.values.residences?.filter(({ id }) => !residences.some((residence) => id === residence.id)) ?? [];
    }

    props.setFieldValue('residences', newResidences);
    switch (type) {
      case 'cluster':
        return setSelectedResidenceClusters((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
      case 'group':
        return setSelectedResidenceGroups((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
      case 'region':
        return setSelectedRegions((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
    }
  }

  return (
    <div style={{ minWidth: '900px', maxWidth: '100%' }}>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          fieldName="name"
          label={'Namn på gruppen'}
          placeholder="Namn"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <Grid container spacing={2} alignItems="center" alignContent="space-between" className="pl-4 mb-2">
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <p className={css(styles.text2)}>Vilka boenden ska tillhöra till gruppen?</p>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <p className={css(styles.text3)}>{props.values.residences.length + ' Boenden valda'}</p>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            style={{ alignSelf: 'center', display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              style={{ marginTop: '2px', marginRight: '19px', color: theme.greenLight }}
              color="secondary"
              variant="outlined"
              onClick={() => {
                props.setFieldValue('residences', props.residenceMinimalList);
                setSelectedResidenceClusters(props.residenceClustersMinimalList);
                setSelectedResidenceGroups(props.residenceGroupsMinimalList);
                setSelectedRegions(props.regionsMinimalList);
              }}
            >
              Välj alla
            </Button>

            <Link to="/karta?complete=true">
              <Button
                style={{ marginTop: '2px', color: theme.greenLight }}
                color="secondary"
                variant="outlined"
                startIcon={<LocationOnIcon />}
              >
                Karta
              </Button>
            </Link>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ paddingLeft: '15px' }}>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="multiple"
                  id="residence_cluster_ids"
                  label="Grupp"
                  options={props.residenceClustersMinimalList}
                  values={selectedResidenceClusters}
                  onChange={(_e, _v, reason, details) => setMultipleResidences('cluster', reason, details?.option)}
                  renderTags="simple"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="multiple"
                  id="residence_group_ids"
                  label="Avtalspart"
                  options={props.residenceGroupsMinimalList}
                  values={selectedResidenceGroups}
                  onChange={(_e, _v, reason, details) => setMultipleResidences('group', reason, details?.option)}
                  renderTags="simple"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="multiple"
                  id="region_ids"
                  label="Region"
                  options={props.regionsMinimalList}
                  values={selectedRegions}
                  onChange={(_e, _v, reason, details) => setMultipleResidences('region', reason, details?.option)}
                  renderTags="simple"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="multiple"
                  id="residences"
                  name="residences"
                  label="Boenden för nyheten"
                  options={props.residenceMinimalList}
                  values={props.values.residences}
                  onChange={(_, values) => props.setFieldValue('residences', values)}
                  error={props.errors.residences}
                  renderTags="simple"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={12} style={{ marginBottom: '20px', marginTop: '20px' }}>
          <div className={css(styles.text4)} style={{ textAlign: 'center' }}>
            {props.values.residences.length == props.residenceMinimalList.length
              ? 'Alla boenden valda'
              : props.values.residences
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((r) => r.name)
                  .join(', ')}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={5} />
        <Grid item xs={2}>
          <FormControl variant="standard" margin="dense" required fullWidth>
            <Button type="submit" variant="contained" disabled={props.submitDisabled}>
              {props.isCreate ? 'Lägg till' : 'Spara'}
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={3} />
        {!props.isCreate && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button
                color="secondary"
                variant="outlined"
                onClick={(): void => {
                  if (props.removal) props.removal();
                }}
              >
                Ta bort
              </Button>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ResidenceClusterForm;
