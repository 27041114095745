import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';

import { validationSchema, initialValues } from './residenceClusterSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import ResidenceClusterForm from './components/ResidenceClusterForm';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { OptionsInterface } from '../Common/types';

import { getRegionsMinimalList } from '../Region/regionApi';
import { createResidenceCluster } from './residenceClusterApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import { getResidenceClustersMinimalList } from './residenceClusterApi';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';

const CreateResidenceCluster: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const [residenceClustersMinimalList, setResidenceClustersMinimalList] = useState([]);
  const [residenceGroupsMinimalList, setResidenceGroupsMinimalList] = useState([]);
  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);
  const [regionsMinimalList, setRegionsMinimalList] = useState([]);

  useEffect(() => {
    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => setResidenceMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'));

    getResidenceClustersMinimalList()
      .then(({ data }) => setResidenceClustersMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'));

    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroupsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalspart'));

    getRegionsMinimalList({ is_active: true })
      .then(({ data }) => setRegionsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner'));
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = ({ residences, ...values }: typeof initialValues, actions: FormikHelpers<any>): void => {
    createResidenceCluster({ ...values, residence_ids: residences.map((r) => r.id as number) })
      .then(() => notifySuccess('Gruppen har skapats'))
      .then(() => navigate(`/residence-clusters`))
      .catch((error: any) => {
        notifyError(`Det gick inte att skapa gruppen: \n${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      });
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>Lägg till grupp</h1>
        <CancelLink link={`/residence-clusters`} />
      </div>

      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
          }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <ResidenceClusterForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                isCreate={true}
                setFieldValue={setFieldValue}
                submitDisabled={isSubmitting || !isValid || !dirty}
                residenceMinimalList={residenceMinimalList}
                residenceClustersMinimalList={residenceClustersMinimalList}
                residenceGroupsMinimalList={residenceGroupsMinimalList}
                regionsMinimalList={regionsMinimalList}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CreateResidenceCluster;
