import React, { useEffect, useMemo, useState } from 'react';
import { FormikErrors, FormikHandlers } from 'formik';
import { ExtraHourValues } from './formValues';
import { ExtraHourDetails } from '../types';
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { FormDateField, FormHourMinutePicker, FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import { useDebounce } from '../../Common/utilities';
import { MinimalSalaryClass } from '../../SalaryClass/types';
import { getSalaryClassesMinimalList } from '../../SalaryClass/salaryClassApi';
import dayjs from 'dayjs';
import FilterMultipleAutocomplete from '../../Common/FilterPopover/FilterMultipleAutocomplete';
import { getUsers } from '../../User/usersApi';
import { UserListInterface } from '../../User/types';
import { Masonry } from '@mui/lab';
import { deleteExtraHour } from '../extraHoursApi';
import { useNotify } from '../../Common/snackbarHooks';
import { useNavigate } from 'react-router-dom';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import { Redo, RemoveCircleOutline, Restore } from '@mui/icons-material';
import { twMerge } from 'tailwind-merge';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import FormFreeDateField from '../../Common/FormFreeItems/FormFreeDateField';
import ConditionalParent from '../../Common/ConditionalParent';

type ExtraHourFormProps = {
  values: ExtraHourValues;
  errors?: FormikErrors<ExtraHourValues>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  extraHour?: ExtraHourDetails;
  isSubmitting?: boolean;
  isValid?: boolean;
  dirty?: boolean;
};

type DeletableUserInterface = UserListInterface & {
  removed?: boolean;
};

export default function ExtraHourForm({
  values,
  errors,
  handleChange,
  setFieldValue,
  extraHour,
  isSubmitting,
  isValid,
  dirty,
}: ExtraHourFormProps) {
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();
  const [salaryClasses, setSalaryClasses] = useState<MinimalSalaryClass[]>();
  const [users, setUsers] = useState<UserListInterface[]>([]);
  const { loading, debounce } = useDebounce(
    (query: string) => getUsers({ query, page: 1 }),
    ({ data }) => setUsers(data.data),
    { runInitially: true, runInitiallyWith: '' }
  );
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [archiveDialogVisible, setArchiveDialogVisible] = useState<boolean>(false);

  const groupedUsers = useMemo(() => {
    const userGroups: Record<number, DeletableUserInterface[]> = {};
    values.users.forEach((user) => {
      if (!userGroups[user.team_id]) userGroups[user.team_id] = [];
      userGroups[user.team_id].push(user);
    });
    extraHour?.extra_hours_users.forEach(({ user }) => {
      if (!userGroups[user.team_id]) userGroups[user.team_id] = [];
      if (userGroups[user.team_id].every((u) => u.id !== user.id))
        userGroups[user.team_id].push({ ...user, removed: true });
    });

    const groupedUsers: { team_name: string; users: DeletableUserInterface[] }[] = [];
    for (const key in userGroups) {
      groupedUsers.push({
        team_name: userGroups[key][0].team_name,
        users: userGroups[key].toSorted((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
        ),
      });
    }
    return groupedUsers.toSorted((a, b) =>
      a.team_name.toLowerCase() > b.team_name.toLowerCase()
        ? 1
        : a.team_name.toLowerCase() < b.team_name.toLowerCase()
        ? -1
        : 0
    );
  }, [values.users, extraHour]);

  useEffect(() => {
    getSalaryClassesMinimalList({ custom: true, with_name: true }).then(({ data }) => setSalaryClasses(data.data));
  }, []);

  function archiveExtraHour() {
    if (!extraHour) return;
    setLoadingArchive(true);
    deleteExtraHour(extraHour.id)
      .then(() => {
        notifySuccess('Extra arbetstimmen har tagits bort.');
        navigate('/extra-hours');
      })
      .catch((error) => notifyError(`Det gick inte att ta bort extra arbetstimmen: ${HandleError(error)}`))
      .finally(() => setLoadingArchive(false));
  }

  function unsetUser(user: DeletableUserInterface) {
    if (user.removed) {
      delete user.removed;
      setFieldValue('users', [...values.users, user]);
    } else {
      setFieldValue(
        'users',
        values.users.filter((u) => u.id !== user.id)
      );
    }
  }

  return (
    <div className="mx-8">
      <Grid container spacing={2} rowSpacing={-2}>
        <FormTextField
          size={6}
          fieldName="activity"
          label="Beskrivning av tillfället"
          placeholder="Beskrivning"
          values={values}
          errors={errors}
          disabled={!!extraHour?.locked_at}
          onChange={handleChange}
        />

        <FormFreeDateField
          size={6}
          label="Välj datum"
          value={values.date}
          error={errors?.date ?? null}
          maxDate={dayjs()}
          onlyValid={false}
          disabled={!!extraHour?.locked_at}
          onChange={(day) => setFieldValue('date', day)}
        />

        <Grid item xs={6}>
          <FormHourMinutePicker
            fieldName="individual_hours"
            label="Antal arbetstimmar per person"
            values={values}
            errors={errors}
            onChange={(aaa: SelectChangeEvent<{ value: number }>) =>
              setFieldValue('individual_hours', aaa.target.value)
            }
            disabled={!!extraHour?.locked_at}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <FormAutocompleteBox
              type="single"
              label="Välj löneart"
              options={salaryClasses ?? []}
              value={values.salary_class}
              onChange={(_, value) => setFieldValue('salary_class', value)}
              error={errors?.salary_class ?? null}
              loading={!salaryClasses}
              disabled={!!extraHour?.locked_at}
            />
          </FormControl>
        </Grid>
      </Grid>

      {extraHour && (
        <Grid container spacing={2} rowSpacing={-2}>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between">
                <p>Skapad av: {extraHour.created_by.name}</p>
                <p>Skapad datum: {dayjs(extraHour.created_at).format('YYYY-MM-DD')}</p>
              </div>
              {extraHour.updated_by && (
                <div className="flex justify-between">
                  <p>Uppdaterad av: {extraHour.updated_by.name}</p>
                  <p>Uppdaterad datum: {dayjs(extraHour.updated_at).format('YYYY-MM-DD')}</p>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      )}

      <Divider className="!mt-4 !mb-8" />

      <Grid container spacing={4}>
        <Grid item xs={4}>
          <FormControl fullWidth error={!!errors?.users}>
            <FilterMultipleAutocomplete
              id="users"
              label="Sök användare eller team"
              placeholder="Skriv för att söka..."
              groupBy={({ team_name }) => team_name}
              options={users}
              values={values.users}
              loading={loading}
              getOptionLabel={({ name }) => name}
              onChange={(_, options) => setFieldValue('users', options)}
              onInputChange={(query) => debounce(query)}
              tagRender={null}
              disabled={!!extraHour?.locked_at}
              disableCloseOnSelect
            />
            <FormHelperText error>
              <>{errors?.users || ' '}</>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <Masonry columns={3} spacing={2}>
            {groupedUsers.map((group) => (
              <div key={group.team_name} className="text-neutral-600 space-y-0.5">
                <p className="ml-[30px] font-bold">{group.team_name}</p>
                {group.users.map((user) => (
                  <div key={user.id} className="flex items-start group">
                    <div
                      className={twMerge(
                        'transition-opacity duration-200 opacity-0 -my-0.5',
                        !extraHour?.locked_at && 'group-hover:opacity-100'
                      )}
                    >
                      <IconButton
                        size="small"
                        color={user.removed ? 'default' : 'error'}
                        onClick={() => unsetUser(user)}
                        disabled={!!extraHour?.locked_at}
                      >
                        {user.removed ? <Restore fontSize="small" /> : <RemoveCircleOutline fontSize="small" />}
                      </IconButton>
                    </div>
                    <p
                      className={twMerge(
                        'inline-block leading-none mt-1 animate-flash-appear',
                        user.removed && 'line-through text-neutral-400',
                        !extraHour?.extra_hours_users.find((ehu) => ehu.user.id === user.id) && 'text-green-700'
                      )}
                    >
                      {user.name}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </Masonry>
        </Grid>
      </Grid>

      <Divider className="!mt-4 !mb-8" />

      <Grid container spacing={2} className="!pb-4">
        <Grid item xs={5} />
        <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            disabled={!!extraHour?.locked_at || !dirty || !isValid || isSubmitting}
            fullWidth
            type="submit"
          >
            {isSubmitting ? (
              <CircularProgress size="1rem" className="!my-1" color="inherit" />
            ) : extraHour ? (
              'Spara'
            ) : (
              'Lägg till'
            )}
          </Button>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={2}>
          {extraHour && (
            <ConditionalParent
              on={!!extraHour.locked_at}
              parent={(children) => (
                <Tooltip title="Det går inte att ta bort en exporterad extra arbetstid">
                  <span>{children}</span>
                </Tooltip>
              )}
            >
              <Button
                color="error"
                variant="outlined"
                disabled={!!extraHour?.locked_at || loadingArchive}
                fullWidth
                onClick={() => setArchiveDialogVisible(true)}
              >
                {loadingArchive ? <CircularProgress size="1rem" className="!my-1" color="inherit" /> : 'Ta bort'}
              </Button>
            </ConditionalParent>
          )}
          <ConfirmationDialog
            isVisible={archiveDialogVisible}
            title="Ta bort extra arbetstimme"
            message="Är du säker på att du vill ta bort denna extra arbetstimme?"
            onClose={() => setArchiveDialogVisible(false)}
            onConfirm={archiveExtraHour}
          />
        </Grid>
      </Grid>
    </div>
  );
}
