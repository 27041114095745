import React, { useState, useEffect, useRef } from 'react';
import { encode } from 'base-64';
import { useLocation, useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';

import { Grid, Table, TableBody, TableCell } from '@mui/material';

import { filterSortDefaults } from './residenceSchema';
import { paginationDefaults } from '../Common/commonSchema';
import { TableHead, TableRow, Pagination } from '../Common/Table';
import { ListParams, ListPagination } from '../Common/types';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import FilterPopover from './components/FilterPopover';
import { CreateFab } from '../Common/ButtonLinks';
import EmptyList from '../Common/Table/EmptyList';
import { formatDate, isEmpty, useDebounce } from '../Common/utilities';

import { SearchBar } from '../Common/SearchBar';
import { getResidenceList } from './residenceApi';
import { ResidenceInterface } from './types';
import paramsFromUrl from '../Common/Hooks/paramsFromUrl';
import useGlobalPopstateListener from '../Common/Hooks/useGlobalPopstateListener';

const Residences: React.FC = () => {
  // hooks
  const pageTopRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyError } = useNotify();
  const [residenceList, setResidenceList] = useState<Array<ResidenceInterface>>([]);
  const [residencesCount, setResidencesCount] = useState<number>(0);

  // get cached filters and pagination from url if any
  // otherwise load default values
  const query = paramsFromUrl('query');
  const page = paramsFromUrl('page');
  const [pagination, setPagination] = useState(
    page === '' ? (paginationDefaults as ListPagination) : (page as ListPagination)
  );
  const [listParams, setGetListParams] = useState(
    query === '' ? (filterSortDefaults as ListParams) : (query as ListParams)
  );
  const { loading: loading, debounce: fetchResidences } = useDebounce(
    (params: ListParams) => getResidenceList({ ...params, ...pagination }),
    ({ data }) => {
      setResidenceList(data.data);
      setResidencesCount(data.meta.count);
    },
    { runInitially: false }
  );

  function handleHistoryChange(event: PopStateEvent) {
    // Handle popstate event
    setPagination(paramsFromUrl('page'));
  }
  useGlobalPopstateListener(handleHistoryChange);

  const rows = [
    { id: 'name', label: 'Boende' },
    { id: 'residence_group_name', label: 'Avtalspart' },
    { id: 'fortnox_id', numeric: true, label: 'Fortnox ID' },
    { id: 'active_teams_count', numeric: true, label: 'Antal team' },
    { id: 'city', label: 'Ort' },
    { id: 'created_at', label: 'Skapad' },
  ];

  // This useEffect takes place on first page load
  useEffect(() => {
    setPagination(pagination);
  }, []);

  // This useEffect takes place whenever listParams or allowSearch changes
  useEffect(() => {
    const query = encode(JSON.stringify(listParams));
    const page = encode(JSON.stringify(pagination));
    navigate(`/residences?query=${query}&page=${page}${location.hash}`, { replace: true });

    fetchResidences({ ...listParams, ...pagination });
  }, [listParams, pagination]); // eslint-disable-line

  // render
  return (
    <React.Fragment>
      <div ref={pageTopRef} className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Boenden</h1>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <p style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
              {residencesCount} boenden
            </p>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <SearchBar
              setGetListParams={setGetListParams}
              listParams={listParams}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={2} sm={2} md={2} lg={2}>
            <FilterPopover
              setGetListParams={setGetListParams}
              listParams={listParams}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
        </Grid>

        <Table className={css(commonStyles.table)}>
          <TableHead rows={rows} maxWidth="40px" listParams={listParams} setGetListParams={setGetListParams} />

          <TableBody>
            {isEmpty(residenceList) ? (
              <EmptyList />
            ) : (
              residenceList.map((row: ResidenceInterface, idx: number) => (
                <TableRow
                  row={row}
                  idx={idx}
                  id={row.id?.toString()}
                  key={row.id}
                  onClick={(): void => {
                    const query = encode(JSON.stringify(listParams));
                    const page = encode(JSON.stringify(pagination));
                    navigate(`/residences?query=${query}&page=${page}#${row.id}`, { replace: true });
                    navigate(`/residences/${row.id}`);
                  }}
                >
                  <TableCell className={css(commonStyles.tableCell)} scope="row">
                    <b>{row.name}</b>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCell)}>
                    <p>{row.residence_group_name}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>
                    <p>{row.fortnox_id}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>
                    <p>{row.active_teams_count}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCell)}>
                    <p>{row.city}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCell)}>
                    <p>{formatDate(row.created_at)}</p>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <Pagination
          pageTopRef={pageTopRef}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={residencesCount}
        />

        <CreateFab link={'/residences/create'} />
        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Residences;
