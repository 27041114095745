import { Formik, FormikHelpers, yupToFormErrors } from 'formik';
import { useNavigate } from 'react-router-dom';
import React, { ReactElement } from 'react';

import { CreateAgreementForm } from './components/CreateAgreementForm';
import { initialValues, validationSchema } from './agreementSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import { createAgreement } from './agreementApi';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { AgreementDetails } from './types';

const CreateAgreement: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotify();

  // functions
  const handleFormSubmit = (values: any, actions: FormikHelpers<any>): void => {
    if (values.residences) {
      const agreements = values.residences.map((residence: any) => {
        const real_budgeted_hours = residence.teams
          .filter((team: any) => team.checked)
          .reduce((sum: number, d: any) => sum + parseFloat(d.budgeted_hours), 0);

        return {
          agreement: {
            residence_id: residence.id,
            type: values.type,
            start_date: residence.start_date,
            end_date: residence.end_date,
            budgeted_hours: real_budgeted_hours,
            startup_fee: residence.startup_fee,
            is_active: values.is_active,
            invoicing_routine: values.invoicing_routine,
          },
          price_periods: values.price_periods,
          team_agreements: residence.teams.filter((team: any) => team.checked),
        };
      });

      createAgreement(agreements)
        .then(() => notifySuccess('Avtal skapas. Om det är många boenden kan det nån minut.'))
        .then(() => navigate('/agreements'))
        .catch((error) => {
          notifyError(`Det gick inte att skapa avtal: \n${HandleError(error.response.data)}`);
          actions.setSubmitting(false);
        });
    }
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>Lägg till avtal</h1>
        <CancelLink link={'/agreements'} />
      </div>

      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialValues as unknown as AgreementDetails}
          validate={(values): Promise<any> => {
            return validationSchema
              .validate(values, {
                abortEarly: false,
                context: {
                  type: values.type,
                  start_date: values.start_date,
                  end_date: values.end_date,
                  price_periods: values.price_periods,
                },
              })
              .then(() => Promise.resolve())
              .catch((err) => Promise.resolve(yupToFormErrors(err))); // In Formik 2.x, rejection will be interpreted as an actual exception and it won't update the form error state, therefore we return a resolved promise of errors instead
          }}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
          }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <CreateAgreementForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                setFieldValue={setFieldValue}
                submitDisabled={isSubmitting || !isValid || !dirty}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CreateAgreement;
