import { AlternateEmail, MapOutlined } from '@mui/icons-material';
import { Avatar, Button, CircularProgress, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../Common/AuthContext';
import { AuthContextValue } from '../Common/types';
import { getUserRole } from '../Common/utilities';
import DashboardEventsCard from './components/DashboardEventsCard';
import DashboardItem from './components/DashboardItem';
import DashboardLink from './components/DashboardLink';
import DashboardLoading from './components/DashboardLoading';
import DashboardNewsCard from './components/DashboardNewsCard';
import DashboardWorkShiftCard from './components/DashboardWorkShiftCard';
import DriveIcon from './components/DriveIcon';
import { getDashboardEvents, getDashboardNews, getWorkShifts } from './dashboardApi';
import { DashboardEvents, DashboardNews, DashboardTeamWorkShifts } from './types';

const NEWS_PAGE_SIZE = 4;

export default function Dashboard() {
  const { profile, hasPermissions } = useContext(AuthContext) as AuthContextValue;
  const [importantNews, setImportantNews] = useState<DashboardNews | null>();
  const [news, setNews] = useState<DashboardNews[]>();
  const [loadingMoreNews, setLoadingMoreNews] = useState(false);
  const [canGetMoreNews, setCanGetMoreNews] = useState(true);
  const [workShifts, setWorkShifts] = useState<DashboardTeamWorkShifts[]>();
  const [events, setEvents] = useState<DashboardEvents>();

  useEffect(() => {
    getDashboardNews({ important: true }).then(({ data }) => setImportantNews(data.data[0] ?? null));
    getDashboardNews({ important: false, page_size: NEWS_PAGE_SIZE }).then(({ data }) => {
      setNews(data.data);
      if (data.data.length < NEWS_PAGE_SIZE) setCanGetMoreNews(false);
    });
    if (hasPermissions(['operational_manager', 'regional_manager'])) {
      getWorkShifts().then(({ data }) => setWorkShifts(data.work_shifts));
    }
    getDashboardEvents().then(({ data }) => setEvents(data));
  }, []);

  function getMoreNews() {
    if (!news || loadingMoreNews) return;
    setLoadingMoreNews(true);

    getDashboardNews({ important: false, page_size: NEWS_PAGE_SIZE, page: news.length / NEWS_PAGE_SIZE + 1 })
      .then(({ data }) => {
        setNews([...news, ...data.data]);
        if (data.data.length < NEWS_PAGE_SIZE) setCanGetMoreNews(false);
      })
      .finally(() => setLoadingMoreNews(false));
  }

  return (
    <div className="flex-grow bg-subtleGreen px-6 py-2 shadow-black/50 shadow-[inset_5px_0px_5px_-5px]">
      <p className="w-fit">
        <Link to={`/profile/${profile.id}`}>
          <div className="pt-4 pb-8 flex items-center text-black text-2xl w-fit">
            <Avatar alt={`${profile.first_name} ${profile.surname}`} />
            <p className="ml-2">
              <span className="font-semibold">Hej {profile.first_name},</span>
              <span className="text-neutral-500"> {getUserRole(profile)}</span>
            </p>
          </div>
        </Link>
      </p>
      <Grid container columns={2} spacing={4}>
        <Grid item sm={1}>
          {importantNews !== null && (
            <DashboardItem title="Viktig nyhet">
              {importantNews ? <DashboardNewsCard news={importantNews} /> : <DashboardLoading className="h-36" />}
            </DashboardItem>
          )}
          <DashboardItem title="Nyheter" className="space-y-4">
            {news ? (
              news.map((news) => <DashboardNewsCard key={news.id} news={news} />)
            ) : (
              <DashboardLoading className="h-52" />
            )}
            {canGetMoreNews && (
              <div className="flex justify-center">
                {!loadingMoreNews ? (
                  <Button variant="outlined" color="secondary" onClick={getMoreNews} disabled={!news}>
                    Visa fler nyheter
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </div>
            )}
          </DashboardItem>
        </Grid>
        <Grid item sm={1}>
          <DashboardItem title="Länkar" className="grid grid-cols-3 gap-3">
            <DashboardLink
              to="https://drive.google.com/drive/folders/0B6pvkAU79FrKWGdhMDlmQVZUMkE?resourcekey=0-TXnmiYAWqKFV4Uxdx4LyKg&usp=sharing"
              title="Drive"
              icon={DriveIcon}
            />
            <DashboardLink to="https://intra.ungomsorg.se/rccontacts.php" title="Regionchefer" icon={AlternateEmail} />
            <DashboardLink to="/karta?complete=true" title="Boendekarta" icon={MapOutlined} />
          </DashboardItem>

          <DashboardEventsCard events={events} />

          {hasPermissions(['operational_manager', 'regional_manager']) && (
            <Grid container columns={2} spacing={2}>
              <Grid item sm={1}>
                <p className="font-medium text-neutral-600">Senaste pass</p>
              </Grid>
              <Grid item sm={1}>
                <p className="font-medium text-neutral-600">Kommande pass</p>
              </Grid>
              {workShifts?.length ? (
                workShifts.map((w: DashboardTeamWorkShifts, idx: number) => (
                  <React.Fragment key={idx}>
                    <Grid item sm={1}>
                      <DashboardWorkShiftCard team={w.team} workShift={w.latest_work_shift} />
                    </Grid>
                    <Grid item sm={1}>
                      <DashboardWorkShiftCard team={w.team} workShift={w.upcoming_work_shift} />
                    </Grid>
                  </React.Fragment>
                ))
              ) : (
                <Grid item sm={2}>
                  {workShifts ? (
                    <Grid item sm={2}>
                      <div className="h-10 flex items-center justify-center text-neutral-800">
                        Inga pass som behöver hanteras
                      </div>
                    </Grid>
                  ) : (
                    <DashboardLoading className="h-52" />
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
