import React from 'react';
import { AgreementDetails } from '../types';
import { Button } from '@mui/material';
import { AutoFixHigh } from '@mui/icons-material';
import { twMerge } from 'tailwind-merge';

type Props = {
  agreement: AgreementDetails;
  openCorrectionModal: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function Corrections({ agreement, openCorrectionModal }: Props) {
  return (
    <>
      {agreement.agreement_corrections?.length ? (
        <div className="mb-8">
          <h2 className="flex items-center text-xl text-neutral-600">
            <AutoFixHigh />
            <span className="font-bold mx-2">Korrigeringar</span>
          </h2>

          <div className="grid rounded-md border border-neutral-400 bg-neutral-100 text-neutral-600 gap-y-6 p-4">
            {agreement.agreement_corrections
              .sort((a, b) => (a.date > b.date ? 1 : -1))
              .map((correction, index) => (
                <div key={index} className="grid grid-cols-[40fr_30fr_15fr_15fr]">
                  <span className="text-left">
                    <b>{correction.type === 'WorkHourCorrection' ? 'Arb tim-korrigering' : 'Timpott-korrigering'}</b>:{' '}
                    {correction.notes}
                  </span>
                  <span className="text-left">{correction.team.name}</span>
                  <span className="text-left">{correction.date}</span>
                  <span className={twMerge('text-right', correction.value >= 0 ? 'text-[#3C886B]' : 'text-red-500')}>
                    {correction.value >= 0 && '+'}
                    {correction.value} timmar
                  </span>
                </div>
              ))}
          </div>
        </div>
      ) : null}
      {agreement.is_active && (
        <div className="grid mb-8">
          <div className="justify-self-center w-60">
            <Button color="secondary" variant="outlined" fullWidth onClick={(e): void => openCorrectionModal(e)}>
              Gör en korrigering
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
