import React from 'react';

import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import ConditionalParent from '../ConditionalParent';
import { getValue } from '../utilities';

type CheckboxProps<T> = {
  size?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  name: string;
  className?: string;
  label: string;
  values: Record<string, any>;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export default function FormFreeCheckBox<T>({
  size,
  name,
  className,
  label,
  values,
  disabled,
  onChange,
}: CheckboxProps<T>) {
  return (
    <ConditionalParent
      on={size !== null}
      parent={(children) => (
        <Grid item xs={size!}>
          {children}
        </Grid>
      )}
    >
      <>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              name={name}
              checked={getValue(values, name)}
              onChange={onChange}
              color="primary"
              disabled={disabled}
            />
          }
        />
      </>
    </ConditionalParent>
  );
}
