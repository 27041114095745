import { AxiosResponse } from 'axios';

import { ListParams } from '../Common/types';
import { ResidenceInterface } from './types';
import API from '../Common/axios';

export const createResidence = (
  residence: Omit<ResidenceInterface, 'teams_notes' | 'residence_group' | 'residence_clusters' | 'region'> & {
    residence_group_id: number;
    residence_cluster_ids: number[];
    region_id: number;
  }
): Promise<AxiosResponse> => {
  return API.post('v1/residences', { residence });
};

export const getResidence = (id: string) => {
  return API.get<{ data: ResidenceInterface }>(`v1/residences/${id}`);
};

export const updateResidence = (
  id: number | undefined,
  residence: Omit<
    ResidenceInterface,
    'teams_notes' | 'people_attributes' | 'residence_group' | 'residence_clusters' | 'region'
  > & {
    residence_group_id: number;
    residence_cluster_ids: number[];
    region_id: number;
  }
): Promise<AxiosResponse> => {
  return API.patch(`v1/residences/${id}`, { residence });
};

export const getResidenceList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/residences', { params });
};

export const getResidencesMinimalList = (params: {
  is_active?: boolean;
  region_id?: (string | number)[];
  residence_cluster_id?: (string | number)[];
  residence_group_id?: (string | number)[];
}) => {
  return API.get<{ data: ResidenceInterface[] }>('v1/residences/minimal_list', { params });
};

export const getResidencePoints = (): Promise<AxiosResponse> => {
  return API.get('v1/residences/point_list');
};

export const getResidenceTeamsList = (params: {
  is_active?: boolean;
  residence_group_id?: number[];
}): Promise<AxiosResponse> => {
  return API.get('v1/residences/team_list', { params });
};

export const deleteResidence = (id: number): Promise<AxiosResponse> => {
  return API.delete(`v1/residences/${id}`);
};
