import React, { useEffect, useMemo } from 'react';

import { Button, Divider, FormControl, Grid } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import FormToggle from '../../Common/FormItems/FormToggle';
import Teams from './Teams';
import { AgreementDetails, AgreementTeamAgreement } from '../types';
import Corrections from './Corrections';
import AddPricePeriods from './AddPricePeriods';

type Props = {
  values: AgreementDetails;
  handleChange: any;
  errors: any;
  setFieldValue: any;
  openCorrectionModal: any;
  handleDelete: any;
  submitDisabled: boolean;
};

export const EditAgreementForm: React.FC<Props> = (props) => {
  const activeTeamAgreements = useMemo(
    () =>
      props.values.teams
        .filter(({ is_active }) => is_active)
        .map((team) => team.team_agreement)
        .filter(Boolean) as AgreementTeamAgreement[],
    [JSON.stringify(props.values.teams)]
  );
  const invoicing_routines = [
    { id: '-1', name: 'En månad före' },
    { id: '0', name: 'Samma månad' },
    { id: '1', name: 'En månad efter' },
  ];

  const agreementTypes = [
    { id: 'ContinuousAgreement', name: 'Löpande' },
    { id: 'FixedAgreement', name: 'Anpassad' },
  ];

  function getValidDates(dateKey: keyof AgreementTeamAgreement): Dayjs[] {
    return activeTeamAgreements.map((ta) => dayjs(String(ta[dateKey]))).filter((date) => date.isValid());
  }

  useEffect(() => {
    const minDate = dayjs.min(getValidDates('start_date'));
    if (minDate?.isValid()) {
      const minPricePeriodDate = dayjs.min(
        props.values.price_periods.map((pricePeriod: any) => dayjs(pricePeriod.start_date))
      );

      const minPricePeriodIndex = props.values.price_periods.findIndex((pricePeriod: any) =>
        minPricePeriodDate?.isSame(pricePeriod.start_date)
      );
      props.setFieldValue(
        `price_periods[${minPricePeriodIndex}].start_date`,
        minDate.format('YYYY-MM-DD')
        // formatISO(minDate, { representation: 'date' })
      );
      props.setFieldValue('start_date', minDate.format('YYYY-MM-DD'));
    }
  }, [activeTeamAgreements]);

  useEffect(() => {
    const maxDate = dayjs.max(getValidDates('end_date'));
    if (maxDate?.isValid()) {
      const maxPricePeriodDate = dayjs.max(
        props.values.price_periods.map((pricePeriod: any) => dayjs(pricePeriod.end_date))
      );
      const maxPricePeriodIndex = props.values.price_periods.findIndex((pricePeriod: any) =>
        maxPricePeriodDate?.isSame(pricePeriod.end_date)
      );
      props.setFieldValue(`price_periods[${maxPricePeriodIndex}].end_date`, maxDate.format('YYYY-MM-DD'));
      props.setFieldValue('end_date', maxDate.format('YYYY-MM-DD'));
    }
  }, [activeTeamAgreements]);

  return (
    <div>
      <AddPricePeriods
        agreement={props.values}
        handleChange={props.handleChange}
        errors={props.errors}
        isEdit={true}
        setFieldValue={props.setFieldValue}
      />

      {/* <TeamOnEditAgreement
        values={props.values}
        handleChange={props.handleChange}
        errors={props.errors}
        setFieldValue={props.setFieldValue}
      /> */}
      <Teams
        agreement={props.values}
        handleChange={props.handleChange}
        errors={props.errors}
        setFieldValue={props.setFieldValue}
      />

      <Corrections agreement={props.values} openCorrectionModal={props.openCorrectionModal} />

      <Divider className="!mb-8" />

      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormToggle
          size={3}
          fieldName="is_active"
          label="Avtalet är aktivt"
          values={props.values}
          onChange={props.handleChange}
        />
        <Grid item xs={2} />
        {props.values.editable && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button type="submit" variant="contained" color="primary" disabled={props.submitDisabled}>
                Spara
              </Button>
            </FormControl>
          </Grid>
        )}
        {props.values.deletable && (
          <>
            <Grid item xs={3} />
            <Grid item xs={2}>
              <FormControl variant="standard" margin="dense" required fullWidth>
                <Button onClick={props.handleDelete} variant="outlined" color="error">
                  Ta Bort
                </Button>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
