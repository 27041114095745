import React from 'react';
import { BaseFilterPopover } from '../../Common/FilterPopover';
import { FormControlLabel, Switch } from '@mui/material';
import { Dayjs } from 'dayjs';
import FilterDatePicker from '../../Common/FilterPopover/FilterDatePicker';
import FilterFreeDateField from '../../Common/FilterPopover/FilterFreeDateField';

type Props = {
  startDate: Dayjs | undefined;
  setStartDate: (date: Dayjs | null) => void;
  endDate: Dayjs | undefined;
  setEndDate: (date: Dayjs | null) => void;
  showInactive: boolean | undefined;
  setShowInactive: (val: boolean) => void;
};

export default function AgreementsFilterPopover({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showInactive,
  setShowInactive,
}: Props) {
  return (
    <BaseFilterPopover>
      <div className="mt-4">
        <FilterFreeDateField label="Startdatum" value={startDate ?? null} onChange={setStartDate} />
      </div>

      <div className="mt-4">
        <FilterFreeDateField label="Slutdatum" value={endDate ?? null} onChange={setEndDate} />
      </div>

      <div className="flex items-center justify-between mt-4">
        Visa inaktiva
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={showInactive ?? false} onChange={(_, checked) => setShowInactive(checked)} />}
        />
      </div>
    </BaseFilterPopover>
  );
}
