import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getMinimalMonths } from './scheduleApi';
import { MinimalMonth } from '../Common/types';
import Month from './components/Month';
import dayjs from 'dayjs';
import { Button, CircularProgress } from '@mui/material';
import { CreateFab } from '../Common/ButtonLinks';
import { lastLocationState, useQuery } from '../Common/utilities';
import { decode } from 'base-64';

const MONTHS_PER_EXTRA_REQUEST = 3;

const TeamSchedule: React.FC = () => {
  const { teamId } = useParams();
  const location = useLocation();
  const [minimalMonths, setMinimalMonths] = useState<MinimalMonth[]>();
  const [loadingMinimalMonths, setLoadingMinimalMonths] = useState(true);
  const [loadingEarlierMonths, setLoadingEarlierMonths] = useState(false);
  const [loadingLaterMonths, setLoadingLaterMonths] = useState(false);

  const [query, setQuery] = useQuery(
    {
      month: (val) => (dayjs(val, 'YYYY-MM').isValid() ? dayjs(val, 'YYYY-MM') : undefined),
      from: (val) => val,
      showInactive: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      firstMonth: (val) => (dayjs(val, 'YYYY-MM').isValid() ? dayjs(val, 'YYYY-MM') : undefined),
      lastMonth: (val) => (dayjs(val, 'YYYY-MM').isValid() ? dayjs(val, 'YYYY-MM') : undefined),
    },
    {
      backConverter: {
        month: (val) => val?.format('YYYY-MM'),
        firstMonth: (val) => val?.format('YYYY-MM'),
        lastMonth: (val) => val?.format('YYYY-MM'),
      },
    }
  );
  const currentMonth = dayjs().format('YYYY-MM');
  const focusMonth = query.month ?? currentMonth;

  useEffect(() => {
    setLoadingMinimalMonths(true);
    const date = dayjs(focusMonth, 'YYYY-MM');
    let from_month = date.subtract(1, 'month').format('YYYY-MM');
    let to_month = date.add(3, 'months').format('YYYY-MM');

    if (query.firstMonth && query.lastMonth) {
      from_month = query.firstMonth.format('YYYY-MM');
      to_month = query.lastMonth.format('YYYY-MM');
    }
    getMinimalMonths(teamId, focusMonth ? { from_month, to_month } : undefined)
      .then(({ data }) => setMinimalMonths(data.data))
      .finally(() => setLoadingMinimalMonths(false));
  }, [teamId, focusMonth]);

  function getEarlierMonths() {
    if (loadingEarlierMonths) return;
    const firstMonth = dayjs(minimalMonths?.[0].month);
    if (!firstMonth.isValid()) return;
    setLoadingEarlierMonths(true);

    getMinimalMonths(teamId, {
      from_month: firstMonth.subtract(MONTHS_PER_EXTRA_REQUEST, 'months').format('YYYY-MM'),
      to_month: firstMonth.subtract(1, 'month').format('YYYY-MM'),
    })
      .then(({ data }) => {
        setQuery({ firstMonth: data.data[0].month, lastMonth: minimalMonths?.[minimalMonths.length - 1].month });
        setMinimalMonths(minimalMonths && [...data.data, ...minimalMonths]);
      })
      .finally(() => setLoadingEarlierMonths(false));
  }

  function getLaterMonths() {
    if (loadingLaterMonths) return;
    const lastMonth = dayjs(minimalMonths?.[minimalMonths.length - 1].month);
    if (!lastMonth.isValid()) return;
    setLoadingLaterMonths(true);
    getMinimalMonths(teamId, {
      from_month: lastMonth.add(1, 'month').format('YYYY-MM'),
      to_month: lastMonth.add(MONTHS_PER_EXTRA_REQUEST, 'months').format('YYYY-MM'),
    })
      .then(({ data }) => {
        setQuery({ firstMonth: minimalMonths?.[0].month, lastMonth: data.data[data.data.length - 1].month });
        setMinimalMonths(minimalMonths && [...minimalMonths, ...data.data]);
      })
      .finally(() => setLoadingLaterMonths(false));
  }

  return (
    <div className="min-h-[calc(100vh-8rem)]">
      <div className="py-4">
        {loadingMinimalMonths ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex flex-col w-full gap-4">
            <Button
              className="self-center"
              disabled={loadingEarlierMonths}
              startIcon={loadingEarlierMonths && <CircularProgress color="inherit" size={22} />}
              onClick={getEarlierMonths}
            >
              Ladda tidigare månader
            </Button>
            {minimalMonths?.map((month) => (
              <Month
                key={month.month}
                minimalMonth={month}
                teamId={teamId!}
                defaultExpanded={month.month === focusMonth}
              />
            ))}
            <Button
              className="self-center"
              disabled={loadingLaterMonths}
              startIcon={loadingLaterMonths && <CircularProgress color="inherit" size={22} />}
              onClick={getLaterMonths}
            >
              Ladda senare månader
            </Button>
          </div>
        )}
      </div>
      <CreateFab link={`/teams/${teamId}/work-shifts/create`} state={lastLocationState(location)} />
    </div>
  );
};

export default TeamSchedule;
