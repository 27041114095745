import { AxiosResponse } from 'axios';

import { ListParams } from '../Common/types';
import { MinimalTeam, TeamExportData, TeamInterface, TeamListInterface } from './types';
import API from '../Common/axios';
import { AvailableSubstitute, MinimalWorkShiftUserInterface } from '../User/types';

export type GetTeamsParams = {
  query?: string;
  is_active?: boolean;
  filter_regional_manager?: string;
  filter_operational_manager?: string;
  filter_region?: number;
  filter_residence_group?: number;
  residence_id?: number[];
  page?: number;
  sort_by?: string;
  sort_order?: 'asc' | 'desc';
};

export const getTeamList = (params: GetTeamsParams) => {
  return API.get<{ data: TeamListInterface[]; meta: { count: number; show_next_period: boolean } }>('v1/teams', {
    params,
  });
};

export const createTeam = (team: TeamInterface): Promise<AxiosResponse> => {
  return API.post('v1/teams', { team });
};

export const updateTeam = (id: number | undefined, team: TeamInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/teams/${id}`, { team });
};

export const getTeam = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/teams/${id}`);
};

export const deleteTeam = (id: number | undefined): Promise<AxiosResponse> => {
  return API.delete(`v1/teams/${id}`);
};

export const getTeamMinimalList = (params?: {
  residence_id?: number[];
  is_active?: boolean;
  filter_operational_manager?: string;
  filter_regional_manager?: string;
  filter_region?: number;
  filter_residence_group?: number;
  filter_residence_clusters?: (string | number)[];
  filter_residence_groups?: (string | number)[];
  filter_regions?: (string | number)[];
}) => {
  return API.get<{ data: MinimalTeam[] }>('v1/teams/minimal_list', { params });
};

export const getTeamExportData = (teams: MinimalTeam[]) => {
  return API.get<{ data: TeamExportData[] }>('v1/teams/export_data', { params: { team_id: teams.map((t) => t.id) } });
};

export const getDefaultParticipants = (
  team_id: number,
  params: {
    date?: string | null;
  }
): Promise<AxiosResponse> => {
  return API.get(`v1/teams/${team_id}/default_participants`, { params });
};

export const getTempTeamLeaders = (params: {
  team_id: string | number;
  with_role?: boolean;
  with_team?: boolean;
  query?: string;
}): Promise<AxiosResponse> => {
  return API.get(`v1/teams/${params.team_id}/temp_team_leaders`, { params });
};

export const getSubstitutes = (params: {
  team_id: string | number;
  work_shift_id?: string | number;
  date?: string | null;
  with_role?: boolean;
  query?: string;
}): Promise<AxiosResponse> => {
  return API.get(`v1/teams/${params.team_id}/substitutes`, { params });
};

export const getTeamSubstitutes = (
  teamId: number,
  params?: { query?: string; date?: string; work_shift_id?: number }
) => {
  return API.get<{ previous: AvailableSubstitute[]; closest: AvailableSubstitute[]; other: AvailableSubstitute[] }>(
    `v1/teams/${teamId}/substitutes`,
    { params }
  );
};

export const getTeamTempTeamLeaders = (
  teamId: number,
  params?: { query?: string; work_shift_id?: number; local_ids?: string[] }
) => {
  return API.get<{ temp_team_leaders: MinimalWorkShiftUserInterface[]; others: MinimalWorkShiftUserInterface[] }>(
    `v1/teams/${teamId}/temp_team_leaders`,
    { params }
  );
};

export const getTeamSchedulePdf = (teamId: number) => {
  return API.get(`v1/teams/${teamId}/download_schedule`, { responseType: 'arraybuffer' });
};
