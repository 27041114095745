import React, { useState } from 'react';
import { BaseFilterPopover, FilterDateField, FilterSwitch } from '../../Common/FilterPopover';
import { ListParams } from '../../Common/types';
import { FormControlLabel, Switch } from '@mui/material';
import { theme } from '../../Common/styling';

type Props = {
  setGetListParams: (p: ListParams) => void;
  listParams: ListParams;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
};

const FilterPopover: React.FC<Props> = (props) => {
  const [showBirthdatePickers, setShowBirthdatePickers] = useState(
    !!props.listParams.from_birthdate || !!props.listParams.to_birthdate
  );

  return (
    <BaseFilterPopover>
      <FilterSwitch
        fieldName="hide_employed"
        label="Visa anställda"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />
      <FilterSwitch
        fieldName="hide_banned"
        label="Visa anställda som har slutat"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />
      {!props.listParams.hide_banned ? (
        <div>
          <div>Avslutningsdatum från:</div>
          <FilterDateField
            fieldName="banned_at_start"
            label=""
            listParams={props.listParams}
            setGetListParams={props.setGetListParams}
            setPagination={props.setPagination}
            paginationDefaults={props.paginationDefaults}
          />
          <FilterDateField
            fieldName="banned_at_end"
            label="till"
            listParams={props.listParams}
            setGetListParams={props.setGetListParams}
            setPagination={props.setPagination}
            paginationDefaults={props.paginationDefaults}
          />
        </div>
      ) : null}
      <FilterSwitch
        fieldName="hide_inactive_objects"
        label="Visa inaktiva objekt i menyn"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
      />

      <div className="flex items-center justify-between">
        <p>Visa födelsedatum</p>
        <FormControlLabel
          control={
            <Switch
              checked={showBirthdatePickers}
              onChange={(_, checked) => {
                setShowBirthdatePickers(checked);
                if (!checked)
                  props.setGetListParams({ ...props.listParams, from_birthdate: undefined, to_birthdate: undefined });
              }}
              // Change with MUI5 update
              sx={
                showBirthdatePickers
                  ? {
                      '& .MuiSwitch-track': { backgroundColor: `${theme.green} !important` },
                      '& .MuiSwitch-thumb': { backgroundColor: theme.green },
                    }
                  : undefined
              }
              // color="secondary"
            />
          }
          label=""
          labelPlacement="start"
        />
      </div>
      {showBirthdatePickers && (
        <div>
          <FilterDateField
            fieldName="from_birthdate"
            label="Födelsedag efter"
            listParams={props.listParams}
            setGetListParams={props.setGetListParams}
            paginationDefaults={props.paginationDefaults}
          />
          <FilterDateField
            fieldName="to_birthdate"
            label="Födelsedag före"
            listParams={props.listParams}
            setGetListParams={props.setGetListParams}
            paginationDefaults={props.paginationDefaults}
          />
        </div>
      )}
    </BaseFilterPopover>
  );
};

export default FilterPopover;
